/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Grid from "@mui/material/Grid";

import { useEffect, useState, useRef } from "react";

import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/TransactionReport/data/authorsTableData";

import { useParams } from "react-router-dom";
import MSSelect from "components/MSSelect";
import { MenuItem, Select, Button } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { useAuth } from "contexts/authContext";

import "jspdf-autotable";

const initMerchants = [
  { id: null, name: "Select Merchant*" },
  { id: 1, name: "abc" },
  { id: 2, name: "xyz" },
];

const transactionTypes = [
  { id: "", name: "Please Select" },
  { id: "purchase", name: "purchase" },
  { id: "exchange", name: "exchange" },
  { id: "refund", name: "refund" },
];

import Api from "constants/Api";
import axios from "axios";
import { date } from "yup";
// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"

function TransactionReport() {
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);
  const [merchants, setMerchants] = useState({ items: [] });
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const [transactionFilter, setTransactionFilter] = useState({});
  const [submittedTransactionFilter, setSubmittedTransactionFilter] = useState({});
  const [transactions, setTransactions] = useState([]);

  const params = useParams();
  const { columns, rows } = authorsTableData(transactions);

  const handleDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedDate(dateF);
  };
  const handleEndDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedEndDate(dateF);
  };

  const handleChangeMerchant = (event) => {
    const selectedValue = event.target.value;
    // Do something with the selected value
    console.log(selectedValue);
    setTransactionFilter((prevState) => ({ ...prevState, merchantId: selectedValue }));
  };
  const handleChangeTransactionType = (event) => {
    const selectedValue = event.target.value;
    console.log("value:" + selectedValue);

    setSelectedTransactionType(selectedValue);
  };

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, startDate: selectedDate }));
  }, [selectedDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, endDate: selectedEndDate }));
  }, [selectedEndDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({
      ...prevState,
      transactionType: selectedTransactionType,
    }));
  }, [selectedTransactionType]);

  //console.log("access TransactionReport");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();
  const getMerchant = () => {
    const url = `${Api.merchant}/Merchant/getAllShortData`;
    console.log("url" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      //console.log("array:" + JSON.stringify(arr));
      setMerchants(arr);
    });
  };

  useEffect(() => {
    console.log("getMerchant()");

    getMerchant();
  }, []);

  //get data from server

  const getTransaction = () => {
    const url = `${Api.report}/TransactionReport/TransactionReport`;
    console.log("url" + url);

    axios.post(url, submittedTransactionFilter).then((response) => {
      const arr = response.data;
      console.log("array:" + JSON.stringify(arr));
      setTransactions(arr);
    });
  };

  useEffect(() => {
    console.log("getTransaction()");
    getTransaction();
  }, [submittedTransactionFilter]);

  //end get data from server
  const tableRef = useRef(null);

  /*const exportToPDF = async () => {
    const canvas = await html2canvas(tableRef.current);
    //const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    //pdf.addImage(imgData, 'PNG', 0, 0);
    pdf.save("table.pdf");
  };*/

  const exportToPDFOld = () => {
    const doc = new jsPDF();

    // Define the columns
    const columns = [
      "Merchant ID",
      "Merchant Name",
      "First Name",
      "Last Name",
      "Transaction Date",
      "Transaction No.",
      "Transaction Value",
      "Points",
      "Transaction Type",
      "Is Canceled",
    ];

    // Extract the relevant data from your transactions
    const rows = transactions.map((transaction) => [
      transaction.merchantId,
      transaction.merchantName,
      transaction.firstname,
      transaction.lastname,
      new Date(transaction.dateOfTransaction).toLocaleDateString(),
      transaction.transactionNumber,
      transaction.transactionValue,
      transaction.points || "-",
      transaction.transactionType,
      transaction.isCanceled ? "Yes" : "No",
    ]);

    // Use the autoTable function to define the table content
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20,
    });

    doc.save("transactions.pdf");
  };

  const exportToPDFOld2 = async () => {
    const doc = new jsPDF();

    // Fetching the image as a blob
    console.log("get image");

    const imageUrl = "/assets/images/logos/pplLogo.jpg"; // Adjust the path if you store it elsewhere
    //const imageUrl = "http://localhost:3000/assets/images/logos/pplLogo.jpg";

    const response = await fetch(imageUrl);
    console.log(JSON.stringify(response));
    //console.error(JSON.stringify(response.error));

    const blob = await response.blob();
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      doc.addImage(base64data, "JPEG", 15, 5, 40, 20); // (base64Data, format, x, y, width, height)

      // Define the columns
      const columns = [
        "Merchant ID",
        "Merchant Name",
        "First Name",
        "Last Name",
        "Transaction Date",
        "Transaction No.",
        "Transaction Value",
        "Points",
        "Transaction Type",
        "Is Canceled",
      ];

      // Extract the relevant data from your transactions
      const rows = transactions.map((transaction) => [
        transaction.merchantId,
        transaction.merchantName,
        transaction.firstname,
        transaction.lastname,
        new Date(transaction.dateOfTransaction).toLocaleDateString(),
        transaction.transactionNumber,
        transaction.transactionValue,
        transaction.points || "-",
        transaction.transactionType,
        transaction.isCanceled ? "Yes" : "No",
      ]);

      // Calculate totals
      const totalValue = transactions.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const totalTransactions = transactions.length;

      // Assuming you have a startDate and endDate for the report period:
      const startDate = new Date(transactions[0].dateOfTransaction).toLocaleDateString();
      const endDate = new Date(
        transactions[transactions.length - 1].dateOfTransaction
      ).toLocaleDateString();

      // Centered text function
      const centeredText = (text, y) => {
        const textWidth =
          (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
        const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(text, textOffset, y);
      };

      // Add header and date range
      doc.setFontSize(18);
      centeredText("Transactions Report", 35);
      doc.setFontSize(12);
      const dateText = `Start Date: ${startDate} - End Date: ${endDate}`;
      centeredText(dateText, 41);

      // Draw a line under the header for aesthetics
      doc.line(14, 45, 196, 45); // (x1, y1, x2, y2)

      // Use the autoTable function to define the table content
      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 50,
        didDrawPage: (data) => {
          // Add footer on each page
          doc.setFontSize(10);
          doc.text(
            `Total Transactions: ${totalTransactions}`,
            14,
            doc.internal.pageSize.height - 10
          );
          doc.text(`Total Value: ${totalValue}`, 160, doc.internal.pageSize.height - 10);
        },
      });

      doc.save("transactions.pdf");
    };
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    const imageUrl1 = "../pplLogo.jpg";
    const imageUrl2 = "../Logo-english.jpg";

    const loadAndDrawImage = (url, callback) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        let reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          callback(reader.result);
        };
      };
      xhr.send();
    };

    loadAndDrawImage(imageUrl1, (base64data1) => {
      const originalWidth1 = 708;
      const originalHeight1 = 184;
      //const newWidth1 = 50;
      //const newHeight1 = (originalHeight1 * newWidth1) / originalWidth1;
      const newHeight1 = 12;
      const newWidth1 = (originalWidth1 * newHeight1) / originalHeight1;
      const startX1 = (210 - newWidth1) / 2;

      doc.addImage(base64data1, "JPEG", startX1, 20, newWidth1, newHeight1);

      loadAndDrawImage(imageUrl2, (base64data2) => {
        // const startX2 = startX1 - newWidth1 - 10; // 10 units space between images
        const startX2 = 20; // 10 units space between images

        doc.addImage(base64data2, "JPEG", startX2, 18, 50, 16);

        const columns = [
          "Merchant",
          "First Name",
          "Last Name",
          "Transaction Date",
          "Transaction No.",
          "Value",
          "Points",
          "Balance",
          "Type",
          "Is Canceled",
        ];

        const rows = transactions
          .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
          .map((transaction) => [
            transaction.merchantName,
            transaction.firstname,
            transaction.lastname,
            formatDate(transaction.dateOfTransaction),
            transaction.transactionNumber,
            transaction.transactionValue,
            transaction.points || "-",
            transaction.pointbalance,
            transaction.transactionType,
            transaction.isCanceled ? "Yes" : "No",
          ]);

        const totalValue = transactions.reduce(
          (acc, curr) => acc + (curr.transactionValue || 0),
          0
        );
        const totalTransactions = transactions.length;

        let startDate = new Date();
        let endDate = new Date();
        if (selectedDate) {
          startDate = formatDate(selectedDate);
        }
        if (selectedEndDate) {
          endDate = formatDate(selectedEndDate);
        }

        const centeredText = (text, y) => {
          const textWidth =
            (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
          const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(text, textOffset, y);
        };

        // Adjusted font sizes
        doc.setFontSize(12); // Reduced by 2 units
        centeredText("Transactions Report", 48);
        doc.setFontSize(10); // Reduced by 2 units
        let dateText = "";
        if (selectedEndDate) {
          dateText = `Start Date: ${startDate} - End Date: ${endDate}`;
        } else {
          if (selectedDate) {
            dateText = `Start Date: ${startDate}`;
          }
        }
        centeredText(dateText, 55);

        doc.line(14, 60, 196, 60);

        doc.autoTable({
          head: [columns],
          body: rows,
          startY: 63,
          styles: {
            //fillColor: [171, 142, 102], // Color #AB8E66 in RGB format
            fontSize: 8, // Setting the font size for the content inside the table
          },
          headStyles: {
            fillColor: [171, 142, 102], // Color #AB8E66 in RGB format for header
          },
          didDrawPage: (data) => {
            doc.setFontSize(10);
            doc.text(
              `Total Transactions: ${totalTransactions}`,
              11,
              doc.internal.pageSize.height - 10
            );
            doc.text(`Total Value: ${totalValue} `, 160, doc.internal.pageSize.height - 10);
          },
        });

        doc.save("transactions.pdf");
      });
    });
  };

  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Transactions Report - by tenant
            </MDTypography>
          </MDBox>

          {/*<filters*/}
          <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
            <MDBox display="flex" alignItems="center">
              <MDTypography mr={2}>Merchants</MDTypography>
              <Select
                label="select"
                required
                displayEmpty
                onChange={handleChangeMerchant}
                defaultValue={0}
              >
                <MenuItem key={0} value={0}>
                  {"Please Select"}
                </MenuItem>
                {merchants.items.map((element, index) => (
                  <MenuItem key={index} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  onChange={(newValue) => handleDateChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date"
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <MDBox display="flex" alignItems="center">
              <MDTypography mr={2}>Transaction type</MDTypography>
              <Select
                label="select"
                required
                displayEmpty
                defaultValue={""}
                onChange={handleChangeTransactionType}
              >
                {transactionTypes.map((element, index) => (
                  <MenuItem key={index} value={element.id}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
            <MDBox>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log("search");
                  setSubmittedTransactionFilter(transactionFilter);
                }}
              >
                Search
              </MDButton>
              <MDButton onClick={exportToPDF}>Export to PDF</MDButton>
            </MDBox>
          </MDBox>
          {/*<filters*/}

          {/*<DataTable*/}
          <MDBox ml={2} mt={0} mb={1}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries
              noEndBorder
            />
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TransactionReport;

/*          <MDBox display="flex" alignItems="flex-start" justifyContent="space-around">
            <MDBox ml={4} mt={2} mb={1} display="flex" alignItems="flex-start">
              <MDTypography>Merchants</MDTypography>
              <Select
                label="select "
                required
                displayEmpty
                defaultValue={""}
                onChange={handleChangeMerchant}
              >
                {merchants.items.map((element, index) => {
                  return (
                    <MenuItem m={20} key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </MDBox>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="Start Date"
                  onChange={(newValue) => handleDateChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  label="End Date  "
                  onChange={(newValue) => handleEndDateChange(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <MDBox>
              <Button
                onClick={() => {
                  console.log("ss");
                }}
              >
                {" "}
                search
              </Button>
            </MDBox>
          </MDBox>*/
