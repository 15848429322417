/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

import New from "./components/New";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/HappeningCategory/data/authorsTableData";

import { useParams } from "react-router-dom";
import Edit from "./components/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Api from "constants/Api";
import MDAlert from "components/MDAlert";
// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"

const list = "list";
const newEntry = "newEntry";
const editEntry = "editEntry";

function HappeningCategory() {
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);

  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [deletedId, setDeletedId] = useState(null);
  const [message, setMessage] = useState("");

  const [screenStatus, setScreenStatus] = useState(list);
  const [alertType, setAlertType] = useState("success");

  const handleClose = () => {
    setOpen(false);
  };

  const confirmDelete = () => {
    setOpen(false);
    const url = `${Api.merchant}/happening/delete?id=${deletedId}`;
    console.log("url:" + url);

    axios
      .delete(url)
      .then((response) => {
        console.log(response.data);
        setMessage("Merchant deleted successfully");
        setAlertType("success");
        setRefresh((prevCheck) => !prevCheck);
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });
  };

  const params = useParams();
  console.log("refresh");

  const { columns, rows } = authorsTableData(
    screenStatus ? screenStatus : "",
    setOpen,
    setDeletedId,
    refresh
  );
  console.log("access happening category");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();

  useEffect(() => {
    console.log(JSON.stringify(params));
    //setNewEntry(!(params.newEntryw === "false"));
    if (params.id) {
      setId(params.id);
    } else {
      setScreenStatus(list);
    }
  }, [params]);

  useEffect(() => {
    if (id != null) {
      setScreenStatus(editEntry);
    }
  }, [id]);

  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {screenStatus == list && "List Happening Category"}
              {screenStatus == newEntry && "Create Happening Category"}
              {screenStatus == editEntry && "Edit Happening Category"}
            </MDTypography>
          </MDBox>
          {message && (
            <MDBox>
              <MDAlert color={alertType} dismissible>
                {alertContent(message, alertType)}
              </MDAlert>
            </MDBox>
          )}
          {screenStatus == newEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(list);
                  //setList(false);
                }}
                size="small"
              >
                {"< Back"}
              </MDButton>
              <New
                action={{
                  route: "/happeningCategory",
                  color: "primary",
                  tooltip: "Create Profile",
                }}
              />
            </MDBox>
          )}
          {screenStatus == editEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              <Edit
                id={Number(id)}
                action={{ route: "/happeningCategory", color: "primary", tooltip: "Edit Profile" }}
              />
            </MDBox>
          )}

          {screenStatus == list && (
            <MDBox ml={4} mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(newEntry);
                  //setList(false);
                }}
                size="small"
              >
                Create New
              </MDButton>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage
                showTotalEntries
                noEndBorder
              />
            </MDBox>
          )}
        </Card>
      </MDBox>

      <MDBox>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete happening"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the selected happening?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={confirmDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default HappeningCategory;
