//import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//import { saveAs } from "file-saver";

// Material Dashboard 2 React components
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useState, useRef } from "react";

import DataTable from "examples/Tables/DataTable";
import authorsTableData from "./data/authorsTableData";
import ExcelJS from "exceljs";

import { useParams } from "react-router-dom";
import { MenuItem, Select, Button, InputLabel, FormControl } from "@mui/material";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import * as extendedFun from "extensions/extendedFunctions";

import { useNavigate } from "react-router-dom";

import "./styles.css"; // Import your CSS file

import { jsPDF } from "jspdf";
import "jspdf-autotable";

const initMerchants = [
  { id: null, name: "Select Merchant*" },
  { id: 1, name: "abc" },
  { id: 2, name: "xyz" },
];

const transactionTypes = [
  { id: "all", name: "All transactions type" },
  { id: "purchase", name: "Purchase" },
  { id: "exchange", name: "Exchange" },
  { id: "refund", name: "Refund" },
  { id: "void", name: "void" },
];
import { useAuth } from "../../contexts/authContext";
import Api from "constants/Api";
import axios from "axios";
//import { date } from "yup";
// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"

const imageUrl1 = "../pplLogo.jpg";
const imageUrl2 = "../Logo-english.jpg";
const imageUrl3 = "../tc_logo.jpg";

function TransactionsMerchantReport() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  console.log("useAuth():" + token + "," + roles + "," + merchantId);
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);
  const [merchants, setMerchants] = useState({ items: [] });
  const [merchantName, setMerchantName] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();

  const [transactionFilter, setTransactionFilter] = useState({ merchantId: merchantId });
  const [submittedTransactionFilter, setSubmittedTransactionFilter] = useState({
    merchantId: merchantId,
  });
  const [transactions, setTransactions] = useState([]);

  const [totalTrans, setTotalTrans] = useState(0);
  const [totalSalesExchangeTrans, setTotalSalesExchangeTrans] = useState(0);

  const [totalAmountSales, setTotalAmountSales] = useState(0);
  const [amountSalesExchange, setAmountSalesExchange] = useState(0);

  const [totalVoidAmt, setTotalVoidAmt] = useState(0);
  const [totalVoid, setTotalVoid] = useState(0);

  const [averageSpending, setAverageSpending] = useState(0);
  const [highestTransactionValue, setHighestTransactionValue] = useState(0);
  const [lowestTransactionValue, setLowestTransactionValue] = useState(0);

  const [pointsGainedForTheTenant, setPointsGainedForTheTenant] = useState(0);

  const params = useParams();
  const { columns, rows } = authorsTableData(transactions);

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedDate(dateF);
  };
  const handleEndDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedEndDate(dateF);
  };

  const handleChangeTransactionType = (event) => {
    const selectedValue = event.target.value;
    console.log("value:" + selectedValue);

    setSelectedTransactionType(selectedValue);
  };
  /**useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, merchantId: merchantId }));
  }, [merchantId]);*/
  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, startDate: selectedDate }));
  }, [selectedDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, endDate: selectedEndDate }));
  }, [selectedEndDate]);

  useEffect(() => {
    if (selectedTransactionType !== "all") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: selectedTransactionType,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: "",
      }));
    }
  }, [selectedTransactionType]);

  //console.log("access TransactionReport");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();
  const getMerchant = async () => {
    const url = `${Api.merchant}/Merchant/getAllShortData?${merchantId}`;
    console.log("url" + url);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        setMerchants(arr);
      })
      .catch((err) => {
        console.error("There was an error in getMerchant!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    console.log("getMerchant e");

    getMerchant();
  }, []);
  useEffect(() => {
    console.log("getMerchant e a");

    let merchant = merchants.items.find((a) => a.id == merchantId);
    let merchantName = merchant ? merchant.name : null;
    console.log("getMerchant e a merchantName *:" + merchantName);
    setMerchantName(merchantName);
  }, [merchants, merchantId]);
  //get data from server

  const getTransaction = () => {
    const url = `${Api.report}/TransactionReport/TransactionSeparateVoidReport`;
    if (!submittedTransactionFilter.merchantId) {
      return;
    }
    console.log("array&3:" + JSON.stringify(submittedTransactionFilter));

    axios
      .post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        setTransactions(arr);
      })
      .catch((err) => {
        console.error("There was an error in getTransaction!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    console.log("getTransaction()");
    getTransaction();
  }, [submittedTransactionFilter]);

  //end get data from server
  const tableRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  //  Function to load and draw an image
  const loadAndDrawImage = async (doc, url, x, y, width, height) => {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        let reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          doc.addImage(reader.result, "JPEG", x, y, width, height);
          resolve();
        };
      };
      xhr.send();
    });
  };

  // Function to calculate average spending

  const calculateTotalPrivilegePLUSTransactions = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending;
    return averageSpending.toFixed(2);
  };
  // Function to calculate average spending
  const calculateAverageSpending = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending / transactions.length;
    return averageSpending.toFixed(2);
  };

  const calculateAverageSpendingPurchaseExchange = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending / transactions.length;
    return averageSpending.toFixed(2);
  };

  // Function to calculate the maximum spending
  const calcHighestTransactionValue = () => {
    const maxSpending = transactions.reduce((max, curr) => {
      return Math.max(max, curr.transactionValue);
    }, 0);

    return maxSpending.toFixed(2);
  };

  // Function to calculate the maximum spending
  /*const calcLowestTransactionValue = () => {
    const minSpending = transactions.reduce((min, curr) => {
      return Math.min(min, curr.transactionValue);
    }, transactions[0].transactionValue);

    return minSpending.toFixed(2);
  };*/
  const calcLowestTransactionValue = () => {
    // Filter transactions to only include positive values
    const positiveTransactions = transactions.filter(
      (transaction) => transaction.transactionValue > 0
    );

    if (positiveTransactions.length === 0) {
      return 0; // Return 0 if there are no positive transactions. You can adjust this default value if needed.
    }

    // Find the lowest positive transaction value
    const minSpending = positiveTransactions.reduce((min, curr) => {
      return Math.min(min, curr.transactionValue);
    }, positiveTransactions[0].transactionValue);

    return minSpending.toFixed(2);
  };

  // Function to calculate average spending
  const calculatePointsGainedForTheTenant = () => {
    const totalPoints = transactions.reduce((acc, curr) => {
      return acc + curr.points;
    }, 0);
    return totalPoints.toFixed(2);
  };
  // Function to generate the PDF document
  // ... (previous code)

  useEffect(() => {
    const result = calculateTransactionDetails();

    setTotalTrans(result.totalPrivilegePLUSTransactions);
    setTotalSalesExchangeTrans(result.TotalSalesExchangeTrans);
    setAmountSalesExchange(result.AmountSalesExchange);
    setTotalAmountSales(result.totalSaleValue);
    setTotalVoidAmt(result.totalVoidAmount);
    setTotalVoid(result.totalVoidTransactions);
    setAverageSpending(calculateAverageSpending());
    setHighestTransactionValue(calcHighestTransactionValue());
    setLowestTransactionValue(calcLowestTransactionValue());
    setPointsGainedForTheTenant(calculatePointsGainedForTheTenant());
  }, [transactions]);

  function calculateTransactionDetails() {
    let totalVoidAmount = 0;
    let totalNumberOfVoidTransactions = 0;
    let totalNumberOfTransactions = 0;

    //total of every thing
    let totalSalesAmount = 0;
    //////////////
    //////Purchase,Exchange,Void-Refund
    let totalSalesExchangeTrans = 0;
    let amountSalesExchange = 0;
    ////////

    for (let transaction of transactions) {
      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange" ||
        transaction.transactionType === "Void-Refund"
      ) {
        totalVoidAmount += transaction.transactionValue;
        totalNumberOfVoidTransactions++;
      }
      totalSalesAmount += transaction.transactionValue;
      totalNumberOfTransactions++;

      if (
        transaction.transactionType === "Purchase" ||
        transaction.transactionType === "Exchange"
      ) {
        totalSalesExchangeTrans++;
      }
      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange"
      ) {
        totalSalesExchangeTrans--;
      }
      if (
        transaction.transactionType === "Purchase" ||
        transaction.transactionType === "Exchange" ||
        transaction.transactionType === "Void-Refund"
      ) {
        //totalSalesExchangeTrans++;
        amountSalesExchange += transaction.transactionValue;
      }

      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange" ||
        transaction.transactionType === "Refund"
      ) {
        //totalSalesExchangeTrans++;
        amountSalesExchange += transaction.transactionValue;
      }
    }

    // Calculate net sales
    //let totalNetSales = totalSalesAmount + totalRefundAmount; // Refunds are usually negative values, so adding them will subtract from the sales.

    return {
      totalVoidAmount: totalVoidAmount,
      totalVoidTransactions: totalNumberOfVoidTransactions,
      totalPrivilegePLUSTransactions: totalNumberOfTransactions,
      totalSaleValue: totalSalesAmount,
      TotalSalesExchangeTrans: totalSalesExchangeTrans,
      AmountSalesExchange: amountSalesExchange,
    };
  }

  function formatAsAccounting(value) {
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const Download = () => {
    switch (downloadFormat.toLowerCase()) {
      case "pdf":
        generatePDF();
        break;
      case "xlsx":
        generateXLSX();
        break;
      case "xls":
        generateXLS2();
        break;
      case "csv":
        generateCSV();
        break;
    }
  };
  const generatePDF = async () => {
    let arrayOfObjects = transactions;
    // Sorting the array by dateOfTransaction in ascending order
    arrayOfObjects.sort((a, b) => new Date(a.dateOfTransaction) - new Date(b.dateOfTransaction));
    const doc = new jsPDF();

    const originalWidth1 = 708;
    const originalHeight1 = 184;
    //const newWidth1 = 50;
    //const newHeight1 = (originalHeight1 * newWidth1) / originalWidth1;
    const newHeight1 = 12;
    const newWidth1 = (originalWidth1 * newHeight1) / originalHeight1;
    const startX1 = (210 - newWidth1) / 2;
    const startX3 = 210 - 45;

    // Setting default font at the start
    //doc.setFont("normal");
    doc.setFont("helvetica"); // Assuming you want helvetica, which is a default for many PDFs. Adjust as needed.
    doc.setFontSize(8);
    //doc.fontWeight(400);
    //doc.addImage(base64data1, "JPEG", startX1, 20, newWidth1, newHeight1);
    // 1. Centering Images: Ensure symmetric placement
    const middle = 210 / 2;
    //await loadAndDrawImage(doc, imageUrl1, startX1, 20, 50, 12);
    //await loadAndDrawImage(doc, imageUrl2, 0, 18, 50, 16);
    //await loadAndDrawImage(doc, imageUrl3, startX3, 20, 40, 12);
    await loadAndDrawImage(doc, imageUrl1, middle - 25, 20.5, 48, 12); // Centered
    await loadAndDrawImage(doc, imageUrl2, 13, 21, 40, 12);
    await loadAndDrawImage(doc, imageUrl3, 210 - 55, 21, 40, 12);

    const columns = [
      "Date",
      "PrivilegePLUS ID",
      "Receipt No.",
      "                     ",
      "Points",
      // "Balance",
      "Transaction Type",
      //"Is Canceled",
    ];

    const rows = arrayOfObjects
      .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
      .map((transaction) => [
        formatDate(transaction.dateOfTransaction),
        extendedFun.hideMiddleCharacters(transaction.memberCode),
        transaction.transactionNumber,
        "AED " + formatAsAccounting(transaction.transactionValue),
        transaction.points || "-",
        //transaction.pointbalance,
        transaction.transactionType,
        //transaction.isCanceled ? "Yes" : "No",
      ]);

    const totalValue = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
    const totalTransactions = arrayOfObjects.length;

    const centeredText = (text, y) => {
      const textWidth =
        (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor;
      const textOffset = (doc.internal.pageSize.width - textWidth) / 2;
      doc.text(text, textOffset, y);
    };

    doc.setFontSize(9);
    const title = merchantName ? "Tenant Transactions Report " : "Tenant Transactions Report";

    centeredText(title, 45);
    doc.setFontSize(9);

    //date
    let startDate = new Date();
    let endDate = new Date();
    if (selectedDate) {
      startDate = formatDate(selectedDate);
    }
    if (selectedEndDate) {
      endDate = formatDate(selectedEndDate);
    }

    let dateText = "";
    if (selectedEndDate) {
      dateText = `Start Date: ${startDate} - End Date: ${endDate}`;
    } else {
      if (selectedDate) {
        dateText = `Start Date: ${startDate}`;
      }
    }

    doc.line(14, 50, 196, 50);

    centeredText(dateText, 56);

    doc.line(14, 60, 196, 60);

    console.log("merchantName:" + merchantName);

    centeredText("Tenant Name: " + merchantName, 66);

    //table of data
    // 2. Column Alignment
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 70,
      styles: {
        fontSize: 7.5,
        fontWeight: 400,
        fontColor: "#000",
        textColor: 0,
      },
      columnStyles: {
        0: { halign: "center" },
        1: { halign: "center" },
        2: { halign: "center" },
        3: { halign: "right" }, // Transaction Amount
        4: { halign: "right" }, // Points
        5: { halign: "center" }, // Transaction Type
      },
      headStyles: {
        font: "helvetica", // Explicitly set the font here
        fontSize: 8,
        fontWeight: 400,
        fillColor: [171, 142, 102],
        halign: "center",
        fontColor: "#000",
      },
      didDrawCell: function (data) {
        // Check if the cell is a header and is the fourth column
        if (data.section === "head" && data.column.index === 3) {
          const cell = data.cell;
          const cellText = Array.isArray(cell.text) ? cell.text.join(" ") : cell.text; // Ensure the text is a string
          const txtWidth =
            (doc.getStringUnitWidth(cellText) * cell.styles.fontSize) / doc.internal.scaleFactor;
          const x = cell.x + cell.width - txtWidth - 12; // 3 is a padding value, adjust as required
          doc.text("Transaction Amount", x, cell.y + cell.height - 2.5); // Adjust as required
        }
      },
    });
    // Calculate the height required for the table
    const tableHeight = doc.autoTable.previous.finalY + 40;

    // Function to check if there's enough space on the current page for content
    const hasEnoughSpace = (contentHeight) =>
      doc.internal.pageSize.height - doc.autoTable.previous.finalY >= contentHeight;

    let isNewPage = false;
    let startSummary = doc.autoTable.previous.finalY + 40;
    // Check if there's enough space for the summary on the current page
    if (!hasEnoughSpace(66)) {
      // Add a new page if there's not enough space
      doc.addPage();
      isNewPage = true;
    }

    // Calculate the average spending
    // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
    const result = calculateTransactionDetails();

    const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
    const totalSalesTransactions = result.TotalSalesExchangeTrans;
    //const totalSaleValue = result.totalSaleValue;
    const totalSaleValue = result.AmountSalesExchange;

    const totalVoidAmount = result.totalVoidAmount;
    const totalVoidTransactions = result.totalVoidTransactions;

    const averageSpending = calculateAverageSpending();
    const highestTransactionValue = calcHighestTransactionValue();
    const lowestTransactionValue = calcLowestTransactionValue();

    const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

    // Add summary information
    // Consistent Fonts & Styles
    doc.setFont("helvetica");

    doc.setFontSize(8);

    // 1- Add margin
    if (isNewPage) {
      startSummary = 66;
    }
    // 2- Add a line
    doc.line(14, startSummary - 32, 190, startSummary - 32);
    doc.text("Description ", 14, startSummary - 27);
    doc.text("Values ", 80, startSummary - 27);

    doc.line(14, startSummary - 24, 190, startSummary - 24);

    startSummary -= 8;
    // 3- Add Description, Total No. of Transaction, Total Value of transaction

    doc.text("Total PrivilegePLUS Transactions: ", 14, startSummary - 12);
    doc.text(totalPrivilegePLUSTransactions.toString(), 80, startSummary - 12);

    doc.text("Total Sales Transactions: ", 14, startSummary - 6);
    doc.text(totalSalesTransactions.toString(), 80, startSummary - 6);

    doc.text(`Total Sales Amount: `, 14, startSummary);
    doc.text(`AED ${formatAsAccounting(totalSaleValue).toString()}`, 80, startSummary);

    doc.text("Total Void Transactions: ", 14, startSummary + 6);
    doc.text(totalVoidTransactions.toString(), 80, startSummary + 6);

    doc.text(`Total Void Amount:`, 14, startSummary + 12);
    doc.text(`AED ${formatAsAccounting(totalVoidAmount).toString()}`, 80, startSummary + 12);

    doc.text("Total PrivilegePLUS Points Rewarded: ", 14, startSummary + 18);
    doc.text(formatAsAccounting(pointsGainedForTheTenant).toString(), 80, startSummary + 18);

    doc.text("Summary:", 14, startSummary + 26);

    doc.text(`Highest Transaction Value: `, 14, startSummary + 32);

    doc.text(
      `AED ${formatAsAccounting(highestTransactionValue).toString()}`,
      80,
      startSummary + 32
    );
    doc.text(`Lowest Transaction Value:`, 14, startSummary + 38);
    doc.text(`AED ${formatAsAccounting(lowestTransactionValue).toString()}`, 80, startSummary + 38);
    /*doc.text(`Average Transaction Value:`, 14, startSummary + 54);
    doc.text(`AED ${formatAsAccounting(averageSpending).toString()}`, 80, startSummary + 54);*/

    // Save the PDF
    doc.save("Tenant Transactions Report.pdf");
  };

  // ... (rest of your code)
  const exportToExcel = () => {
    let arrayOfObjects = transactions;

    const rows = arrayOfObjects
      .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
      .map((transaction) => [
        formatDate(transaction.dateOfTransaction),
        transaction.memberCode,
        transaction.transactionNumber,
        formatAsAccounting(transaction.transactionValue),
        transaction.points || "-",
        //transaction.pointbalance,
        transaction.transactionType,
        //transaction.isCanceled ? "Yes" : "No",
      ]);
    // Map over transactions to change the header names
    const modifiedTransactions = rows.map((transaction) => {
      const keys = Object.keys(transaction);
      if (keys.length > 0) keys[0] = "Date";
      if (keys.length > 1) keys[1] = "PrivilegePLUS ID";
      if (keys.length > 2) keys[2] = "Receipt No.";
      if (keys.length > 3) keys[3] = "Transaction Amount";
      if (keys.length > 4) keys[4] = "Points";
      if (keys.length > 5) keys[5] = "Transaction Type";
      return keys.reduce((acc, key, index) => {
        acc[key] = transaction[Object.keys(transaction)[index]];
        return acc;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(modifiedTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "Tenant Transactions Report.xlsx");
  };
  const exportToXLS = () => {
    let arrayOfObjects = transactions;

    const rows = arrayOfObjects
      .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
      .map((transaction) => [
        formatDate(transaction.dateOfTransaction),
        transaction.memberCode,
        transaction.transactionNumber,
        "AED " + formatAsAccounting(transaction.transactionValue),
        transaction.points || "-",
        transaction.transactionType,
      ]);

    // Map over transactions to change the header names
    const modifiedTransactions = rows.map((transaction) => {
      const keys = Object.keys(transaction);
      if (keys.length > 0) keys[0] = "Date";
      if (keys.length > 1) keys[1] = "PrivilegePLUS ID";
      if (keys.length > 2) keys[2] = "Receipt No.";
      if (keys.length > 3) keys[3] = "Transaction Amount";
      if (keys.length > 4) keys[4] = "Points";
      if (keys.length > 5) keys[5] = "Transaction Type";
      return keys.reduce((acc, key, index) => {
        acc[key] = transaction[Object.keys(transaction)[index]];
        return acc;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(modifiedTransactions);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");

    // Save as .xls
    XLSX.writeFile(wb, "Tenant Transactions Report.xls", { bookType: "xls" });
  };

  const exportToCSV = () => {
    let arrayOfObjects = transactions;

    // Filter and map the transactions to the desired format
    const rows = arrayOfObjects
      .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
      .map((transaction) => [
        formatDate(transaction.dateOfTransaction),
        transaction.memberCode,
        transaction.transactionNumber,
        transaction.transactionValue,
        transaction.points || "-",
        transaction.transactionType,
      ]);

    // Create header row for CSV
    const headers = [
      "Date",
      "PrivilegePLUS ID",
      "Receipt No.",
      "Transaction Amount",
      "Points",
      "Transaction Type",
    ];
    rows.unshift(headers);

    // Convert the rows to CSV format
    let csvContent = rows.map((row) => row.join(",")).join("\n");

    // Create a Blob object and download the CSV file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Tenant Transactions Report.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateXLSX = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transactions Report");

      // Load and draw images
      const imageUrl1 = "../pplLogo.jpg";
      const imageUrl2 = "../Logo-english.jpg";
      const imageUrl3 = "../tc_logo.jpg";

      const image1 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl1),
        extension: "jpeg",
      });

      const image2 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl2),
        extension: "jpeg",
      });

      const image3 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl3),
        extension: "jpeg",
      });

      //worksheet.addImage(image1, "C2:D3");
      //worksheet.addImage(image2, "A2:B3");
      //worksheet.addImage(image3, "E2:F3");

      // Adjust columns and rows to fit image dimensions
      worksheet.getColumn(3).width = 15; // Adjust width of column C
      worksheet.getColumn(4).width = 15; // Adjust width of column D
      worksheet.getRow(2).height = 60; // Adjust height of row 2
      worksheet.getRow(3).height = 60; // Adjust height of row 3

      worksheet.addImage(image1, {
        tl: { col: 2, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image2, {
        tl: { col: 0, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image3, {
        tl: { col: 4.9, row: 1 },
        ext: { width: 200, height: 60 },
      });
      // Title & Date range
      worksheet.mergeCells("A5:F5");
      const titleCell = worksheet.getCell("A5");
      const title = merchantName ? "Tenant Transactions Report " : "Tenant Transactions Report";

      titleCell.value = title;
      titleCell.alignment = { horizontal: "center" };

      if (selectedDate) {
        const dateText = selectedEndDate
          ? `Start Date: ${formatDate(selectedDate)} - End Date: ${formatDate(selectedEndDate)}`
          : `Start Date: ${formatDate(selectedDate)}`;

        worksheet.mergeCells("A6:F6");
        const dateCell = worksheet.getCell("A6");
        dateCell.value = dateText;
        dateCell.alignment = { horizontal: "center" };
      }

      worksheet.mergeCells("A7:F7");
      const tenantCell = worksheet.getCell("A7");
      tenantCell.value = "Tenant Name: " + merchantName;
      tenantCell.alignment = { horizontal: "center" };

      // Table Headers (Below Title, Date, and Merchant Name)
      worksheet.addRow(); // Empty row for spacing

      const headerRow = worksheet.addRow([
        "Date",
        "PrivilegePLUS ID",
        "Receipt No.",
        "Transaction Amount",
        "Points",
        "Transaction Type",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      const data = transactions
        .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            date: formatDate(transaction.dateOfTransaction),
            id: extendedFun.hideMiddleCharacters(transaction.memberCode),
            receipt: transaction.transactionNumber,
            amount: "AED " + formatAsAccounting(transaction.transactionValue),
            points: transaction.points || "-",
            type: transaction.transactionType,
          };
        });
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.date,
          transaction.id,
          transaction.receipt,
          transaction.amount,
          transaction.points,
          transaction.type,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "id", width: 24 },
        { key: "receipt", width: 15 },
        { key: "amount", width: 20 },
        { key: "points", width: 15 },
        { key: "type", width: 20 },
      ];
      worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });

      //Add summary
      // Calculate the average spending
      // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      //const totalSaleValue = result.totalSaleValue;
      const totalSalesTransaction = result.TotalSalesExchangeTrans;
      const totalSaleValue = result.AmountSalesExchange;

      //const totalSalesTransactions = result.totalSalesExchangeTrans;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/

      // Assuming currentRow is already defined
      const rangeA = `A${currentRow}`;
      const rangeB = `B${currentRow}`;
      const rangeC = `C${currentRow}`;

      worksheet.mergeCells(rangeA, rangeB);
      worksheet.getCell(rangeA).alignment = { horizontal: "left" };
      worksheet.getCell(rangeC).alignment = { horizontal: "right" };
      worksheet.getCell(rangeA).value = "Description";
      worksheet.getCell(rangeC).value = "Values";

      // Apply borders
      const borderStyle = { style: "thin" };

      worksheet.getCell(rangeA).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };
      worksheet.getCell(rangeB).border = {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle, // This cell is merged with the previous one so no need for the left border.
      };
      worksheet.getCell(rangeC).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };

      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
      currentRow++;
      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalSalesTransaction.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        totalSaleValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        -1 * totalVoidAmount
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
      worksheet.getCell(`C${currentRow}`).value =
        formatAsAccounting(pointsGainedForTheTenant).toString();
      currentRow++;
      currentRow++;
      worksheet.getCell(`A${currentRow}`).value = "Summary:";
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        highestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        lowestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      /*worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        averageSpending
      ).toString()}`;*/
      currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // ... continue with any other operations, such as saving the workbook.

      // Download the Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Tenant Transactions Report.xlsx";
      link.click();
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const generateXLS2 = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transactions Report");

      // Load and draw images
      const imageUrl1 = "../pplLogo.jpg";
      const imageUrl2 = "../Logo-english.jpg";
      const imageUrl3 = "../tc_logo.jpg";

      const image1 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl1),
        extension: "jpeg",
      });

      const image2 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl2),
        extension: "jpeg",
      });

      const image3 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl3),
        extension: "jpeg",
      });

      //worksheet.addImage(image1, "C2:D3");
      //worksheet.addImage(image2, "A2:B3");
      //worksheet.addImage(image3, "E2:F3");

      // Adjust columns and rows to fit image dimensions
      worksheet.getColumn(3).width = 15; // Adjust width of column C
      worksheet.getColumn(4).width = 15; // Adjust width of column D
      worksheet.getRow(2).height = 60; // Adjust height of row 2
      worksheet.getRow(3).height = 60; // Adjust height of row 3

      worksheet.addImage(image1, {
        tl: { col: 2, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image2, {
        tl: { col: 0, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image3, {
        tl: { col: 4, row: 1 },
        ext: { width: 200, height: 60 },
      });
      // Title & Date range
      worksheet.mergeCells("A5:F5");
      const titleCell = worksheet.getCell("A5");
      const title = merchantName ? "Tenant Transactions Report " : "Tenant Transactions Report";

      titleCell.value = title;
      titleCell.alignment = { horizontal: "center" };

      if (selectedDate) {
        const dateText = selectedEndDate
          ? `Start Date: ${formatDate(selectedDate)} - End Date: ${formatDate(selectedEndDate)}`
          : `Start Date: ${formatDate(selectedDate)}`;

        worksheet.mergeCells("A6:F6");
        const dateCell = worksheet.getCell("A6");
        dateCell.value = dateText;
        dateCell.alignment = { horizontal: "center" };
      }

      worksheet.mergeCells("A7:F7");
      const tenantCell = worksheet.getCell("A7");
      tenantCell.value = "Tenant Name: " + merchantName;
      tenantCell.alignment = { horizontal: "center" };

      // Table Headers (Below Title, Date, and Merchant Name)
      worksheet.addRow(); // Empty row for spacing

      const headerRow = worksheet.addRow([
        "Date",
        "PrivilegePLUS ID",
        "Receipt No.",
        "Transaction Amount",
        "Points",
        "Transaction Type",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      const data = transactions
        .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            date: formatDate(transaction.dateOfTransaction),
            id: extendedFun.hideMiddleCharacters(transaction.memberCode),
            receipt: transaction.transactionNumber,
            amount: "AED " + formatAsAccounting(transaction.transactionValue),
            points: transaction.points || "-",
            type: transaction.transactionType,
          };
        });
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.date,
          transaction.id,
          transaction.receipt,
          transaction.amount,
          transaction.points,
          transaction.type,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "id", width: 24 },
        { key: "receipt", width: 15 },
        { key: "amount", width: 20 },
        { key: "points", width: 15 },
        { key: "type", width: 20 },
      ];
      worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });

      //Add summary
      // Calculate the average spending
      // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      const totalSalesTransactions = result.TotalSalesExchangeTrans;
      const totalSaleValue = result.AmountSalesExchange;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/

      // Assuming currentRow is already defined
      const rangeA = `A${currentRow}`;
      const rangeB = `B${currentRow}`;
      const rangeC = `C${currentRow}`;

      worksheet.mergeCells(rangeA, rangeB);
      worksheet.getCell(rangeA).alignment = { horizontal: "left" };
      worksheet.getCell(rangeC).alignment = { horizontal: "right" };
      worksheet.getCell(rangeA).value = "Description";
      worksheet.getCell(rangeC).value = "Values";

      // Apply borders
      const borderStyle = { style: "thin" };

      worksheet.getCell(rangeA).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };
      worksheet.getCell(rangeB).border = {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle, // This cell is merged with the previous one so no need for the left border.
      };
      worksheet.getCell(rangeC).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };

      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalSalesTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        totalSaleValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        -1 * totalVoidAmount
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
      worksheet.getCell(`C${currentRow}`).value =
        formatAsAccounting(pointsGainedForTheTenant).toString();
      currentRow++;
      currentRow++;
      worksheet.getCell(`A${currentRow}`).value = "Summary:";
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        highestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        lowestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      /*worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        averageSpending
      ).toString()}`;*/
      currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // ... continue with any other operations, such as saving the workbook.

      // Download the Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Tenant Transactions Report.xls";
      link.click();
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const generateCSV = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transactions Report");

      // Load and draw images
      const imageUrl1 = "../pplLogo.jpg";
      const imageUrl2 = "../Logo-english.jpg";
      const imageUrl3 = "../tc_logo.jpg";

      const image1 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl1),
        extension: "jpeg",
      });

      const image2 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl2),
        extension: "jpeg",
      });

      const image3 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl3),
        extension: "jpeg",
      });

      //worksheet.addImage(image1, "C2:D3");
      //worksheet.addImage(image2, "A2:B3");
      //worksheet.addImage(image3, "E2:F3");

      // Adjust columns and rows to fit image dimensions
      worksheet.getColumn(3).width = 15; // Adjust width of column C
      worksheet.getColumn(4).width = 15; // Adjust width of column D
      worksheet.getRow(2).height = 60; // Adjust height of row 2
      worksheet.getRow(3).height = 60; // Adjust height of row 3

      worksheet.addImage(image1, {
        tl: { col: 2, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image2, {
        tl: { col: 0, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image3, {
        tl: { col: 4, row: 1 },
        ext: { width: 200, height: 60 },
      });
      // Title & Date range
      worksheet.mergeCells("A5:F5");
      const titleCell = worksheet.getCell("A5");
      titleCell.value = "Transaction Report - By Tenant";
      titleCell.alignment = { horizontal: "center" };

      if (selectedDate) {
        const dateText = selectedEndDate
          ? `Start Date: ${formatDate(selectedDate)} - End Date: ${formatDate(selectedEndDate)}`
          : `Start Date: ${formatDate(selectedDate)}`;

        worksheet.mergeCells("A6:F6");
        const dateCell = worksheet.getCell("A6");
        dateCell.value = dateText;
        dateCell.alignment = { horizontal: "center" };
      }

      worksheet.mergeCells("A7:F7");
      const tenantCell = worksheet.getCell("A7");
      tenantCell.value = "Tenant Name: " + merchantName;
      tenantCell.alignment = { horizontal: "center" };

      // Table Headers (Below Title, Date, and Merchant Name)
      worksheet.addRow(); // Empty row for spacing

      const headerRow = worksheet.addRow([
        "Date",
        "PrivilegePLUS ID",
        "Receipt No.",
        "Transaction Amount",
        "Points",
        "Transaction Type",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      const data = transactions
        .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            date: formatDate(transaction.dateOfTransaction),
            id: transaction.memberCode,
            receipt: transaction.transactionNumber,
            amount: "AED " + formatAsAccounting(transaction.transactionValue),
            points: transaction.points || "-",
            type: transaction.transactionType,
          };
        });
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.date,
          transaction.id,
          transaction.receipt,
          transaction.amount,
          transaction.points,
          transaction.type,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "id", width: 24 },
        { key: "receipt", width: 15 },
        { key: "amount", width: 20 },
        { key: "points", width: 15 },
        { key: "type", width: 20 },
      ];
      worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });

      //Add summary
      // Calculate the average spending
      // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      const totalSaleValue = result.totalSaleValue;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/

      // Assuming currentRow is already defined
      const rangeA = `A${currentRow}`;
      const rangeB = `B${currentRow}`;
      const rangeC = `C${currentRow}`;

      worksheet.mergeCells(rangeA, rangeB);
      worksheet.getCell(rangeA).alignment = { horizontal: "left" };
      worksheet.getCell(rangeC).alignment = { horizontal: "right" };
      worksheet.getCell(rangeA).value = "Description";
      worksheet.getCell(rangeC).value = "Values";

      // Apply borders
      const borderStyle = { style: "thin" };

      worksheet.getCell(rangeA).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };
      worksheet.getCell(rangeB).border = {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle, // This cell is merged with the previous one so no need for the left border.
      };
      worksheet.getCell(rangeC).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };

      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        totalSaleValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        -1 * totalVoidAmount
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
      worksheet.getCell(`C${currentRow}`).value =
        formatAsAccounting(pointsGainedForTheTenant).toString();
      currentRow++;
      currentRow++;
      worksheet.getCell(`A${currentRow}`).value = "Summary:";
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        highestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        lowestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      /*worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        averageSpending
      ).toString()}`;*/
      currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // ... continue with any other operations, such as saving the workbook.

      // Download the Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Tenant Transactions Report.csv";
      link.click();
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  const generateXLS = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transactions Report");
      // ... [the rest of your existing code to set up the workbook]

      // Load and draw images
      const imageUrl1 = "../pplLogo.jpg";
      const imageUrl2 = "../Logo-english.jpg";
      const imageUrl3 = "../tc_logo.jpg";

      const image1 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl1),
        extension: "jpeg",
      });

      const image2 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl2),
        extension: "jpeg",
      });

      const image3 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl3),
        extension: "jpeg",
      });

      //worksheet.addImage(image1, "C2:D3");
      //worksheet.addImage(image2, "A2:B3");
      //worksheet.addImage(image3, "E2:F3");

      // Adjust columns and rows to fit image dimensions
      worksheet.getColumn(3).width = 15; // Adjust width of column C
      worksheet.getColumn(4).width = 15; // Adjust width of column D
      worksheet.getRow(2).height = 60; // Adjust height of row 2
      worksheet.getRow(3).height = 60; // Adjust height of row 3

      worksheet.addImage(image1, {
        tl: { col: 2, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image2, {
        tl: { col: 0, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image3, {
        tl: { col: 4, row: 1 },
        ext: { width: 200, height: 60 },
      });
      // Title & Date range
      worksheet.mergeCells("A5:F5");
      const titleCell = worksheet.getCell("A5");
      titleCell.value = "Transaction Report - By Tenant";
      titleCell.alignment = { horizontal: "center" };

      if (selectedDate) {
        const dateText = selectedEndDate
          ? `Start Date: ${formatDate(selectedDate)} - End Date: ${formatDate(selectedEndDate)}`
          : `Start Date: ${formatDate(selectedDate)}`;

        worksheet.mergeCells("A6:F6");
        const dateCell = worksheet.getCell("A6");
        dateCell.value = dateText;
        dateCell.alignment = { horizontal: "center" };
      }

      worksheet.mergeCells("A7:F7");
      const tenantCell = worksheet.getCell("A7");
      tenantCell.value = "Tenant Name: " + merchantName;
      tenantCell.alignment = { horizontal: "center" };

      // Table Headers (Below Title, Date, and Merchant Name)
      worksheet.addRow(); // Empty row for spacing

      const headerRow = worksheet.addRow([
        "Date",
        "PrivilegePLUS ID",
        "Receipt No.",
        "Transaction Amount",
        "Points",
        "Transaction Type",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      const data = transactions
        .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            date: formatDate(transaction.dateOfTransaction),
            id: transaction.memberCode,
            receipt: transaction.transactionNumber,
            amount: "AED " + formatAsAccounting(transaction.transactionValue),
            points: transaction.points || "-",
            type: transaction.transactionType,
          };
        });
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.date,
          transaction.id,
          transaction.receipt,
          transaction.amount,
          transaction.points,
          transaction.type,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "id", width: 24 },
        { key: "receipt", width: 15 },
        { key: "amount", width: 20 },
        { key: "points", width: 15 },
        { key: "type", width: 20 },
      ];
      worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });

      //Add summary
      // Calculate the average spending
      // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      const totalSaleValue = result.totalSaleValue;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      averageSpendingPurchaseExchange = calculateAverageSpendingPurchaseExchange();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/

      // Assuming currentRow is already defined
      const rangeA = `A${currentRow}`;
      const rangeB = `B${currentRow}`;
      const rangeC = `C${currentRow}`;

      worksheet.mergeCells(rangeA, rangeB);
      worksheet.getCell(rangeA).alignment = { horizontal: "left" };
      worksheet.getCell(rangeC).alignment = { horizontal: "right" };
      worksheet.getCell(rangeA).value = "Description";
      worksheet.getCell(rangeC).value = "Values";

      // Apply borders
      const borderStyle = { style: "thin" };

      worksheet.getCell(rangeA).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };
      worksheet.getCell(rangeB).border = {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle, // This cell is merged with the previous one so no need for the left border.
      };
      worksheet.getCell(rangeC).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };

      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        totalSaleValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        totalVoidAmount
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
      worksheet.getCell(`C${currentRow}`).value =
        formatAsAccounting(pointsGainedForTheTenant).toString();
      currentRow++;
      currentRow++;
      worksheet.getCell(`A${currentRow}`).value = "Summary:";
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        highestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        lowestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      /*worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        averageSpending
      ).toString()}`;*/
      currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // Write to XLSX first
      const buffer = await workbook.xlsx.writeBuffer();

      // Convert XLSX buffer to XLS
      const xlsxBuffer = new Uint8Array(buffer);
      const xlsxBook = XLSX.read(xlsxBuffer, { type: "array" });
      const xlsBuffer = XLSX.write(xlsxBook, { bookType: "xls", type: "buffer" });

      // Download the Excel in XLS format
      const blob = new Blob([xlsBuffer], {
        type: "application/vnd.ms-excel",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Tenant Transactions Report.xls";
      link.click();
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  // Utility function to get base64 encoded image from URL
  const getBase64ImageFromUrl = async (imageUrl) => {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const [downloadFormat, setDownloadFormat] = useState("pdf");

  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="small">
      <MDTypography display="block" variant="caption" color="black" fontWeight="small">
        {data}
      </MDTypography>
    </MDBox>
  );
  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            //bgColor="#AB8E66"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={3}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white">
              Tenant Transactions Report - {merchantName && merchantName}
            </MDTypography>
          </MDBox>

          <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={1}>
            {/*<filters*/}
            <MDBox display="flex" alignItems="center">
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    onChange={(newValue) => handleDateChange(newValue)}
                    sx={{
                      "& .MuiFormLabel-root.MuiInputLabel-root": {
                        marginTop: "0px", // Adjust the value as per your requirements
                      },
                      "& .MuiInputBase-root": {
                        height: "46px",
                        width: "160px",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px",
                        borderWidth: "0px",
                      },
                    }}
                  />
                </LocalizationProvider>
                <MDBox ml={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      sx={{
                        "& .MuiFormLabel-root.MuiInputLabel-root": {
                          marginTop: "0px", // Adjust the value as per your requirements
                        },
                        "& .MuiInputBase-root": {
                          height: "46px",
                          width: "160px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          margin: "0px",
                          paddingRight: "10px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          borderWidth: "0px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </MDBox>
              </MDBox>
              {/*<MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <Select
                  label="Transaction Type"
                  displayEmpty
                  defaultValue={""}
                  onChange={handleChangeTransactionType}
                  style={{ width: "160px", height: "46px", marginLeft: 10, color: "red" }} // Set the width you desire
                  InputLabelProps={{ style: { color: "black" } }} // Here's the change for the label color
                >
                  {transactionTypes.map((element, index) => (
                    <MenuItem key={index} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>*/}
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <FormControl
                  style={{
                    marginLeft: 10,
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    htmlFor="transaction-type-select"
                    style={{
                      marginLeft: 10,
                      height: "46px",
                    }}
                  >
                    Transaction Type
                  </InputLabel>
                  <Select
                    id="transaction-type-select"
                    label="Transaction Type"
                    //defaultValue={""}
                    onChange={handleChangeTransactionType}
                    style={{ width: "160px", height: "46px" }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }} // Disable the underline here
                  >
                    {transactionTypes.map((element, index) => (
                      <MenuItem key={index} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <MDButton
                  variant="gradient"
                  color="info"
                  //bgColor="#AB8E66"
                  borderRadius="lg"
                  coloredShadow="success"
                  onClick={() => {
                    console.log("search");
                    setSubmittedTransactionFilter(transactionFilter);
                  }}
                  style={{ width: "90px", height: "44px", marginLeft: 10 }} // Set the width you desire
                >
                  Search
                </MDButton>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="row"
                mt={2}
                ml={4}
                style={{ height: "44px", marginLeft: 20 }}
              >
                <label
                  className="label-small"
                  style={{
                    fontSize: "14px", // Bigger font size
                    //padding: "8px 12px", // Padding to increase height and width
                    marginLeft: 4,
                  }}
                >
                  {"Export to:"}
                </label>
                <select
                  style={{
                    fontSize: "14px", // Bigger font size
                    //padding: "8px 12px", // Padding to increase height and width
                    border: "1px solid #ccc", // A subtle border (optional)
                    borderRadius: "4px", // Rounded corners (optional)
                    marginLeft: 4,
                    height: "40px",
                  }}
                  onChange={(event) => {
                    let selectedValue = event.target.value;
                    console.log(JSON.stringify(selectedValue));
                    setDownloadFormat(selectedValue);
                  }}
                >
                  <option value="pdf">PDF</option>
                  <option value="xlsx">XLSX</option>
                  <option value="xls">XLS</option>
                  {/*<option value="csv">CSV</option>*/}
                </select>
                <MDButton
                  variant="gradient"
                  color="info"
                  //bgColor="#AB8E66"
                  borderRadius="lg"
                  coloredShadow="success"
                  onClick={Download}
                  style={{ width: "4px", height: "44px", marginLeft: 10 }} // Set the width you desire
                >
                  <DownloadIcon style={{ fontSize: "medium", width: "24", height: "24" }} />
                </MDButton>
              </MDBox>
              {/*<MDBox display="flex" alignItems="center" justifyContent="row" mt={2} ml={4}>
                <label class="label-small">{"Export to:"}</label>

                <MDButton onClick={generatePDF} style={{ width: "10px" }}>
                  PDF
                </MDButton>
                <MDButton onClick={exportToExcel} style={{ width: "10px" }}>
                  XLSX
                </MDButton>
                <MDButton onClick={exportToXLS} style={{ width: "10px" }}>
                  XLS
                </MDButton>
                <MDButton onClick={exportToCSV} style={{ width: "10px" }}>
                  CSV
                </MDButton>
              </MDBox>*/}
            </MDBox>

            {/*<filters*/}
          </MDBox>
          {/*<DataTable*/}
          <MDBox mx={2} mt={1}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries
              noEndBorder
              align={"middle"}
              //canSearch
            />
          </MDBox>

          <MDBox mx={2} mt={1} p={2} mb={3}>
            <MDBox display="block" color="text" fontWeight="small">
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total PrivilegePlus Transactions: {totalTrans}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Sales Transactions: {totalSalesExchangeTrans}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Sales Amount: AED{" "}
                {extendedFun.formatNumberAccountingStyle(amountSalesExchange)}
                {/*Total Sales Amount: AED {totalAmountSales}*/}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Void Transactions: {totalVoid}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Void Amount: AED {extendedFun.formatNumberAccountingStyle(totalVoidAmt)}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={2}
              >
                Total PrivilegePlus Points Rewarded: {pointsGainedForTheTenant}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Summary:
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Highest Transaction Value: AED{" "}
                {extendedFun.formatNumberAccountingStyle(highestTransactionValue)}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Lowest Transaction Value: AED{" "}
                {extendedFun.formatNumberAccountingStyle(lowestTransactionValue)}
              </MDTypography>

              {/*<MDTypography display="block" variant="caption" color="black" fontWeight="small">
                Average Sales Transaction Value: AED{" "}
                {(totalAmountSales / totalSalesExchangeTrans).toFixed(2)}
              </MDTypography>*/}

              {/*<MDTypography display="block" variant="caption" color="black" fontWeight="small">
                Average Transaction Value: AED {averageSpending}
              </MDTypography>*/}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default TransactionsMerchantReport;
