/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PrintIcon from "@mui/icons-material/Print";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { jsPDF } from "jspdf";

function WinnerInfoCard({
  color,
  icon,
  name,
  nationality,
  gender,
  mobile,
  email,
  prizeDetails,
  orderWinner,
  drawDate,
  couponCode,
  memberCode,
}) {
  function printWinner() {
    const doc = new jsPDF();

    // Add a title to the PDF
    doc.setFontSize(20);
    doc.text("PPL SHOP, SAVE & WIN", 20, 20);

    doc.text("Winner Details", 20, 32);

    // Below this, add the winner details. Adjust the y-coordinate for each subsequent line
    const lineHeight = 10; // this can be adjusted
    let currentY = 48; // initial y-coordinate after the title

    const details = [
      { label: "Draw Date", value: drawDate },
      { label: "Prize Details", value: prizeDetails },
      { label: "Name", value: name },
      { label: "PrivilegePLUS ID", value: memberCode },
      { label: "Gender", value: gender },
      { label: "Mobile", value: mobile },
      { label: "Email", value: email },
      { label: "Nationality", value: nationality },
      { label: "Coupon Number", value: couponCode },
    ];

    doc.setFontSize(14);

    details.forEach((detail) => {
      if (detail.value) {
        doc.text(`${detail.label}: ${detail.value}`, 20, currentY);
        currentY += lineHeight;
      }
    });

    // Output the PDF
    doc.save("winner_details.pdf");
  }
  return (
    <Card>
      <MDBox p={2} mx={3} display="flex" justifyContent="center" color="success">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor="info"
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <EmojiEventsIcon fontSize="large">{icon}</EmojiEventsIcon>
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {name}
        </MDTypography>
        {couponCode && (
          <MDTypography color="text" variant="body2">
            Coupon Code: {couponCode}
          </MDTypography>
        )}
        {nationality && (
          <MDTypography color="text" variant="body2">
            Nationality: {nationality}
          </MDTypography>
        )}

        {mobile && (
          <MDTypography color="text" variant="body2">
            Mobile: {mobile}
          </MDTypography>
        )}
        {email && (
          <MDTypography color="text" variant="body2">
            Email: {email}
          </MDTypography>
        )}
        {gender && (
          <MDTypography color="text" variant="body2">
            Gender: {gender}
          </MDTypography>
        )}

        {orderWinner > 0 && (
          <MDTypography color="text" variant="body2">
            Order of winner: {orderWinner}
          </MDTypography>
        )}

        {drawDate && (
          <MDTypography color="text" variant="body2">
            Draw date: {drawDate}
          </MDTypography>
        )}

        {gender && !prizeDetails ? null : <Divider />}
        {prizeDetails && (
          <MDTypography variant="h6" fontWeight="medium">
            Prize: {prizeDetails}
          </MDTypography>
        )}
        <MDBox p={2} mt="auto">
          <MDButton
            // disabled={!isActive}
            variant="gradient"
            color="info"
            onClick={printWinner}
          >
            <PrintIcon></PrintIcon>&nbsp;Print
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of DefaultInfoCard
WinnerInfoCard.defaultProps = {
  color: "info",
  drawDate: "",
};

// Typechecking props for the DefaultInfoCard
WinnerInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  // title: PropTypes.string.isRequired,
  // description: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  name: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  prizeDetails: PropTypes.string.isRequired,
  orderWinner: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  drawDate: PropTypes.string,
  mobile: PropTypes.string,
  email: PropTypes.string,
  couponCode: PropTypes.string,
  memberCode: PropTypes.string,
};

export default WinnerInfoCard;
