// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.css */

.label-small {
  font-size: 12px;
  font-weight: 400;
}

/* styles.css or equivalent */
.datePickerInput {
  height: 40px !important; /* The use of !important might be necessary to override default styles, but try to avoid it if possible */
}

.datePickerInput .datePicker-innerInput {
  height: 40px;
}

.inputStyle {
  height: 30px !important;
  padding: 3px !important;
}

.labelStyle {
  lineheight: "1 !important";
  fontsize: "14px !important";
}

.inputContainer {
  height: 30px !important; 
}

.inputField {
  height: 30px !important; 
  padding-top: -10px !important; 
  padding-bottom: -10px !important;
  font-size: 14px !important; 
}`, "",{"version":3,"sources":["webpack://./src/layouts/TransactionReportV1/styles.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA,6BAA6B;AAC7B;EACE,uBAAuB,EAAE,yGAAyG;AACpI;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,6BAA6B;EAC7B,gCAAgC;EAChC,0BAA0B;AAC5B","sourcesContent":["/* style.css */\n\n.label-small {\n  font-size: 12px;\n  font-weight: 400;\n}\n\n/* styles.css or equivalent */\n.datePickerInput {\n  height: 40px !important; /* The use of !important might be necessary to override default styles, but try to avoid it if possible */\n}\n\n.datePickerInput .datePicker-innerInput {\n  height: 40px;\n}\n\n.inputStyle {\n  height: 30px !important;\n  padding: 3px !important;\n}\n\n.labelStyle {\n  lineheight: \"1 !important\";\n  fontsize: \"14px !important\";\n}\n\n.inputContainer {\n  height: 30px !important; \n}\n\n.inputField {\n  height: 30px !important; \n  padding-top: -10px !important; \n  padding-bottom: -10px !important;\n  font-size: 14px !important; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
