// axiosInstance.js
import axios from "axios";

const apiConfig = {
  apiLoyalty: "https://loyalty.privilegeplusloyaltyrewards.com/api",
  merchant: "https://merchant.privilegeplusloyaltyrewards.com/api",
  report: "https://reports.privilegeplusloyaltyrewards.com/api",
};
// Create a custom Axios instance
const axiosApiLoyalty = axios.create({
  baseURL: apiConfig.apiLoyalty,
  headers: { "Content-Type": "application/json" },
  timeout: 10000, // Example timeout
});

const axiosMerchant = axios.create({
  baseURL: apiConfig.merchant,
  headers: { "Content-Type": "application/json" },
  timeout: 10000, // Example timeout
});

const axiosMerchantForm = axios.create({
  baseURL: apiConfig.merchant,
  headers: { "Content-Type": "multipart/form-data" },
  timeout: 10000, // Example timeout
});
const axiosReport = axios.create({
  baseURL: apiConfig.report,
  headers: { "Content-Type": "application/json" },
  timeout: 10000, // Example timeout
});

// Setting up the request interceptor
axiosApiLoyalty.interceptors.request.use(
  (config) => {
    console.log("attach token: ");
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    console.log("token: " + token);
    // If a token exists, add it to the request's Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle any errors that occur during the request setup
    return Promise.reject(error);
  }
);

axiosMerchantForm.interceptors.request.use(
  (config) => {
    console.log("attach token: ");
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    console.log("token: " + token);
    // If a token exists, add it to the request's Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle any errors that occur during the request setup
    return Promise.reject(error);
  }
);

axiosMerchant.interceptors.request.use(
  (config) => {
    console.log("attach token: ");
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    console.log("token: " + token);
    // If a token exists, add it to the request's Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle any errors that occur during the request setup
    return Promise.reject(error);
  }
);

/*axiosReport.interceptors.request.use(
  (config) => {
    console.log("attach token: ");
    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    console.log("token: " + token);
    // If a token exists, add it to the request's Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Return the modified config
    return config;
  },
  (error) => {
    // Handle any errors that occur during the request setup
    return Promise.reject(error);
  }
);*/
// Export the custom Axios instance
export { axiosApiLoyalty, axiosMerchant, axiosReport, axiosMerchantForm };
