/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react"; // This is crucial!

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";

import Sidenav from "examples/Sidenav";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
//import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";

import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

import { ProtectedRoute } from "protectedRoute";
import { useAuth } from "contexts/authContext";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  /*useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);*/

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  ///console.log("route:" + JSON.stringify(routes));
  let { isAuthenticated, merchantId, roles, token } = useAuth();
  console.log("AuthRoles*:" + JSON.stringify(roles));

  const filteredRoutes = routes.filter((item) => {
    // Check if the item has the roles property
    if (!item.roles) {
      return false;
    }

    // Check if any role in the item's roles matches any role in userRoles
    const matchesUserRoles = item.roles.some((role) => roles.includes(role));

    // If userRoles includes "SponsorAdmin", exclude items with "MerchantAdmin"
    if (
      roles.includes("SponsorAdmin") &&
      item.roles.includes("MerchantAdmin") &&
      item.key === "Home2"
    ) {
      return false;
    }

    return matchesUserRoles;
  });
  const getRoutes = (allRoutes) =>
    allRoutes
      .filter((item) => {
        return item.roles && item.roles.some((role) => roles.includes(role));
      })
      .map((route) => {
        if (route.collapse) {
          return getRoutes(route.collapse);
        }

        if (route.route) {
          // Check if the route requires authentication
          if (route.requireAuth) {
            const TestComponent = () => <div>Test</div>;
            const ComponentToRender = route.componentAuth;
            //console.log("route:" + JSON.stringify(route));
            return (
              <Route
                exact
                path={route.route}
                key={route.key}
                //element={<ProtectedRoute>{React.createElement(route.component)}</ProtectedRoute>}
                element={
                  <ProtectedRoute authRoles={route.roles}>
                    <ComponentToRender />
                  </ProtectedRoute>
                }
              />
            );
          } else {
            //if (roles.some((element) => element.toLowerCase() === "sponsoradmin")) {
            return <Route exact path={route.route} element={route.component} key={route.key} />;
            //}
          }
        }

        return null;
      });

  const getRoutes2 = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        // Check if the route requires authentication
        if (route.requireAuth) {
          const TestComponent = () => <div>Test</div>;
          const ComponentToRender = route.componentAuth;
          //console.log("route:" + JSON.stringify(route));
          return (
            <Route
              exact
              path={route.route}
              key={route.key}
              //element={<ProtectedRoute>{React.createElement(route.component)}</ProtectedRoute>}
              element={
                <ProtectedRoute authRoles={route.roles}>
                  <ComponentToRender />
                </ProtectedRoute>
              }
            />
          );
        } else {
          return <Route exact path={route.route} element={route.component} key={route.key} />;
        }
      }

      return null;
    });
  //console.log("$$$" + getRoutes(routes));
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              //brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="PrivilegePLUS"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            {/*<Configurator />*/}
            {configsButton}
          </>
        )}
        {
          //layout === "vr" && <Configurator />
        }
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            //brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="PrivilegePLUS "
            routes={filteredRoutes}
            routes1={routes.filter((item) => {
              // Check if the item has the roles property and if any role matches the UserRoles
              return item.roles && item.roles.some((role) => roles.includes(role));
            })}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/*<Configurator />*/}
          {/*configsButton*/}
        </>
      )}
      {
        //layout === "vr" && <Configurator />
      }
      <Routes>
        {roles && roles.length > 0 && getRoutes(routes)}
        {!roles || (roles.length == 0 && getRoutes2(routes))}
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </ThemeProvider>
  );
}
