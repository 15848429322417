/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import team2 from "assets/images/team-2.jpg";

import { useEffect, useState } from "react";
import Api from "constants/Api";
import axios from "axios";

import * as extendedFun from "extensions/extendedFunctions";
import MDAvatar from "components/MDAvatar";

export default function data(screenStatus) {
  const [happening, setHappening] = useState({ items: [] });
  console.log("data");
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {data}
      </MDTypography>
    </MDBox>
  );

  const InfoImage = ({ image, name }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDAvatar src={image} name={name} size="sm" />
    </MDBox>
  );

  console.log("array");

  const getHappening = () => {
    const url = `${Api.merchant}/HappeningSub/getAll`;
    console.log("url" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      //console.log("array:" + JSON.stringify(arr));
      setHappening(arr);
    });
  };

  useEffect(() => {
    console.log("getHappening()");

    getHappening();
  }, [screenStatus]);

  return {
    columns: [
      { Header: "action", accessor: "action", align: "center" },
      { Header: "mall", accessor: "mall", width: "20%", align: "left" },
      { Header: "category", accessor: "category", width: "20%", align: "left" },

      { Header: "title", accessor: "title", width: "20%", align: "left" },
      { Header: "subTitle", accessor: "subTitle", width: "20%", align: "left" },
      { Header: "Start Date", accessor: "startDate", align: "center" },
      { Header: "end Date", accessor: "endDate", align: "center" },
      { Header: "showing Date", accessor: "showingDate", align: "center" },
      { Header: "end Showing Date", accessor: "endShowingDate", align: "center" },
      { Header: "image", accessor: "image", align: "center" },
      { Header: "details", accessor: "details", align: "left" },
      { Header: "created By", accessor: "createdBy", align: "center" },
    ],
    rows: happening.items.map((item) => ({
      action: (
        <MDTypography
          component="a"
          href={`/happening/${item.id}`}
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          Edit
        </MDTypography>
      ),
      mall: <Info data={item.mall.name} />,
      category: <Info data={item.happening.name} />,
      title: <Info data={item.title ? item.title : ""} />,
      subTitle: <Info data={item.subTitle ? item.subTitle : ""} />,
      startDate: <Info data={extendedFun.IsoDateToDDMMYY(item.startDate)} />,
      endDate: <Info data={extendedFun.IsoDateToDDMMYY(item.endDate)} />,
      showingDate: <Info data={extendedFun.IsoDateToDDMMYY(item.showingDate)} />,
      endShowingDate: <Info data={extendedFun.IsoDateToDDMMYY(item.endShowingDate)} />,
      //image: <InfoImage image={team2} name={item.image ? item.details : ""} />,
      image: <InfoImage image={item.image} name={item.image ? item.details : ""} />,

      details: <Info data={item.details ? item.details : ""} />,
      createdBy: <Info data={item.createdBy ? item.createdBy : ""} />,
    })),
  };
}

/*  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );*/
/*      {
        title: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
        details: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            11/01/19
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        title: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
        details: <Job title="Executive" description="Projects" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            19/09/17
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        title: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
        details: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            24/12/08
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team3} name="Richard Gran" email="richard@creative-tim.com" />,
        function: <Job title="Manager" description="Executive" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            04/10/21
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },
      {
        author: <Author image={team4} name="Miriam Eric" email="miriam@creative-tim.com" />,
        function: <Job title="Programator" description="Developer" />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
          </MDBox>
        ),
        employed: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            14/09/20
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      },*/
