/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { CategoryScale } from "chart.js";
// Register the scales
Chart.register(CategoryScale);

import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from "chart.js";
Chart.register(LineController, LineElement, PointElement, LinearScale, Title);

import { BarElement, BarController } from "chart.js";

// Register the components
Chart.register(BarElement, BarController);

import * as extendedFun from "../../extensions/extendedFunctions";
import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

ChartJS.register(ArcElement, Tooltip, Legend);
// Material Dashboard 2 React components

import ChartDataLabels from "chartjs-plugin-datalabels";

import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useNavigate, useLocation } from "react-router-dom";

// Data

// Dashboard components
import MDTypography from "components/MDTypography";

import { DoughnutController } from "chart.js";
import axios from "axios";
import { useAuth } from "../../contexts/authContext";
import Api from "constants/Api";
import { Typography } from "@mui/material";

function getLastFourMonths() {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();

  const monthsArray = [];
  for (let i = 4; i >= 1; i--) {
    const monthIndex = (currentMonthIndex - i + 12) % 12;
    monthsArray.push(monthNames[monthIndex]);
  }

  return monthsArray;
}

// Register the plugin
//import { Chart } from "chart.js";
Chart.register(ChartDataLabels);
// Example usage:
const optionsLinew = {
  responsive: true,
  layout: {
    padding: {
      top: 50, // Increase the top padding to create space between the title and the bars
    },
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    datalabels: {
      anchor: "end", // 'start', 'center', 'end'
      align: "top", // 'start', 'center', 'end', 'left', 'right', 'top', 'bottom'
      formatter: (value) => value,
      font: {
        weight: "bold",
      },
    },
  },
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  },
};

const optionsLine2 = {
  responsive: true,
  //maintainAspectRatio: false, // Add this line to allow the chart to adjust based on the container's height
  layout: {
    padding: {
      top: 30, // Increase the top padding to create space between the title and the bars
    },
  },
  plugins: {
    legend: {
      position: "top", // Adjust as needed
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    datalabels: {
      anchor: "end",
      align: "top",
      formatter: (value) => value,
      font: {
        weight: "bold",
      },
    },
  },
};

const optionsLine = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30, // Increase the top padding to create space between the title and the bars
    },
  },
  plugins: {
    legend: {
      position: "center",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
    datalabels: {
      anchor: "end",
      align: "top",
      formatter: (value) => value,
      font: {
        weight: "bold",
      },
    },
  },
};
const lastFourMonths = getLastFourMonths();

const labels = lastFourMonths;

const lineDataTemp = {
  labels,
  datasets: [
    {
      label: "Transactions Count",
      data: [0, 0, 0, 0],
      backgroundColor: "rgba(75, 192, 192, 0.6)",

      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
      //barThickness: 40, // Increase this value to make bars thicker
      //maxBarThickness: 40, // Optional: maximum t
    },
  ],
};

function updateDataValue(value, index) {
  console.log("updateDataValue" + "," + value + "," + index);
  if (index >= 0 && index < lineDataTemp.datasets[0].data.length) {
    lineDataTemp.datasets[0].data[index] = value;
  } else {
    console.error("Index out of range");
  }
}
const pieDataTemp = {
  labels: [],
  datasets: [
    {
      title: "Pie Chart",
      label: "Members",
      data: [],
      backgroundColor: [
        "rgba(75, 192, 192, 0.8)",
        "rgba(54, 162, 235, 0.8)",
        "rgba(255, 206, 86, 1)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(71, 102, 12, 0.8)",
        "rgba(255, 99, 132, 1)",
        "rgba(41, 12, 120, 0.8)",
      ],
      borderColor: [
        "rgba(75, 192, 192, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(71, 102, 12, 0.8)",
        "rgba(255, 99, 132, 1)",
        "rgba(41, 12, 120, 0.8)",
      ],
      borderWidth: 1,
    },
  ],
};
const pieOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        // Custom styling for the legend items
        boxWidth: 20,
        padding: 6,
      },
      fullWidth: true,
      // Custom afterFit function to modify the width
      onClick: null,
      onHover: null,
      afterFit: (legend) => {
        legend.width = legend.chart.width; // Set the legend width to chart width
      },
    },
    title: {
      display: false,
      text: "Pie Chart Example",
    },
    datalabels: {
      display: true,
      color: "#333",
      font: {
        weight: "regular",
      },
      formatter: (value, context) => {
        return value + "%";
      },
    },
  },
};
const pieOptions3 = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      width: "100%",
    },
    title: {
      display: false,
      text: "Pie Chart Example",
    },
    datalabels: {
      display: true,
      color: "#333",
      font: {
        weight: "regular",
      },
      formatter: (value, context) => {
        return value + "%";
      },
    },
  },
};
function getFirstDayOfMonthInIrelandTime() {
  // Define time zones
  const dubaiOffset = 4; // Dubai is UTC+4
  const irelandOffset = 1; // Ireland is UTC+1 during DST (Daylight Saving Time)

  // Get current date in Dubai time
  const now = new Date();
  const dubaiDate = new Date(now.getTime() + dubaiOffset * 60 * 60 * 1000);

  // Get the first day of the month in Dubai time
  const firstDayOfMonthDubai = new Date(dubaiDate.getFullYear(), dubaiDate.getMonth(), 1);

  // Convert the first day of the month to Ireland time
  const irelandDate = new Date(
    firstDayOfMonthDubai.getTime() - (dubaiOffset - irelandOffset) * 60 * 60 * 1000
  );

  // Format the date as yyyy-mm-dd
  const year = irelandDate.getUTCFullYear();
  const month = String(irelandDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(irelandDate.getUTCDate()).padStart(2, "0");

  const hours = String(irelandDate.getUTCHours()).padStart(2, "0");
  const minutes = String(irelandDate.getUTCMinutes()).padStart(2, "0");
  return irelandDate;
  //return `${year}-${month}-${day} ${hours}:${minutes}`;
}

function getFirstDateOfCurrentMonthold() {
  const today = new Date(); // Get the current date
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1); // Set to the first day of the current month
  return firstDay;
}
function getLastDateOfCurrentMonth() {
  const today = new Date(); // Get the current date
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Set to the last day of the current month
  return lastDay;
}

function getFirstDateOfLastMonth() {
  const today = new Date(); // Get the current date
  const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Set to the first day of the last month
  return extendedFun.IsoDateToYYYYMMDD(firstDayOfLastMonth);
}
function getLastDateOfLastMonth() {
  const today = new Date(); // Get the current date
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Set to the last day of the last month
  return extendedFun.IsoDateToYYYYMMDD(lastDayOfLastMonth);
}

function getFirstDateOfLast2Month() {
  const today = new Date(); // Get the current date
  const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 2, 1); // Set to the first day of the last month
  return firstDayOfLastMonth;
}
function getLastDateOfLast2Month() {
  const today = new Date(); // Get the current date
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 0); // Set to the last day of the last month
  return lastDayOfLastMonth;
}

function getFirstDateOfLast3Month() {
  const today = new Date(); // Get the current date
  const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 3, 1); // Set to the first day of the last month
  return firstDayOfLastMonth;
}
function getLastDateOfLast3Month() {
  const today = new Date(); // Get the current date
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 2, 0); // Set to the last day of the last month
  return lastDayOfLastMonth;
}
function getFirstDateOfLast4Month() {
  const today = new Date(); // Get the current date
  const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 4, 1); // Set to the first day of the last month
  return firstDayOfLastMonth;
}
function getLastDateOfLast4Month() {
  const today = new Date(); // Get the current date
  const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 3, 0); // Set to the last day of the last month
  return lastDayOfLastMonth;
}
export function App() {
  return <Bar options={options} data={data} />;
}

//api=[{"labels":"syria","data":20},{"labels":"uae","data":40}]

// Register the components
Chart.register(ArcElement, DoughnutController);
const today = new Date(); // Get today's date
let tomorrow = new Date(today); // Create a new date object for tomorrow
tomorrow.setDate(tomorrow.getDate() + 1); // Add one day to the current date
tomorrow = extendedFun.IsoDateToYYYYMMDD(tomorrow);
function extractKeys(data) {
  return Object.keys(data);
}

function extractValues(data) {
  return Object.values(data);
}
function extractValuesAsPercentage(data) {
  const values = Object.values(data);
  const sum = values.reduce((acc, val) => acc + val, 0);
  return values.map((value) => parseFloat(((value / sum) * 100).toFixed(2)));
}
function generalUsersReport() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  const navigate = useNavigate();

  //const [merchantId, setMerchantId] = useState(310);

  const [screenHeight, setScreenHeight] = useState(0);
  const [summary, setSummary] = useState({});
  const [usersByNationality, setUsersByNationality] = useState([]);

  const [countryNames, setCountryNames] = useState([]);
  const [userCounts, setUserCounts] = useState([]);

  const [total1MonthBefore, setTotal1MonthBefore] = useState([]);
  const [total2MonthBefore, setTotal2MonthBefore] = useState([]);
  const [total3MonthBefore, setTotal3MonthBefore] = useState([]);
  const [totalThisMonthBefore, setTotalThisMonthBefore] = useState([]);

  const [lineData, setLineData] = useState(lineDataTemp);

  const [pieData, setPieData] = useState(pieDataTemp);

  const [submittedTransactionFilter, setSubmittedTransactionFilter] = useState({
    merchantId: merchantId,
    startDate: "2023-03-14",
    endDate: tomorrow,
    transactionType: "purchase",
  });
  useEffect(() => {
    setSubmittedTransactionFilter((prevState) => ({ ...prevState, merchantId: merchantId }));
  }, [merchantId]);

  useEffect(() => {
    console.log("getsummary()");
    console.log("getsummary()" + JSON.stringify(submittedTransactionFilter));

    getSummary();
  }, [submittedTransactionFilter]);

  useEffect(() => {
    console.log("call getCountUsersByNationality()");
    getCountUsersByNationality();
  }, []);

  useEffect(() => {
    console.log("^^^^^^^^^^:" + JSON.stringify(usersByNationality));

    // Extract keys and values
    const newLabels = extractKeys(usersByNationality);
    const newData = extractValuesAsPercentage(usersByNationality);
    console.log("newLabels:" + JSON.stringify(newLabels));
    console.log("newData:" + JSON.stringify(newData));

    // Create a new object for the state to update immutably
    const updatedPieData = {
      ...pieData, // Spread the existing data to maintain other properties
      labels: newLabels,
      datasets: pieData.datasets.map((dataset, index) => {
        if (index === 0) {
          // Assuming you only have one dataset and want to update it
          return {
            ...dataset, // Spread the existing dataset properties
            data: newData, // Update only the data property
          };
        }
        return dataset; // Return unmodified for other datasets
      }),
    };
    console.log("piedata:" + JSON.stringify(pieData));
    // Update the state
    setPieData(updatedPieData);
  }, [usersByNationality]); // Dependency array, effect runs when usersByNationality changes

  const getCountUsersByNationality = () => {
    console.log("******()");

    //change api endpoin
    const url = `${Api.report}/TransactionReport/CountUsersByRegion`;
    console.log("******()" + url);
    console.log("******()" + JSON.stringify(submittedTransactionFilter));
    if (!submittedTransactionFilter.merchantId) {
      //return;
    }
    console.log("array&3:" + JSON.stringify(submittedTransactionFilter));

    axios
      .post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        setUsersByNationality(arr);
        console.log("CountUsersByNationality:" + JSON.stringify(arr));
      })
      .catch((err) => {
        console.error("There was an error in getCountUsersByNationality!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  const getSummary = () => {
    console.log("******()");

    //change api endpoin
    const url = `${Api.report}/TransactionReport/transactionsSummary`;
    console.log("******()" + url);
    console.log("******(getSummary)" + JSON.stringify(submittedTransactionFilter));
    //if (!submittedTransactionFilter.merchantId) {
    //  return;
    //}
    console.log("array&3:" + JSON.stringify(submittedTransactionFilter));

    axios
      .post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        console.log("No summary changed:" + JSON.stringify(arr));

        setSummary(arr);
        console.log("Summary:" + JSON.stringify(arr));
      })
      .catch((err) => {
        console.error("There was an error in getSummary!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };
  useEffect(() => {
    console.log("summary changed:" + JSON.stringify(summary));
  }, [summary]);
  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  const getTotalTransactions = async (submittedTransactionFilter) => {
    console.log("******()");

    // Change API endpoint
    const url = `${Api.report}/TransactionReport/getTotalTransactions`;
    console.log("******()" + url);
    console.log("******()" + JSON.stringify(submittedTransactionFilter));

    if (!submittedTransactionFilter.merchantId) {
      //return;
    }

    console.log("array&3:" + JSON.stringify(submittedTransactionFilter));

    try {
      const response = await axios.post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      });

      const arr = response.data;
      console.log("getTotalTransactions:" + submittedTransactionFilter.startDate + arr);
      return arr;
    } catch (err) {
      console.error("There was an error in getCountUsersByNationality!#", err);
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        console.error("401 error", err);
        navigate("/authentication/sign-in");
      }
    }
  };

  const updateLineDataValue = (value, index) => {
    console.error(
      "updatedLineData param:" +
        value +
        "," +
        index +
        "," +
        JSON.stringify(lineData.datasets[0].data)
    );

    if (index >= 0 && index < lineData.datasets[0].data.length) {
      const updatedData = [...lineData.datasets[0].data];
      updatedData[index] = value;

      const updatedLineData = {
        ...lineData,
        datasets: [
          {
            ...lineData.datasets[0],
            data: updatedData,
          },
        ],
      };
      console.error("updatedLineData:" + JSON.stringify(updatedLineData));

      setLineData(updatedLineData);
    } else {
      console.error("Index out of range");
    }
  };

  const updateDataArray = (newDataArray) => {
    if (Array.isArray(newDataArray) && newDataArray.length === lineData.datasets[0].data.length) {
      setLineData((prevData) => ({
        ...prevData,
        datasets: [
          {
            ...prevData.datasets[0],
            data: newDataArray,
          },
        ],
      }));
    } else {
      console.error("The provided array does not match the length of the existing data array");
    }
  };

  const getFourMonthTotalTransactions = async () => {
    /*const filter = {
      merchantId: merchantId,
      //startDate: getFirstDateOfCurrentMonth(),
      startDate: getFirstDayOfMonthInIrelandTime(),

      endDate: getLastDateOfCurrentMonth(),
      transactionType: "purchase",
    };
    console.log("filter:" + JSON.stringify(filter));

    const current = await getTotalTransactions(filter);
    console.log("^%setTotalThisMonthBefore^%^:" + (await getTotalTransactions(filter)));*/

    const filter1 = {
      merchantId: merchantId,
      startDate: getFirstDateOfLastMonth(),
      endDate: getLastDateOfLastMonth(),
      transactionType: "purchase",
    };
    console.log("filter1:" + JSON.stringify(filter1));

    const last1 = await getTotalTransactions(filter1);
    console.log("^%^setTotal1MonthBefore%^:" + (await getTotalTransactions(filter1)));

    const filter2 = {
      merchantId: merchantId,
      startDate: getFirstDateOfLast2Month(),
      endDate: getLastDateOfLast2Month(),
      transactionType: "purchase",
    };
    console.log("filter2:" + JSON.stringify(filter2));

    const last2 = await getTotalTransactions(filter2);

    const filter3 = {
      merchantId: merchantId,
      startDate: getFirstDateOfLast3Month(),
      endDate: getLastDateOfLast3Month(),
      transactionType: "purchase",
    };
    console.log("filter3:" + JSON.stringify(filter3));
    const last3 = await getTotalTransactions(filter3);

    const filter4 = {
      merchantId: merchantId,
      startDate: getFirstDateOfLast4Month(),
      endDate: getLastDateOfLast4Month(),
      transactionType: "purchase",
    };
    console.log("filter4:" + JSON.stringify(filter4));
    const last4 = await getTotalTransactions(filter4);

    const array = [last4, last3, last2, last1];
    updateDataArray(array);
  };

  useEffect(() => {
    if (merchantId) {
      getFourMonthTotalTransactions();
    }
  }, [merchantId]);
  useEffect(() => {
    getFourMonthTotalTransactions();
  }, []);
  return (
    <DashboardLayout>
      <MDBox
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <DashboardNavbar />
      </MDBox>
      {(roles.some((element) => element.toLowerCase() === "super") ||
        roles.some((element) => element.toLowerCase() === "pplmanager") ||
        roles.some((element) => element.toLowerCase() === "sponsoradmin")) && (
        <MDBox
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "space-evenly",
            //minHeight: "calc(100vh - 100px)",
            padding: "2rem 1rem 20rem 1rem ",
          }}
          py={2}
        >
          <Grid
            container
            spacing={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "space-evenly",
              alignItems: "left",
            }}
          >
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="person_add"
                  title="Total Members Engaged"
                  count={
                    summary ? extendedFun.formatIntAccountingStyle(summary.totalUniqueMembers) : 0
                  }
                  /*percentage={{
                color: "success",
                //amount: "+3%",
                //label: "than last month",
              }}*/
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="leaderboard"
                  title="Total Sales Transaction"
                  count={
                    summary ? extendedFun.formatIntAccountingStyle(summary.totalTransactions) : 0
                  }
                  /*percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}*/
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="point_of_sale"
                  title="Total Spend (AED)"
                  count={summary ? extendedFun.formatIntAccountingStyle(summary.totalSpend) : 0}
                  /*percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}*/
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              paddingTop: "1rem ",
            }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ height: "100%", width: "100%" }}>
                <MDBox padding="1rem">
                  <MDBox pt={1} pb={1} px={1}>
                    <MDTypography variant="h6" textTransform="capitalize">
                      Past 4 Month Member Transactions Count
                    </MDTypography>
                    <MDTypography component="div" variant="button" color="text" fontWeight="light">
                      {/* Top 4 nationality */}
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    style={{
                      display: "flex", // Enables flexbox
                      alignItems: "center", // Vertically center the content
                      justifyContent: "center", // Horizontally center the content
                      height: "360px",
                      width: "100%",
                      //maxHeight: "980px",
                    }}
                  >
                    <Bar options={optionsLine} data={lineData} />
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card sx={{ height: "100%", width: "100%" }}>
                <MDBox padding="1rem">
                  <MDBox pt={1} pb={1} px={1}>
                    <MDTypography variant="h6" textTransform="capitalize">
                      Members Nationality
                    </MDTypography>
                  </MDBox>
                  {pieData.labels.length > 0 && (
                    <MDBox
                      style={{
                        display: "flex", // Enables flexbox
                        alignItems: "center", // Vertically center the content
                        justifyContent: "center", // Horizontally center the content
                        height: "360px", // Set a consistent height
                        width: "100%",
                        //maxHeight: "600px",
                      }}
                    >
                      <Pie data={pieData} options={pieOptions} plugins={[ChartDataLabels]} />
                    </MDBox>
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          {/*<MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>*/}
        </MDBox>
      )}
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default generalUsersReport;

{
  /*<Grid item xs={12} md={6} lg={4}>
              <MDBox mb={4}>
                <PieChart
                  icon={{ color: "info", component: "leaderboard" }}
                  title="Pie Chart"
                  description="Analytics Insights"
                  chart={{
                    labels: ["Facebook", "Direct", "Organic", "Referral"],
                    datasets: {
                      label: "Projects",
                      backgroundColors: ["info", "primary", "dark", "secondary", "primary"],
                      data: [15, 20, 12, 60],
                    },
                  }}
                  options={options} // Make sure to pass this if your custom component supports it
                />
              </MDBox>
            </Grid>*/
}
