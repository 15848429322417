// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  h2 {
    margin-top: 0;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .success {
    background-color: #4caf50;
    color: white;
  }
  
  .error {
    background-color: #f44336;
    color: white;
  }

  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/layouts/promotionDrawCreate/components/New/New.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;;;IAGE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,UAAU;IACV,eAAe;IACf,eAAe;EACjB","sourcesContent":[".container {\n    max-width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  h2 {\n    margin-top: 0;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  label {\n    display: block;\n    font-weight: bold;\n  }\n  \n  input[type=\"text\"],\n  input[type=\"number\"],\n  textarea {\n    width: 100%;\n    padding: 5px;\n    border: 1px solid #ccc;\n    border-radius: 3px;\n  }\n  \n  button[type=\"submit\"] {\n    background-color: #4caf50;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .message {\n    margin-top: 20px;\n    padding: 10px;\n    border-radius: 5px;\n    text-align: center;\n  }\n  \n  .success {\n    background-color: #4caf50;\n    color: white;\n  }\n  \n  .error {\n    background-color: #f44336;\n    color: white;\n  }\n\n  .error-message {\n    color: red;\n    font-size: 14px;\n    margin-top: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
