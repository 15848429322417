// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Set to 100% of viewport height */

}
/* Style for the form */
form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 300px;
  padding: 10px;
}

label {
  margin: 5px 0 5px 0;
}

input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 48px;
  font-size: 20px;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #AB8E66;
  color: white;
  font-size: 16px;
  min-height: 48px;
  font-size: 20px;

}

@media (max-width: 480px) {
  form {
    padding: 5px;
  }

  input {
    padding: 5px;
  }

  button {
    padding: 5px;
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/layouts/resetPassword/style/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa,EAAE,mCAAmC;;AAEpD;AACA,uBAAuB;AACvB;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,eAAe;;AAEjB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;AACF","sourcesContent":[".center-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100vh; /* Set to 100% of viewport height */\n\n}\n/* Style for the form */\nform {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  min-width: 300px;\n  padding: 10px;\n}\n\nlabel {\n  margin: 5px 0 5px 0;\n}\n\ninput {\n  padding: 10px;\n  margin-bottom: 10px;\n  border-radius: 5px;\n  border: 1px solid #ccc;\n  min-height: 48px;\n  font-size: 20px;\n}\n\nbutton {\n  padding: 10px;\n  border: none;\n  border-radius: 5px;\n  background-color: #AB8E66;\n  color: white;\n  font-size: 16px;\n  min-height: 48px;\n  font-size: 20px;\n\n}\n\n@media (max-width: 480px) {\n  form {\n    padding: 5px;\n  }\n\n  input {\n    padding: 5px;\n  }\n\n  button {\n    padding: 5px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
