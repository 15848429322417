import axios from "axios";

// Function to get the latest token
const getCurrentToken = () => {
  // Replace with your method of retrieving the latest token
  // For example, from local storage, Redux store, Context API, etc.
  console.log("%%getCurrentToken%%%%");

  return localStorage.getItem("token");
};

const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      console.log("%%%%%%");

      const token = getCurrentToken();

      console.log("Current Token: ", token);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      // Handle the request error here
      return Promise.reject(error);
    }
  );
};
export default setupAxiosInterceptors;
