import React, { useState, useEffect } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import Api from "../../constants/Api";
import logoImage from "./../../assets/images/logos/pplLogo.png"; // Make sure to provide the correct path to the image

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import style from "./style/style.css";
import { token } from "stylis";
const ResetPassword = () => {
  const params = useParams();
  console.log("params:" + JSON.stringify(params));

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const changePassword = () => {
    if (newPassword == null || newPassword == "") {
      setMessage("Please fill passwords");
      return;
    }
    if (!validatePassword(newPassword)) {
      setMessage(
        "Password must be at least 8 characters long and contain one special character, one capital letter, and one number."
      );
      return;
    }
    const data = {
      password: newPassword,
      confirmPassword: confirmPassword,
      email: params.email,
      token: params.token,
    };
    console.log("data:" + JSON.stringify(data));
    const api = Api.IdentityServer1 + "/user/ResetPassword";
    console.log("api:" + api);
    console.log("newPassword:" + newPassword);
    // Check if newPassword and confirmPassword match

    if (newPassword != confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    // Call your API to change the password
    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        console.log("reponse:" + JSON.stringify(response));

        const a = response.json();
        console.log("reponse:" + JSON.stringify(a));
        return a;
      })
      .then((data) => {
        // Handle the API response
        console.log(JSON.stringify(data));
        if (data.message == "successful") {
          setMessage("Passwords reset successfully");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          if (data.message.includes("Invalid token")) {
            setMessage(data.message + " " + "Please reset password again");
            setNewPassword("");
            setConfirmPassword("");
          } else {
            setMessage(data.message);
            setNewPassword("");
            setConfirmPassword("");
          }
        }
        //setMessage(data.message);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  const validatePassword = (password) => {
    // Password must be at least 8 characters long
    if (password.length < 8) {
      return false;
    }

    // Password must contain at least one special character, one capital letter, and one number
    const specialCharacters = "!@#$%^&*()_+{}[]~-";
    const hasSpecialChar = [...password].some((char) => specialCharacters.includes(char));
    const hasCapitalLetter = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    return hasSpecialChar && hasCapitalLetter && hasNumber;
  };
  return (
    <PageLayout>
      <div
        className="center-container"
        style={{
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "55%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "80px",
            marginTop: "85px",
          }}
        >
          <img
            src={logoImage}
            style={{
              width: "100%", // Reduced width
              maxWidth: "200px", // Reduced maximum width
              height: "auto", // Maintains aspect ratio
              margin: "0 auto",
            }}
            alt="Logo"
          />
        </div>
        <h3 style={{ marginBottom: "16px", fontSize: "22px", fontWeight: "500" }}>
          Reset Your Password
        </h3>
        <form>
          <label
            htmlFor="newPassword"
            style={{ marginRight: "10px", fontSize: "18px", fontWeight: "400" }}
          >
            New Password:{" "}
          </label>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <input
              type={passwordVisible ? "text" : "password"}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ marginRight: "5px" }}
            />
            <FontAwesomeIcon
              icon={passwordVisible ? faEye : faEyeSlash}
              onClick={() => setPasswordVisible(!passwordVisible)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <label
            htmlFor="confirmPassword"
            style={{ marginRight: "10px", fontSize: "18px", fontWeight: "400" }}
          >
            Confirm Password:
          </label>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ marginRight: "5px" }}
            />
            <FontAwesomeIcon
              icon={confirmPasswordVisible ? faEye : faEyeSlash}
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <br />
          <button type="button" onClick={changePassword}>
            <p style={{ fontSize: "18px", fontWeight: "500" }}> Change Password </p>
          </button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "10px",
              maxWidth: "300px",
            }}
          >
            {!message.includes("successfully") && (
              <p style={{ fontSize: "16px", fontWeight: "400", color: "red" }}>{message}</p>
            )}
            {message.includes("successfully") && (
              <p style={{ fontSize: "16px", fontWeight: "500", color: "green" }}>{message}</p>
            )}
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default ResetPassword;
