// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* style.css */

.label-small {
  font-size: 12px;
  font-weight: 400;
}

/* styles.css or equivalent */
.datePickerInput {
  height: 40px !important; /* The use of !important might be necessary to override default styles, but try to avoid it if possible */
}

.datePickerInput .datePicker-innerInput {
  height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/memberdetails/styles.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA,6BAA6B;AAC7B;EACE,uBAAuB,EAAE,yGAAyG;AACpI;;AAEA;EACE,YAAY;AACd","sourcesContent":["/* style.css */\n\n.label-small {\n  font-size: 12px;\n  font-weight: 400;\n}\n\n/* styles.css or equivalent */\n.datePickerInput {\n  height: 40px !important; /* The use of !important might be necessary to override default styles, but try to avoid it if possible */\n}\n\n.datePickerInput .datePicker-innerInput {\n  height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
