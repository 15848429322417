/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components

import { useState } from "react";
import PplDrawsList from "./components/PplDrawsList";
import Winners from "./components/Winners";

function promotionDraws() {
  const [winnerArr, setWinnerArr] = useState([]);
  const [drawDetails, setDrawDetails] = useState("");
  const [numberOfWinners, setNumberOfWinners] = useState(0);
  const [prizes, setPrizes] = useState([]);
  const [couponId, setCouponId] = useState(0);
  const [viewWinnersFun, setViewWinnersFun] = useState(null);

  const winnerViewHandler = (v1, v2, v3, v4, v5) => {
    setWinnerArr(v1);
    setDrawDetails(v2);
    setNumberOfWinners(v3);
    setPrizes(v4);
    console.log("setCouponId:" + v5);
    setCouponId(v5);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <PplDrawsList onWinnerView={winnerViewHandler} />
            </Grid>
            <Grid item xs={12} md={5}>
              <Winners
                _winnerArr={winnerArr}
                _drawDetails={drawDetails}
                _numberOfWinners={numberOfWinners}
                _prizeList={prizes}
                _couponId={couponId}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default promotionDraws;
