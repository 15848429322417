import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import PropTypes from "prop-types";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import Api from "constants/Api";
import axios from "axios";
import MDAlert from "components/MDAlert";
import { FormControl, FormHelperText, Input, Select, MenuItem, Alert } from "@mui/material";
import { useAuth } from "../../../../contexts/authContext";

const initMalls = [
  { id: "", name: "Select Mall*" },
  { id: 1, name: "Mercato" },
  { id: 2, name: "Towncenter" },
];
const initForm = {
  title: "",
};

function New({ action }) {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  console.log("useAuth():" + token + "," + roles + "," + merchantId);
  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [image, setImage] = useState(null);

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initForm,
  });

  const validate = (data) => {
    if (data.title == "") {
      return false;
    }
    if (image == null && image == "") {
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    console.log("Create data:" + JSON.stringify(data));
    console.log("Create image:" + JSON.stringify(image));

    if (validate(data)) {
      const formData = new FormData();

      formData.append("image", image);
      formData.append("title", data.title);
      console.log("formData:" + JSON.stringify(formData));

      create(formData);
    } else {
      setMessage("Please, fill all fields");
      setAlertType("error");
      setTimeout(function () {
        setMessage(null);
      }, 5000);
    }
  };

  const create = (data) => {
    const url = `${Api.merchant}/Happening/create`;
    console.log("Create url:" + url);
    /*const config = {
      headers: {
        Authorization: "Bearer " + YOUR_TOKEN_HERE,
      },
    };*/
    // const url = `${Api.merchant}/Happening/create`

    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        console.log(response.data);
        setMessage("New happening create successfully");
        setAlertType("success");
        reset();
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error("error: " + JSON.stringify(error.response));
        //setMessage(JSON.stringify(error.response.data));
        //setAlertType("error");
        //setTimeout(function () {
        //  setMessage(null);
        //}, 5000);
        //alert(error.response.data);
      });
    /*axios
      .post(url, data)
      .then((response) => {
        console.log(response.data);
        setMessage("New happening Category create successfully");
        setAlertType("success");
        reset();
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });*/
  };

  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log("file:" + JSON.stringify(file));
    setValue("image", file);
  };
  return (
    <>
      <MDBox
        color="white"
        p={2}
        justifyContent="center"
        alignItems="center"
        mx={0}
        sx={{
          width: "90%",
          height: "100%",
          flex: 1,
        }}
      >
        <Card>
          <MDBox pt={0} pb={3} px={3}>
            <MDBox role="form">
              <form>
                {message && (
                  <MDBox>
                    <MDAlert color={alertType} dismissible>
                      {alertContent(message, alertType)}
                    </MDAlert>
                  </MDBox>
                )}
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={2}>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "title is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallId} style={{ minWidth: 120, marginTop: 16 }}>
                        <MDInput
                          required
                          label="title"
                          error={!!errors.title}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.title?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={4}>
                  <Controller
                    name="image"
                    control={control}
                    defaultValue=""
                    rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.image} style={{ minWidth: 120, marginTop: 16 }}>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setImage(e.target.files[0]);

                            const file = e.target.files[0];
                            field.onChange(file);
                            setValue("image", file);
                            console.log("file:" + JSON.stringify(file));
                            //console.log("file:" + JSON.stringify(file.name));
                            //console.log("file:" + JSON.stringify(e.target.files[0]));
                          }}
                        />
                        <FormHelperText>{errors.image?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

New.propTypes = {
  action: PropTypes.shape({
    //type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
  }),
};

export default New;
