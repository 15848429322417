/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images

import { useEffect, useState } from "react";

import * as extendedFun from "extensions/extendedFunctions";

function hideMiddleCharacters(str) {
  // Check if the string length is less than or equal to 6, in which case, there's nothing to hide
  if (str.length <= 6) {
    return str;
  }

  // Extract the first 3 characters
  let firstThree = str.slice(0, 3);

  // Extract the last 3 characters
  let lastThree = str.slice(-3);

  // Calculate the number of characters to hide
  let middleLength = str.length - 6;

  // Replace middle characters with "*"
  let hiddenMiddle = "*".repeat(middleLength);

  // Concatenate the first three characters, hidden middle, and last three characters
  let result = firstThree + hiddenMiddle + lastThree;

  return result;
}

export default function data(transactions) {
  console.log("transactions in auth table:" + JSON.stringify(transactions));
  const [happening, setHappening] = useState(transactions);
  //const [filter, setFilter] = useState(transactionFilter);

  console.log("table data transaction ");
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="small">
      <MDTypography display="block" variant="caption" color="black" fontWeight="small">
        {data}
      </MDTypography>
    </MDBox>
  );

  console.log("array++");

  /*const getTransaction = () => {
    const url = `${Api.report}/TransactionReport/TransactionSeparateVoidWithDemographReport`;
    console.log("url" + url);

    axios.post(url, transactionFilter).then((response) => {
      const arr = response.data;
      console.log("array:" + JSON.stringify(arr));
      setHappening(arr);
    });
  };

  useEffect(() => {
    console.log("getTransaction()");

    getTransaction();
  }, [transactionFilter]);*/

  useEffect(() => {
    console.log("getTransaction()");

    setHappening(transactions);
  }, [transactions]);
  return {
    columns: [
      //{ Header: "merchant", accessor: "merchant", align: "center" },

      { Header: "Date", accessor: "date", width: "15%", align: "center" },
      //{ Header: "Customer", accessor: "customerName", width: "20%", align: "left" },

      { Header: "PrivilegePLUS ID", accessor: "memberId", width: "20%", align: "center" },
      { Header: "Nationality", accessor: "nationality", width: "20%", align: "center" },

      { Header: "Age Group", accessor: "ageGroup", width: "20%", align: "center" },
      { Header: "Gender", accessor: "gender", width: "20%", align: "center" },

      { Header: "Receipt No.", accessor: "transactionNumber", width: "15%", align: "center" },

      { Header: "Transaction Amount", accessor: "transactionValue", width: "20%", align: "center" },
      { Header: "Points", accessor: "points", width: "15%", align: "right" },
      //{ Header: "Balance", accessor: "balance", width: "5%", align: "left" },
      { Header: "Transaction Type", accessor: "transactionType", width: "15%", align: "center" },
    ],
    rows: happening.map((item) => ({
      //merchant: <Info data={item.merchantName} />,
      date: <Info data={extendedFun.IsoDateToDDMMYY(item.dateOfTransaction)} />,
      //date: <Info data={item.dateOfTransaction} />,
      //customerName: <Info data={item.firstname + " " + item.lastname} />,
      memberId: <Info data={hideMiddleCharacters(item.memberCode)} />,
      nationality: <Info data={item.nationality} />,

      ageGroup: <Info data={item.ageGroup} />,
      gender: <Info data={item.gender} />,

      transactionNumber: <Info data={item.transactionNumber ? item.transactionNumber : ""} />,
      transactionValue: (
        <Info
          data={
            item.transactionValue !== undefined && item.transactionValue !== null
              ? "AED " + extendedFun.formatNumberAccountingStyle(item.transactionValue.toFixed(2))
              : ""
          }
        />
      ),
      points: <Info data={item.points !== undefined && item.points !== null ? item.points : ""} />,
      //balance: <Info data={item.pointbalance ? item.pointbalance : ""} />,

      transactionType: <Info data={item.transactionType ? item.transactionType : ""} />,
    })),
  };
}
