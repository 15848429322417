/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Api from "constants/Api";

import PplDraw from "../pplDraw";

import MDButton from "components/MDButton";

import New from "../New";

const initPplCoupons = { totalItems: 0, items: [] };
const list = "list";
const newEntry = "newEntry";
const editEntry = "editEntry";

function PromotionDrawsList({ onWinnerView }) {
  const [pplCoupons, setPplCoupons] = useState(initPplCoupons);

  const getCoupons = () => {
    const url = `${Api.loyalty}/Promotion/getAllForDash?StartRangeDate=01-01-2024&EndRangeDate=12-31-2024`;
    console.log("Call Promotion Coupons:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;

      //sorted by date
      arr.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

      setPplCoupons(arr);
    });
  };

  useEffect(() => {
    // getCurrentForMembership?TierId=1 //const url = `${Api.api}/Transaction/getAll?${params}`;
    // const url = `${Api.api}/PplCoupon/getCurrentForMembership?SortBy=startdate&IsSortAsc=false`;

    //filter by current year
    getCoupons();
  }, []);

  const [screenStatus, setScreenStatus] = useState(list);
  if (screenStatus == list) {
    return (
      <Card id="delete-account">
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            Promotion Draws List
          </MDTypography>
          <MDButton
            variant="gradient"
            color="info"
            onClick={() => {
              setScreenStatus(newEntry);
              //setList(false);
            }}
            size="small"
          >
            Create New
          </MDButton>
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
            {pplCoupons.items.map((act, index) => (
              <MDBox key={index}>
                <PplDraw
                  index={index}
                  name={act.name}
                  description={act.description}
                  prize={act.prizeDetails}
                  numberOfWinner={act.numberOfWinner}
                  drawDate={act.drawDate}
                  startDate={act.startDate}
                  endDate={act.endDate}
                  isActive={act.isActive}
                  isDisable={act.isDisable}
                  winners={act.winnerPpls}
                  onWinnerView={onWinnerView}
                  prizeList={act.prizes}
                  couponId={act.id}
                  valueInPoints={act.valueInPoints}
                  price={act.price}
                />
              </MDBox>
            ))}
          </MDBox>
        </MDBox>
      </Card>
    );
  }
  if (screenStatus == newEntry) {
    return (
      <Card id="delete-account">
        <MDBox pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium">
            PRIVILEGE PLUS Draws Create
          </MDTypography>
          <MDBox ml={4} mt={2} mb={1}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => {
                setScreenStatus(list);
                //setList(false);
              }}
              size="small"
            >
              {"< Back"}
            </MDButton>
            <New
              action={{ route: "/PromotionDrawsList", color: "primary", tooltip: "Edit Profile" }}
            />
          </MDBox>
        </MDBox>
      </Card>
    );
  }
}

// Setting default values for the props of Bill
PromotionDrawsList.defaultProps = {
  onWinnerView: () => {},
};
// Typechecking props for the Bill
PromotionDrawsList.propTypes = {
  onWinnerView: PropTypes.func,
};
export default PromotionDrawsList;
