/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Winner from "../Winner";
import SelectWinner from "../SelectWinner";
import { useEffect, useState } from "react";

import Api from "constants/Api";
import axios from "axios";

const initPplCoupon = { totalItems: 0, items: [] };

// the concept is ethir view the winner or select winners
function Winners({ _winnerArr, _drawDetails, _numberOfWinners, _prizeList, _couponId }) {
  const [pplCoupon, setPplCoupon] = useState(initPplCoupon);
  const [winnerArr, setWinnerArr] = useState([]);
  const [drawDetails, setDrawDetails] = useState("");
  const [numberOfWinners, setNumberOfWinners] = useState(0);
  const [prizeList, setPrizeList] = useState([]);
  const [couponId, setCouponId] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const updateRefresh = () => {
    setRefresh((previousState) => {
      return !previousState;
    });
  };

  useEffect(() => {
    getCouponDetails(_couponId);
  }, [refresh]);

  console.log("enter Winners" + _winnerArr);

  useEffect(() => {
    getCouponDetails(_couponId);
  }, [_couponId]);
  //get coupon
  const getCouponDetails = (id) => {
    if (!id || id <= 0) {
      return;
    }
    console.log("****** : +" + id);

    const url = `${Api.loyalty}/Promotion/getAllForDash?couponid=${id}`;

    axios
      .get(url)
      .then((response) => {
        const arr = response.data;
        console.log("getCouponDetails array: +" + JSON.stringify(arr));

        //sorted by date
        arr.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

        setPplCoupon(arr);
      })
      .catch((err) => {
        console.log("getCouponDetails error: +" + JSON.stringify(err));
      });
  };
  //(act.winnerPpls, act.description, act.numberOfWinner, act.prizes, act.id)

  useEffect(() => {
    console.log("effect," + JSON.stringify(pplCoupon));

    if (pplCoupon.items.length > 0) {
      console.log("get Winners," + JSON.stringify(pplCoupon));
      pplCoupon.items.map((act) => {
        setWinnerArr(act.winnerPpls);
        setDrawDetails(act.description);
        setNumberOfWinners(act.numberOfWinner);
        setPrizeList(act.prizes);
        setCouponId(act.id);
      });
    }
  }, [pplCoupon]);

  // const repeatedElements = Array(numberOfWinners).fill(
  //  <SelectWinner couponId={couponId} prize={drawDetails} orderWinner={1} />
  // );
  // numberofwinner ,numberofwinner

  const [arrayOfprizes, setArrayOfprizes] = useState([]);

  useEffect(() => {
    const arrayOfprizes_ = prizeList.map((arr) => arr.orderWinner);
    setArrayOfprizes(arrayOfprizes_);
  }, [prizeList]);

  const [arrayOfWinnerOrder, setArrayOfWinnerOrder] = useState([]);
  useEffect(() => {
    const arrayOfWinnerOrder_ = winnerArr.map((arr) => arr.orderwinner);
    setArrayOfWinnerOrder(arrayOfWinnerOrder_);
  }, [winnerArr]);

  const [pricesWithoutWinners, setPricesWithoutWinners_] = useState([]);

  useEffect(() => {
    const pricesWithoutWinners_ = arrayOfprizes.filter(
      (item) => arrayOfWinnerOrder.indexOf(item) === -1
    );
    setPricesWithoutWinners_(pricesWithoutWinners_);
  }, [arrayOfprizes]);

  console.log(`pricesWithoutWinners${JSON.stringify(pricesWithoutWinners)}`);

  console.log(`prizeList${JSON.stringify(prizeList)}`);
  console.log(JSON.stringify(arrayOfprizes));
  // console.log(JSON.stringify(prizeList.filter((item) => item.orderWinner === 1)[0].details));

  console.log(JSON.stringify(arrayOfWinnerOrder));

  // console.log(`Winner null: ${JSON.stringify(winnerArr[0].member.firstName)}`);
  if (prizeList.length !== 0) {
    // console.log(`Winner null: ${winnerArr}`);
    return (
      <Card sx={{ height: "100%" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Winners List
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2}>
          <MDBox mb={2}>
            <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              {drawDetails}
              <br />
              Number Of Draws : {numberOfWinners}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            {pricesWithoutWinners.map((p, index) => (
              <MDBox key={index}>
                <SelectWinner
                  key={index}
                  couponId={couponId}
                  prize={prizeList.filter((item) => item.orderWinner === p)[0].details}
                  orderWinner={p}
                  refresh={updateRefresh}
                />
              </MDBox>
            ))}
          </MDBox>

          {winnerArr != null && Array.isArray(winnerArr) && winnerArr.length !== 0 && (
            <MDBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={1}
              m={1}
              sx={{ listStyle: "none" }}
            >
              {winnerArr.map((winner, index) => (
                <Winner
                  key={index}
                  name={winner.member.fullName}
                  nationality={winner.member.nationality}
                  gender={winner.member.gender}
                  prizeDetails={winner.prize.details}
                  orderWinner={winner.orderwinner}
                  drawDate={winner.drawdate}
                  numberOfWinners={numberOfWinners}
                />
              ))}
            </MDBox>
          )}
        </MDBox>
      </Card>
    );
  }
  // console.log(`Winner full: ${JSON.stringify(winnerArr)}`);
  /*
  if (winnerArr.length < drawDetails.numberOfWinners) {
    // console.log(`Winner null: ${winnerArr}`);
    return (
      <Card sx={{ height: "100%" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Winners List -----
          </MDTypography>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Number Of Draws : {numberOfWinners}
          </MDTypography>
        </MDBox>
        <MDBox pt={3} pb={2} px={2}>
          <MDBox mb={2}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Mix
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    );
  }
*/
  //if there is no prize for the draw
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Winners List
        </MDTypography>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            {drawDetails}
          </MDTypography>
          <br />
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            Number Of Draws : {numberOfWinners}
          </MDTypography>
        </MDBox>

        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={1}
          m={1}
          sx={{ listStyle: "none" }}
        >
          {winnerArr.map((winner) => (
            <Winner
              name={winner.member.fullName}
              nationality={winner.member.nationality}
              gender={winner.member.gender}
              prizeDetails={winner.prize.details}
              orderWinner={winner.orderwinner}
              drawDate={winner.member.drawdate}
              numberOfWinners={numberOfWinners}
            />
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Bill
Winners.defaultProps = {
  _winnerArr: [],
  _prizeList: [],
};
// Typechecking props for the Bill
Winners.propTypes = {
  _winnerArr: PropTypes.instanceOf(Array),
  _drawDetails: PropTypes.string.isRequired,
  _numberOfWinners: PropTypes.number.isRequired,
  _prizeList: PropTypes.instanceOf(Array),
  _couponId: PropTypes.number.isRequired,
};

export default Winners;
