/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import team2 from "assets/images/team-2.jpg";

import { useEffect, useState } from "react";
import Api from "constants/Api";
import axios from "axios";

import * as extendedFun from "extensions/extendedFunctions";
import MDAvatar from "components/MDAvatar";
import { useAuth } from "../../../contexts/authContext";

export default function data(screenStatus, setOpen, setDeletedId, _refresh = false) {
  const [happening, setHappening] = useState({ items: [] });
  const [refresh, setRefresh] = useState(_refresh);

  useEffect(() => {
    setRefresh(_refresh);
  }, [_refresh]);

  console.log("data:" + screenStatus);
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {data}
      </MDTypography>
    </MDBox>
  );

  const InfoImage = ({ image, name }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDAvatar src={image} name={name} size="lg" />
    </MDBox>
  );

  console.log("array");

  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const getHappening = () => {
    const url = `${Api.merchant}/Happening/getAll`;
    console.log("(((url" + url);
    console.log("(((token" + token);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const arr = response.data;
        console.log("array:" + JSON.stringify(arr));
        setHappening(arr);
      })
      .catch((err) => {
        console.error("There was an error!#", err);
        if (err.response && err.response.status === 401) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    console.log("getHappening()");

    getHappening();
  }, [screenStatus]);

  useEffect(() => {
    console.log("getMerchant()");

    getHappening();
  }, [refresh]);

  return {
    columns: [
      { Header: "action", accessor: "action", align: "center" },
      { Header: "title", accessor: "title", width: "20%", align: "left" },
      { Header: "image", accessor: "image", align: "center" },
      { Header: "created By", accessor: "createdBy", align: "center" },
    ],
    rows: happening.items.map((item) => ({
      action: (
        <>
          <MDTypography
            component="a"
            href={`/happeningCategory/${item.id}`}
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -
          </MDTypography>
          <MDTypography
            component="a"
            href="#"
            onClick={() => {
              console.log("delete");
              setOpen(true);
              setDeletedId(item.id);
            }}
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            delete
          </MDTypography>
        </>
      ),
      title: <Info data={item.title ? item.title : ""} />,
      image: <InfoImage image={item.image} name={item.image ? item.details : ""} />,
      createdBy: <Info data={item.createdBy ? item.createdBy : ""} />,
    })),
  };
}
