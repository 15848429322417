/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Api from "constants/Api";
import PplDraw from "../pplDraw";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/authContext";

const initPplCoupons = { totalItems: 0, items: [] };

function PplDrawsList({ onWinnerView }) {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  const navigate = useNavigate();
  const [pplCoupons, setPplCoupons] = useState(initPplCoupons);

  const getCoupons = () => {
    console.log("token:###" + token);
    const h = {
      headers: {
        Authorization: `Bearer ${token}`, // Add the Authorization header with your token
      },
    };
    console.log("h:" + JSON.stringify(h));
    //const url = `${Api.loyalty}/PplCoupon/getall?StartRangeDate=01-01-2023&EndRangeDate=12-31-2023`;
    const url = `${Api.loyalty}/Promotion/getAllForDash?StartRangeDate=01-01-2024&EndRangeDate=12-31-2024`;
    console.log("url getCoupons:" + url);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        console.log("response.data", JSON.stringify(arr));

        //sorted by date
        arr.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

        setPplCoupons(arr);
      })
      .catch((err) => {
        console.error("There was an error!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    // getCurrentForMembership?TierId=1 //const url = `${Api.api}/Transaction/getAll?${params}`;
    // const url = `${Api.api}/PplCoupon/getCurrentForMembership?SortBy=startdate&IsSortAsc=false`;

    //filter by current year
    getCoupons();
  }, []);

  useEffect(() => {
    console.log("useEffect PPL Coupons");
  }, []);

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Promotion Draws List
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {pplCoupons.items.map((act, index) => (
            <MDBox key={index}>
              <PplDraw
                index={index}
                name={act.name}
                description={act.description}
                prize={act.prizeDetails}
                numberOfWinner={act.numberOfWinner}
                drawDate={act.drawDate}
                startDate={act.startDate}
                endDate={act.endDate}
                isActive={act.isActive}
                isDisable={act.isDisable}
                winners={act.winnerPpls}
                onWinnerView={onWinnerView}
                prizeList={act.prizes}
                couponId={act.id}
              />
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Bill
PplDrawsList.defaultProps = {
  onWinnerView: () => {},
};
// Typechecking props for the Bill
PplDrawsList.propTypes = {
  onWinnerView: PropTypes.func,
};
export default PplDrawsList;
