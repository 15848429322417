import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import MDInput from "components/MDInput";

const FormInputText = ({ name, control, label, initValue, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <MDInput
          name={name}
          onChange={onChange}
          value={value}
          type="text"
          label={label}
          variant="standard"
          px={2}
          {...props}
        />
      )}
    />
  );
};
export default FormInputText;
