import React, { useState, useEffect } from "react";
import Api from "../../../../constants/Api";
import axios from "axios";
import "./New.css";
import * as extendedFun from "extensions/extendedFunctions";

function Edit({ action, id }) {
  const [couponData, setCouponData] = useState({
    name: "",
    description: "",
    startDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
    endDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
    price: "",
    drawDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
    prizedetails: "",
    numberOfWinner: 0,
    isdisable: false,
    image: null,
    prizes: [], // Array to store prizes
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("(***couponData:" + JSON.stringify(couponData));

    const formErrors = {};
    if (!couponData.name) {
      formErrors.name = "Name is required";
    }

    if (!couponData.startDate) {
      formErrors.startDate = "Start Date is required";
    }
    if (!couponData.endDate) {
      formErrors.endDate = "End Date is required";
    }
    if (!couponData.price) {
      formErrors.price = "spending amount is required";
    }
    if (!couponData.drawDate) {
      formErrors.drawDate = "Draw Date is required";
    }
    /*if (!couponData.numberOfWinner) {
      formErrors.numberOfWinner = "Number of Winners is required";
    }*/
    if (couponData.prizes.length === 0) {
      formErrors.prizes = "At least one prize is required";
    }
    console.log("length:" + Object.keys(formErrors).length);

    if (Object.keys(formErrors).length === 0) {
      const formData = new FormData();

      formData.append("id", couponData.id);
      formData.append("name", couponData.name);
      formData.append("description", couponData.description);
      formData.append("startdate", couponData.drawDate ? couponData.startDate : null);
      formData.append("enddate", couponData.endDate ? couponData.endDate : null);
      formData.append("price", couponData.price);
      formData.append("drawdate", couponData.drawDate ? couponData.drawDate : null);
      formData.append("prizedetails", couponData.prizedetails ? couponData.prizedetails : "");
      formData.append("numberofwinner", couponData.numberOfWinner);
      //formData.append("isdisable", couponData.isdisable);
      formData.append("image", couponData.image);

      couponData.prizes.forEach((prize, index) => {
        formData.append(`prizes[${index}].orderWinner`, prize.orderWinner);
        formData.append(`prizes[${index}].details`, prize.details);
      });

      try {
        const url = `${Api.loyalty}/promotion/update`;
        try {
          console.log("formData:" + formData);
          for (let entry of formData.entries()) {
            console.log(entry);
          }
          const response = await axios.put(url, formData);
          setMessage("PPL Draw created successfully");
          setAlertType("success");
          reset();
          setTimeout(function () {
            setMessage(null);
          }, 5000);
        } catch (error) {
          console.error(error);
          setMessage(JSON.stringify(error.message));
          setAlertType("error");
          setTimeout(function () {
            setMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setErrors(formErrors);
      //setErrors(formErrors);
      setMessage("Please fill required fields" + JSON.stringify(formErrors));
      setAlertType("error");
      setTimeout(function () {
        setMessage("");
      }, 5000);
    }
  };

  const reset = () => {
    setCouponData({
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      price: "",
      drawDate: "",
      prizedetails: "",
      numberOfWinner: "",
      isdisable: false,
      image: null,
    });
  };

  const handlePrizeChange = (e, index) => {
    const { name, value } = e.target;
    setCouponData((prevState) => {
      const updatedPrizes = [...prevState.prizes];
      updatedPrizes[index][name] = value; // Update the specific prize property
      return {
        ...prevState,
        prizes: updatedPrizes,
      };
    });
  };

  const handleAddPrize = () => {
    setCouponData((prevState) => ({
      ...prevState,
      prizes: [...prevState.prizes, { orderWinner: "", details: "" }],
    }));
  };

  const handleRemovePrize = (index) => {
    setCouponData((prevState) => {
      const updatedPrizes = [...prevState.prizes];
      updatedPrizes.splice(index, 1);
      return {
        ...prevState,
        prizes: updatedPrizes,
      };
    });
  };

  const getDrawById = (id) => {
    const url = `${Api.loyalty}/promotion/get/${id}`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      let arr = response.data;
      console.log("coupon:" + JSON.stringify(arr));
      arr.startDate = extendedFun.IsoDateToYYYYMMDD(arr.startDate);
      arr.endDate = extendedFun.IsoDateToYYYYMMDD(arr.endDate);
      arr.drawDate = extendedFun.IsoDateToYYYYMMDD(arr.drawDate);

      setCouponData(arr);
    });
  };

  useEffect(() => {
    if (id) {
      getDrawById(id);
    }
  }, [id]);

  return (
    <div className="container">
      <h2>Edit Promotion Draw</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" value={couponData.name} onChange={handleChange} />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label>spending amount:</label>
          <input
            type="number"
            name="price"
            value={couponData.price}
            onChange={handleChange}
            min="1" // Minimum value for the input
            step="1" // Step value for increment/decrement buttons
          />
          {errors.price && <span className="error-message">{errors.price}</span>}
        </div>

        <div className="form-group">
          <label>Description:</label>
          <textarea
            rows={5}
            name="description"
            value={couponData.description}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label>number of winner:</label>
          <input
            type="number"
            name="price"
            value={couponData.numberOfWinner}
            onChange={handleChange}
            min="1" // Minimum value for the input
            step="1" // Step value for increment/decrement buttons
          />
          {errors.price && <span className="error-message">{errors.price}</span>}
        </div>
        <div className="form-group">
          <label>Prize Details:</label>
          <textarea
            rows={5}
            name="prizedetails"
            value={couponData.prizedetails}
            onChange={handleChange}
          />
        </div>
        {/* Add more input fields for other properties */}
        <div className="form-group">
          <label>Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={couponData.startDate}
            onChange={handleChange}
          />
          {errors.startDate && <span className="error-message">{errors.startDate}</span>}
        </div>
        <div className="form-group">
          <label>End Date:</label>
          <input type="date" name="endDate" value={couponData.endDate} onChange={handleChange} />
          {errors.endDate && <span className="error-message">{errors.endDate}</span>}
        </div>
        <div className="form-group">
          <label>Draw Date:</label>
          <input type="date" name="drawDate" value={couponData.drawDate} onChange={handleChange} />
          {errors.drawDate && <span className="error-message">{errors.drawDate}</span>}
        </div>

        {/* Form fields for prizes */}
        <div>
          <h3>Prizes</h3>
          {couponData &&
            couponData.prizes &&
            couponData.prizes.map((prize, index) => (
              <div key={index}>
                <label>Order Winner:</label>
                <input
                  type="number"
                  name="orderWinner"
                  value={prize.orderWinner}
                  onChange={(e) => handlePrizeChange(e, index)}
                  min="1" // Minimum value for the input
                  step="1" // Step value for increment/decrement buttons
                />
                <label>Details:</label>
                <input
                  type="text"
                  name="details"
                  value={prize.details}
                  onChange={(e) => handlePrizeChange(e, index)}
                />
                <button type="button" onClick={() => handleRemovePrize(index)}>
                  Remove Prize
                </button>
              </div>
            ))}
          {errors.prizes && <span className="error-message">{errors.prizes}</span>}

          <button type="button" onClick={handleAddPrize}>
            Add Prize
          </button>
        </div>
        <button type="submit">Save</button>
      </form>
      {message && <div className={`message ${alertType}`}>{message}</div>}
    </div>
  );
}

export default Edit;
