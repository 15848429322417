import PageLayout from "examples/LayoutContainers/PageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import logoImage from "./../../assets/images/logos/pplLogo.png"; // Make sure to provide the correct path to the image

const ConfirmEmailFailed = () => {
  const params = useParams();
  const name = params.error;
  const message = params.message;

  return (
    <PageLayout>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <div style={{ height: "25px" }}></div>
        <img
          src={logoImage}
          width="30%"
          height="30%"
          style={{
            margin: "0",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          alt="Logo"
        />

        <div
          style={{
            margin: "0 auto",
            //width: "70%",
            marginTop: "4%",
            marginBottom: "2%",
            paddingRight: "8px",
            paddingLeft: "8px",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{ width: "25%" }}>
            <u>Shop</u>
          </div>
          <div style={{ width: "25%" }}>
            <u>Dine</u>
          </div>
          <div style={{ width: "25%" }}>
            <u>Beauty & Services</u>
          </div>
          <div style={{ width: "25%", textAlign: "right" }}>
            <u>Unwind</u>
          </div>
        </div>
        <div style={{ height: "20px" }}></div>

        <div
          style={{
            width: "80%",
            margin: "0 auto",
            paddingRight: "8px",
            paddingLeft: "8px",
            textAlign: "center",
            color: "#000",
            fontFamily: "arial",
            fontSize: "14px",
            display: "block",
          }}
        >
          <h2>Error while confirm email, {name}</h2>
          <h3>{message} , contact Customer Service Please </h3>
          <br />
        </div>
        <div style={{ height: "10px" }}></div>
      </div>
    </PageLayout>
  );
};

export default ConfirmEmailFailed;
