import React, { useState, useEffect } from "react";
import Api from "../../../../constants/Api";
import axios from "axios";
import "./New.css";

const coupontypeOld = [
  { id: 1, name: "a" },
  { id: 2, name: "b" },
];

const New = () => {
  const [couponType, setCouponType] = useState([]);
  const [couponData, setCouponData] = useState({
    name: "",
    description: "",
    startdate: "",
    enddate: "",
    price: "",
    drawdate: "",
    prizedetails: "",
    numberofwinner: 0,
    isdisable: false,
    image: null,
    prizes: [], // Array to store prizes
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCouponData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("(***couponData:" + JSON.stringify(couponData));

    const formErrors = {};
    if (!couponData.name) {
      formErrors.name = "Name is required";
    }

    if (!couponData.startdate) {
      formErrors.startdate = "Start Date is required";
    }
    if (!couponData.enddate) {
      formErrors.enddate = "End Date is required";
    }
    if (!couponData.price) {
      formErrors.price = "Spending amount is required";
    }
    if (!couponData.drawdate) {
      formErrors.drawdate = "Draw Date is required";
    }
    /*if (!couponData.numberofwinner) {
      formErrors.numberofwinner = "Number of Winners is required";
    }*/
    if (couponData.prizes.length === 0) {
      formErrors.prizes = "At least one prize is required";
    }

    if (Object.keys(formErrors).length === 0) {
      const formData = new FormData();
      formData.append("name", couponData.name);
      //formData.append("coupontypeid", Number(couponData.coupontypeid));
      formData.append("description", couponData.description);
      formData.append("startdate", couponData.startdate);
      formData.append("enddate", couponData.enddate);
      formData.append("price", couponData.price);
      formData.append("drawdate", couponData.drawdate);
      formData.append("prizedetails", couponData.prizedetails);
      formData.append("numberofwinner", couponData.numberofwinner);
      formData.append("isdisable", couponData.isdisable);
      formData.append("image", couponData.image);

      couponData.prizes.forEach((prize, index) => {
        formData.append(`prizes[${index}].orderWinner`, prize.orderWinner);
        formData.append(`prizes[${index}].details`, prize.details);
      });

      try {
        const url = `${Api.loyalty}/Promotion/create`;
        try {
          const response = await axios.post(url, formData);
          setMessage("PPL Draw created successfully");
          setAlertType("success");
          reset();
          setTimeout(function () {
            setMessage(null);
          }, 5000);
        } catch (error) {
          console.error(error);
          setMessage(JSON.stringify(error.message));
          setAlertType("error");
          setTimeout(function () {
            setMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      setErrors(formErrors);
      //setErrors(formErrors);
      setMessage("Please fill required fields" + JSON.stringify(formErrors));
      setAlertType("error");
      setTimeout(function () {
        setMessage("");
      }, 5000);
    }
  };

  const reset = () => {
    setCouponData({
      name: "",
      //coupontypeid: 0,
      description: "",
      startdate: "",
      enddate: "",
      price: "",
      drawdate: "",
      prizedetails: "",
      numberofwinner: "",
      isdisable: false,
      image: null,
    });
  };

  const handlePrizeChange = (e, index) => {
    const { name, value } = e.target;
    setCouponData((prevState) => {
      const updatedPrizes = [...prevState.prizes];
      updatedPrizes[index][name] = value; // Update the specific prize property
      return {
        ...prevState,
        prizes: updatedPrizes,
      };
    });
  };

  const handleAddPrize = () => {
    setCouponData((prevState) => ({
      ...prevState,
      prizes: [...prevState.prizes, { orderWinner: "", details: "" }],
    }));
  };

  const handleRemovePrize = (index) => {
    setCouponData((prevState) => {
      const updatedPrizes = [...prevState.prizes];
      updatedPrizes.splice(index, 1);
      return {
        ...prevState,
        prizes: updatedPrizes,
      };
    });
  };

  const getCouponType = () => {
    const url = `${Api.loyalty}/CouponType/getAll`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      console.log("getCouponType ***:" + JSON.stringify(arr));

      setCouponType(arr);
    });
  };

  useEffect(() => {
    getCouponType();
  }, []);

  return (
    <div className="container">
      <h2>Create a New Promotion Draw</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" name="name" value={couponData.name} onChange={handleChange} />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className="form-group">
          <label>Spending amount:</label>
          <input
            type="number"
            name="price"
            value={couponData.price}
            onChange={handleChange}
            min="1" // Minimum value for the input
            step="1" // Step value for increment/decrement buttons
          />
          {errors.price && <span className="error-message">{errors.price}</span>}
        </div>

        <div className="form-group">
          <label>Description:</label>
          <textarea
            rows={5}
            name="description"
            value={couponData.description}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Prize Details:</label>
          <textarea
            rows={5}
            name="prizedetails"
            value={couponData.prizedetails}
            onChange={handleChange}
          />
        </div>
        {/* Add more input fields for other properties */}
        <div className="form-group">
          <label>Start Date:</label>
          <input
            type="date"
            name="startdate"
            value={couponData.startdate}
            onChange={handleChange}
          />
          {errors.startdate && <span className="error-message">{errors.startdate}</span>}
        </div>
        <div className="form-group">
          <label>End Date:</label>
          <input type="date" name="enddate" value={couponData.enddate} onChange={handleChange} />
          {errors.enddate && <span className="error-message">{errors.enddate}</span>}
        </div>
        <div className="form-group">
          <label>Draw Date:</label>
          <input type="date" name="drawdate" value={couponData.drawdate} onChange={handleChange} />
          {errors.drawdate && <span className="error-message">{errors.drawdate}</span>}
        </div>
        {/* Add input fields for other properties 
        <div className="form-group">
          <label>Image:</label>
          <input type="file" name="image" accept="image/*" onChange={handleImageChange} />
        </div>*/}

        {/* Form fields for prizes */}
        <div>
          <h3>Prizes</h3>
          {couponData &&
            couponData.prizes &&
            couponData.prizes.map((prize, index) => (
              <div key={index}>
                <label>Order Winner:</label>
                <input
                  type="number"
                  name="orderWinner"
                  value={prize.orderWinner}
                  onChange={(e) => handlePrizeChange(e, index)}
                  min="1" // Minimum value for the input
                  step="1" // Step value for increment/decrement buttons
                />
                <label>Details:</label>
                <input
                  type="text"
                  name="details"
                  value={prize.details}
                  onChange={(e) => handlePrizeChange(e, index)}
                />
                <button type="button" onClick={() => handleRemovePrize(index)}>
                  Remove Prize
                </button>
              </div>
            ))}
          {errors.prizes && <span className="error-message">{errors.prizes}</span>}

          <button type="button" onClick={handleAddPrize}>
            Add Prize
          </button>
        </div>
        <button type="submit">Create</button>
      </form>
      {message && <div className={`message ${alertType}`}>{message}</div>}
    </div>
  );
};

export default New;
