// authContext.js

import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize isAuthenticated from localStorage, if available
    return localStorage.getItem("isAuthenticated") === "true";
  });
  const [roles, setRoles] = useState(() => {
    // Initialize roles from localStorage, if available
    const rolesStr = localStorage.getItem("roles");
    return rolesStr ? JSON.parse(rolesStr) : [];
  });
  const [merchantId, setMerchantId] = useState(() => {
    // Initialize merchantId from localStorage, if available
    return localStorage.getItem("merchantId");
  });
  const [token, setToken] = useState(() => {
    // Initialize token from localStorage, if available
    return localStorage.getItem("token");
  });

  // Update localStorage whenever these values change
  useEffect(() => {
    localStorage.setItem("isAuthenticated", isAuthenticated);
    localStorage.setItem("roles", JSON.stringify(roles));
    localStorage.setItem("merchantId", merchantId);
    localStorage.setItem("token", token);
  }, [isAuthenticated, roles, merchantId, token]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        roles,
        setRoles,
        merchantId,
        setMerchantId,
        token,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
