/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import PropTypes from "prop-types";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { axiosMerchantForm } from "./../../../../axiosInstance"; // Adjust the path as per your file structure

// Images

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";
import MSSelect from "components/MSSelect";

import { Controller, useForm } from "react-hook-form";
import FormInputText from "form-components/FormInputText";

import * as extendedFun from "extensions/extendedFunctions";
import Api from "constants/Api";
import axios from "axios";
import MDAlert from "components/MDAlert";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"
const initMalls = [
  { id: "", name: "Select Mall*" },
  { id: 1, name: "Mercato" },
  { id: 2, name: "Towncenter" },
];

const initFloors = [
  { id: "", name: "Select Floor*" },
  { id: "Basement", name: "Basement" },
  { id: "Ground Floor", name: "Ground Floor" },
  { id: "First Floor", name: "First Floor" },
];

const initForm = {
  happeningId: null,
  mallId: "",
  title: null,
  subTitle: null,
  startDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
  endDate: null,
  showingDate: null,
  endShowingDate: null,
  details: null,
  image: null,
};

function New({ action }) {
  const [currentDate, setCurrentDate] = useState(extendedFun.IsoDateToYYYYMMDD(new Date()));
  const [malls, setMall] = useState(initMalls);

  const [floors, setFloors] = useState(initFloors);

  const [activity, setActivity] = useState(null);
  const [category, setCategory] = useState(null);

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [image, setImage] = useState(null);

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: "",
      mallid: "",
      categoryid: "",
      activityid: "",
      merchantstatusid: "",
      name: "",
      shopno: "",
      floor: "",
      contacttelephone: "",
      logoimage: "",
      shopimage: "",
      joindatetomall: "",
      refunddays: "",
      exchangedays: "",
      pplCeilPurchase: "",
      ppldiscountpercentage: "",
      ppldiscountpercentage2: "",
      pplDiscountTerms: "",
      details: "",
    },
  });
  const [logoimage, setLogoimage] = useState(null);

  const [shopimage, setShopimage] = useState(null);

  const onSubmit = (data) => {
    console.log("data:" + JSON.stringify(data));
    if (!validate(data)) {
      return false;
    }

    const formData = new FormData();

    formData.append("mallid", data.mallid);
    formData.append("categoryid", data.categoryid);
    formData.append("activityid", data.activityid);
    formData.append("merchantstatusid", data.merchantstatusid);
    formData.append("name", data.name);
    formData.append("shopno", data.shopno);
    formData.append("floor", data.floor);
    formData.append("contacttelephone", data.contacttelephone);
    formData.append("logoimage", logoimage);
    formData.append("shopimage", shopimage);

    formData.append("joindatetomall", data.joindatetomall);
    formData.append("refunddays", data.refunddays);
    formData.append("exchangedays", data.exchangedays);

    formData.append("pplCeilPurchase", data.pplCeilPurchase);
    formData.append("ppldiscountpercentage", data.ppldiscountpercentage);
    formData.append("ppldiscountpercentage2", data.ppldiscountpercentage2);

    formData.append("pplDiscountTerms", data.pplDiscountTerms);
    formData.append("details", data.details);
    console.log("formData:" + JSON.stringify(formData));
    //formData.append("title", data.title);
    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }
    create(formData);
  };

  const validate = (data) => {
    if (data.mallId == 0) {
      return false;
    }
    return true;
  };
  const create = (data) => {
    console.log("$$$$$$create new merchant :");

    const url = `${Api.merchant}/merchant/create`;
    console.log("url create merchant:" + url);
    console.log("url create merchant:" + JSON.stringify(data));
    /*const config = {
      headers: {
        Authorization: "Bearer " + YOUR_TOKEN_HERE,
      },
    };*/

    axiosMerchantForm
      .post(url, data)
      .then((response) => {
        console.log(response.data);
        setMessage("Merchant created successfully");
        setAlertType("success");
        reset();
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error.response.data);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });
  };

  useEffect(() => {
    getCategory();
    getActivity();
  }, []);

  const getCategory = () => {
    const url = `${Api.merchant}/Category/getAll`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;

      const myObject = { id: "", name: "Select Category*" };

      arr.unshift(myObject);
      setCategory(arr);
    });
  };
  const getActivity = () => {
    const url = `${Api.merchant}/activity/getAll`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      const myObject = { id: "", name: "Select Activities*" };

      arr.unshift(myObject);
      setActivity(arr);
    });
  };
  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );
  return (
    <>
      <MDBox
        color="white"
        p={2}
        justifyContent="center"
        alignItems="center"
        mx={0}
        sx={{
          width: "90%",
          height: "100%",
          flex: 1,
        }}
      >
        <Card>
          <MDBox ml={2} mt={0} mb={0}>
            <MDButton component={Link} to={action.route} variant="text" color="info">
              back
            </MDButton>
          </MDBox>
          <MDBox pt={0} pb={3} px={3}>
            <MDBox role="form">
              <form>
                {message && (
                  <MDBox>
                    <MDAlert color={alertType} dismissible>
                      {alertContent(message, alertType)}
                    </MDAlert>
                  </MDBox>
                )}
                {/*
                    mallid: "",
                    categoryid: "",
                    activityid: "",
                    merchantstatusid: "",
                */}
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name={"mallid"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Mall is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallid} style={{ minWidth: 120, marginTop: 16 }}>
                        <Select required displayEmpty {...field}>
                          {malls.map((element, index) => {
                            return (
                              <MenuItem m={20} key={index} value={element.id}>
                                {element.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors.mallid?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name={"categoryid"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Category is required" }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.categoryid}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <Select required displayEmpty {...field}>
                          {category &&
                            category.map((element, index) => {
                              return (
                                <MenuItem m={20} key={index} value={element.id}>
                                  {element.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>{errors.categoryid?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name={"activityid"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Activity is required" }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.activityid}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <Select required displayEmpty {...field}>
                          {activity &&
                            activity.map((element, index) => {
                              return (
                                <MenuItem m={20} key={index} value={element.id}>
                                  {element.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>{errors.activityid?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name={"floor"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "floor is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.floor} style={{ minWidth: 120, marginTop: 16 }}>
                        <Select required displayEmpty {...field}>
                          {floors &&
                            floors.map((element, index) => {
                              return (
                                <MenuItem m={20} key={index} value={element.id}>
                                  {element.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>{errors.floor?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "name is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl error={!!errors.name} style={{ minWidth: "50%", marginTop: 16 }}>
                        <MDInput
                          required
                          fullWidth
                          label="name"
                          error={!!errors.name}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.name?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="shopno"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "shopno is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.shopno}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="shopno"
                          error={!!errors.shopno}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.shopno?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={4}>
                  <InputLabel sx={{ mr: 2 }}>logo: </InputLabel>

                  <Controller
                    id="logo-image-upload"
                    name="logoimage"
                    control={control}
                    defaultValue=""
                    //rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.shopimage}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <Input
                          type="file"
                          accept="image/*"
                          label="hah"
                          onChange={(e) => {
                            setLogoimage(e.target.files[0]);

                            const file = e.target.files[0];
                            field.onChange(file);
                            setValue("logoimage", file);

                            console.log("file:" + JSON.stringify(file));
                          }}
                        />
                        <FormHelperText>{errors.logoimage?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={4}>
                  <InputLabel sx={{ mr: 2 }}>main image: </InputLabel>

                  <Controller
                    id="main-image-upload"
                    name="shopimage"
                    control={control}
                    defaultValue=""
                    //rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.shopimage}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setShopimage(e.target.files[0]);

                            const file = e.target.files[0];
                            field.onChange(file);
                            //setValue("image", file);

                            setValue("shopimage", file);
                            console.log("file:" + JSON.stringify(file));
                            //console.log("file:" + JSON.stringify(file.name));
                            //console.log("file:" + JSON.stringify(e.target.files[0]));
                          }}
                        />
                        <FormHelperText>{errors.logoimage?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="joindatetomall"
                    control={control}
                    defaultValue={"2002-1-1"}
                    rules={{
                      required: "join date to mall is required",
                      //validate: (value) => Date(value) > Date(currentDate) || "in future",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.joindatetomall}
                        style={{ minWidth: 120, marginTop: 16 }}
                        sx={{ mx: 20 }}
                      >
                        <MDInput
                          required
                          label="join date to mall Date"
                          error={!!errors.joindatetomall}
                          type="date"
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.joindatetomall?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="refunddays"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "refund days is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "only numbers are allowed",
                      },
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.refunddays}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="refund days"
                          error={!!errors.refunddays}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.refunddays?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="exchangedays"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "exchange days is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "only numbers are allowed",
                      },
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.exchangedays}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="exchange days"
                          error={!!errors.exchangedays}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.exchangedays?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="pplCeilPurchase"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "ppl Ceil Purchase days is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "only numbers are allowed",
                      },
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.exchangedays}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="ppl Ceil Purchase days"
                          error={!!errors.pplCeilPurchase}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.pplCeilPurchase?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="ppldiscountpercentage"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "ppl discount percentage is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "only numbers are allowed",
                      },
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.ppldiscountpercentage}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="ppl discount percentage"
                          error={!!errors.ppldiscountpercentage}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.ppldiscountpercentage?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="ppldiscountpercentage2"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "ppl discount percentage 2 is required",
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "only numbers are allowed",
                      },
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.ppldiscountpercentage}
                        style={{ minWidth: "50%", marginTop: 16 }}
                      >
                        <MDInput
                          required
                          fullWidth
                          label="ppl discount percentage 2"
                          error={!!errors.ppldiscountpercentage2}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.ppldiscountpercentage2?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="pplDiscountTerms"
                    control={control}
                    defaultValue="wwww"
                    render={({ field }) => (
                      <FormControl error={!!errors.pplDiscountTerms} fullWidth>
                        <MDInput
                          label="ppl Discount Terms"
                          error={!!errors.pplDiscountTerms}
                          //helperText={errors.details?.message}
                          {...field}
                          multiline
                          rows="3"
                          fullWidth
                        />
                        <FormHelperText>{errors.pplDiscountTerms?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="details"
                    control={control}
                    defaultValue="wwww"
                    rules={{ required: "details is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.details} fullWidth>
                        <MDInput
                          required
                          label="details"
                          error={!!errors.details}
                          //helperText={errors.details?.message}
                          {...field}
                          multiline
                          rows="3"
                          fullWidth
                        />
                        <FormHelperText>{errors.details?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

New.propTypes = {
  action: PropTypes.shape({
    //type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
  }),
};

export default New;
