/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
//import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import VerifiedIcon from "@mui/icons-material/Verified";
import SummarizeIcon from "@mui/icons-material/Summarize";
import GroupsIcon from "@mui/icons-material/Groups";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

import PplDraws from "layouts/pplDraws";
import PplDrawCreate from "layouts/pplDrawCreate";
import PromotionDrawCreate from "layouts/promotionDrawCreate";
import PromotionDraws from "layouts/promotionDraws";

import Happening from "layouts/Happening";
import HappeningCategory from "layouts/HappeningCategory";
import Test from "layouts/test";
import Merchant from "layouts/Merchant";
import ResetPassword from "layouts/resetPassword";
import WelcomeToApp from "layouts/welcomeToApp";
import ConfirmEmailFailed from "layouts/confirmEmailFailed";
import PrivacyPolicy from "layouts/privacyPolicy";
import DeleteAccount from "layouts/deleteAccount";
import TransactionReport from "layouts/TransactionReport";
import TransactionReportV1 from "layouts/TransactionReportV1";
import TransactionsMerchantReport from "layouts/TransactionsMerchantReport";
import demographicReport from "layouts/demographicReport";
import memberdetails from "layouts/memberdetails";
import generalUsersReport from "layouts/generalUsersReport";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "Home2",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home2",
    component: <Dashboard />,
    requireAuth: true,
    componentAuth: Dashboard, // No angle brackets
    roles: ["Administrator", "DED", "MerchantAdmin", "CustomerService", "CustomerServiceAdmin"],
  },
  {
    type: "collapse",
    name: "Home",
    key: "generalUsersReport",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home/",
    component: <generalUsersReport />,
    requireAuth: true,
    componentAuth: generalUsersReport, // No angle brackets
    roles: ["Super", "PplManager", "SponsorAdmin"],
    //, "SponsorAdmin"
  },
  {
    type: "x",
    name: "Welcome To PrivilegePLUS",
    key: "welcomeToApp",
    route: "/welcomeToApp/:name/:code",
    component: <WelcomeToApp />,
    requireAuth: false,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "privacyPolicy",
    route: "/privacyPolicy",
    component: <PrivacyPolicy />,
    requireAuth: false,
  },
  {
    type: "collapse",
    name: "Delete Account",
    key: "deleteAccount",
    route: "/deleteAccount",
    component: <DeleteAccount />,
    requireAuth: false,
  },
  {
    type: "x",
    name: "Confirm email Failed",
    key: "confirmEmailFailed",
    route: "/confirmEmailFailed/:error/:message",
    component: <ConfirmEmailFailed />,
    requireAuth: false,
  },
  {
    type: "x",
    name: "Reset Password",
    key: "resetPassword",
    route: "/resetPassword/:token/:email",
    component: <ResetPassword />,
    requireAuth: false,
  },
  {
    type: "collapse",
    name: "PrivilegePLUS DRAWS List",
    key: "pplDrawCreate",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/pplDrawCreate",
    component: <PplDrawCreate />,
    requireAuth: true,
    componentAuth: PplDrawCreate, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "",
    name: "Edit PrivilegePLUS Draw",
    key: "EditPrivilegePLUSDrawCreate",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/pplDrawCreate/:id",
    component: <PplDrawCreate />,
    requireAuth: true,
    componentAuth: PplDrawCreate, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "collapse",
    name: "Select Winner PrivilegePLUS ",
    key: "pplDraw",
    icon: <VerifiedIcon fontSize="small"></VerifiedIcon>,
    route: "/pplDraw",
    component: <PplDraws />,
    requireAuth: true,
    componentAuth: PplDraws, // No angle brackets
    roles: ["DED", "Super"],
  },
  {
    type: "collapse",
    name: "Promotion Draws List",
    key: "PromotionDrawCreate",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/PromotionDrawCreate",
    component: <PromotionDrawCreate />,
    requireAuth: true,
    componentAuth: PromotionDrawCreate, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "",
    name: "Edit Promotion Draw",
    key: "EditPromotionDrawCreate",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/PromotionDrawCreate/:id",
    component: <PromotionDrawCreate />,
    requireAuth: true,
    componentAuth: PromotionDrawCreate, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "collapse",
    name: "Select Winner Promotion",
    key: "PromotionDraws",
    icon: <VerifiedIcon fontSize="small"></VerifiedIcon>,
    route: "/PromotionDraws",
    component: <PromotionDraws />,
    requireAuth: true,
    componentAuth: PromotionDraws, // No angle brackets
    roles: ["DED", "Super"],
  },

  {
    type: "collapse",
    name: "MERCHANT",
    key: "merchant",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/merchant/",
    component: <Merchant />,
    requireAuth: true,
    componentAuth: Merchant, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "",
    name: "MERCHANT",
    key: "merchant",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/merchant/:id",
    component: <Merchant />,

    requireAuth: true,
    componentAuth: Merchant, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "collapse",
    name: "TRANSACTIONREPORT",
    key: "TransactionReport",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/TransactionReport/",
    component: <TransactionReport />,

    requireAuth: true, // This route is now protected
    componentAuth: TransactionReport, // No angle brackets
    roles: ["Administrator", "Super"],
  },

  {
    type: "collapse",
    name: "Member Sales Report",
    key: "TransactionReportV1",
    icon: <AutoStoriesIcon fontSize="small"></AutoStoriesIcon>,
    route: "/TransactionReportV1/",
    component: <TransactionReportV1 />,

    requireAuth: true, // This route is now protected
    componentAuth: TransactionReportV1, // No angle brackets
    roles: ["Administrator", "Super", "CustomerServiceAdmin", "PplManager"],
  },
  {
    type: "collapse",
    name: "Transaction Report",
    key: "TransactionsMerchantReport",
    icon: <AutoStoriesIcon fontSize="medium"></AutoStoriesIcon>,
    route: "/TransactionsMerchantReport/",
    requireAuth: true, // This route is now protected
    component: <TransactionsMerchantReport />,

    requireAuth: true, // This route is now protected
    componentAuth: TransactionsMerchantReport, // No angle brackets
    roles: ["MerchantAdmin", "Super"],
  },
  {
    type: "collapse",
    name: "Demographic Report",
    key: "demographicReport",
    icon: <GroupsIcon fontSize="medium"></GroupsIcon>,
    route: "/demographicReport/",
    requireAuth: true, // This route is now protected
    component: <demographicReport />,
    requireAuth: true, // This route is now protected
    componentAuth: demographicReport, // No angle brackets
    roles: ["SponsorAdmin", "Super"],
  },
  {
    type: "collapse",
    name: "Member Details",
    key: "memberdetails",
    icon: <GroupsIcon fontSize="medium"></GroupsIcon>,
    route: "/memberdetails/",
    requireAuth: true, // This route is now protected
    component: <memberdetails />,
    requireAuth: true, // This route is now protected
    componentAuth: memberdetails, // No angle brackets
    roles: ["PplManager", "Administrator", "Super"],
  },
  {
    type: "collapse",
    name: "HAPPENING",
    key: "happening",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/happening/",
    component: <Happening />,
    requireAuth: true,
    componentAuth: Happening, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "",
    name: "HAPPENING",
    key: "happening",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/happening/:id",
    component: <Happening />,

    requireAuth: true,
    componentAuth: Happening, // No angle brackets
    roles: ["Administrator", "Super"],
  },
  {
    type: "collapse",
    name: "Test",
    key: "Test",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/Test/",
    component: <Test />,
  },
  {
    type: "collapse",
    name: "HAPPENING CATEGORY",
    key: "happeningCategory",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/happeningCategory/",
    component: <HappeningCategory />,
    requireAuth: true,
    componentAuth: HappeningCategory, // No angle brackets
    roles: ["Administrator", "Super"],
  },

  {
    type: "",
    name: "HAPPENING CATEGORY",
    key: "happeningCategory",
    icon: <FiberNewIcon fontSize="small"></FiberNewIcon>,
    route: "/happeningCategory/:id",
    component: <HappeningCategory />,
    requireAuth: true,
    componentAuth: HappeningCategory, // No angle brackets
    roles: ["Administrator", "Super"],
  },

  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    //type: "collapse",
    type: "x",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    requireAuth: false,
    componentAuth: SignIn, // No angle brackets
    roles: ["Administrator", "Super", "DED", "MerchantAdmin", "CustomerService", "PplManager"],
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
