export const toQueryString = (obj) => {
  var parts = [];

  for (var property in obj) {
    var value = obj[property];
    // console.log("filter parm:"+value);

    if (value !== null && value != undefined && value != NaN && !Object.is(value, NaN)) {
      parts.push(encodeURIComponent(property) + "=" + encodeURIComponent(value));
    }
  }

  return parts.join("&");
};

export const IsoDateToDDMMYY = (isoDate) => {
  const date = new Date(isoDate + "Z");
  const formattedDate = `
   ${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getFullYear().toString()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")} `;
  return formattedDate;
};

export const IsoDateToYYYYMMDD = (isoDate) => {
  const date = new Date(isoDate);
  const formattedDate = `${date.getFullYear().toString()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  return formattedDate;
};

export function formatNumberAccountingStyle(number) {
  // Use Intl.NumberFormat to format the number with two decimal places and comma as thousand separator
  let formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Math.abs(number));

  // Add parentheses for negative numbers, otherwise return the formatted number directly
  return formattedNumber;
}

export function formatIntAccountingStyle(number) {
  // Use Intl.NumberFormat to format the number with two decimal places and comma as thousand separator
  let formattedNumber = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.ceil(Math.abs(number)));

  // Add parentheses for negative numbers, otherwise return the formatted number directly
  return formattedNumber;
}

export function uppercaseArrayElements(arr) {
  // Map over the array and uppercase each element
  return arr.map(function (element) {
    // Use the toUpperCase() method to convert the element to uppercase
    return element.toUpperCase();
  });
}

export function uppercasePropertyNames(arr) {
  // Loop through each object in the array
  return arr.map((obj) => {
    // Create a new object to store the modified properties
    const newObj = {};
    // Loop through the properties of the current object
    for (let key in obj) {
      // Uppercase the property name and assign the value to the new object
      if (obj[key]) {
        newObj[key] = obj[key].toUpperCase();
      }
    }
    // Return the new object with uppercase property names
    return newObj;
  });
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
export function hideMiddleCharacters(str) {
  // Check if the string length is less than or equal to 6, in which case, there's nothing to hide
  if (str.length <= 6) {
    return str;
  }

  // Extract the first 3 characters
  let firstThree = str.slice(0, 3);

  // Extract the last 3 characters
  let lastThree = str.slice(-3);

  // Calculate the number of characters to hide
  let middleLength = str.length - 6;

  // Replace middle characters with "*"
  let hiddenMiddle = "*".repeat(middleLength);

  // Concatenate the first three characters, hidden middle, and last three characters
  let result = firstThree + hiddenMiddle + lastThree;

  return result;
}

export function truncateString(variableName, maxLength = 22) {
  const truncatedName =
    variableName.length > maxLength
      ? `${variableName.substring(0, maxLength - 3)}...`
      : variableName;

  return truncatedName;
}
