// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    
  }

.responsive-card {
   /* width: 100%;*/
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}
  
  .responsive-image {
    width: 100%;
    max-width: 200px; /* Adjust as needed */
    height: auto;
  }

  .responsive-box {
    padding: 1rem; /* Adjust as needed */
    width: 80%;
    max-width: 80%;
    max-width: 80%;

  }
  
  @media screen and (max-width: 768px) {
    .responsive-box {
        padding: 1rem; /* Adjust as needed */
        width: 80%;
        max-width: 80%;
        max-width: 80%;
    
      }
    .responsive-form {

      padding: 1rem; /* Adjust as needed */


    }
  }`, "",{"version":3,"sources":["webpack://./src/layouts/authentication/sign-in/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;;EAEnB;;AAEF;GACG,gBAAgB;IACf,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;;EAEE;IACE,WAAW;IACX,gBAAgB,EAAE,qBAAqB;IACvC,YAAY;EACd;;EAEA;IACE,aAAa,EAAE,qBAAqB;IACpC,UAAU;IACV,cAAc;IACd,cAAc;;EAEhB;;EAEA;IACE;QACI,aAAa,EAAE,qBAAqB;QACpC,UAAU;QACV,cAAc;QACd,cAAc;;MAEhB;IACF;;MAEE,aAAa,EAAE,qBAAqB;;;IAGtC;EACF","sourcesContent":[".center-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-height: 100vh;\n    \n  }\n\n.responsive-card {\n   /* width: 100%;*/\n    max-width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n}\n  \n  .responsive-image {\n    width: 100%;\n    max-width: 200px; /* Adjust as needed */\n    height: auto;\n  }\n\n  .responsive-box {\n    padding: 1rem; /* Adjust as needed */\n    width: 80%;\n    max-width: 80%;\n    max-width: 80%;\n\n  }\n  \n  @media screen and (max-width: 768px) {\n    .responsive-box {\n        padding: 1rem; /* Adjust as needed */\n        width: 80%;\n        max-width: 80%;\n        max-width: 80%;\n    \n      }\n    .responsive-form {\n\n      padding: 1rem; /* Adjust as needed */\n\n\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
