/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes, { element } from "prop-types";

// Custom styles for MDBox
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const MSSelect = ({
  name,
  control,
  label,
  initValue,
  value,
  options,
  defaultValue,
  variant,
  bgColor,
  color,
  opacity,
  borderRadius,
  shadow,
  coloredShadow,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl required sx={{ minWidth: "20%" }}>
          <InputLabel id="demo-simple-select-required-label">{label}</InputLabel>

          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={value}
            onChange={onChange}
            defaultValue={1}
            name={name}
            {...rest}
          >
            {options.map((element, index) => {
              return (
                <MenuItem m={20} key={index} value={element.id}>
                  {element.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default MSSelect;
