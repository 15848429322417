import PageLayout from "examples/LayoutContainers/PageLayout";
import React from "react";
import { useParams } from "react-router-dom";
import logoImage from "./../../assets/images/logos/pplLogo.png"; // Make sure to provide the correct path to the image

const privacyPolicy = () => {
  const params = useParams();
  const name = params.name;
  const code = params.code;

  return (
    <PageLayout>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <div style={{ height: "25px" }}></div>
        <img
          src={logoImage}
          width="30%"
          height="30%"
          style={{
            margin: "0",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          alt="Logo"
        />

        <div
          style={{
            margin: "0 auto",
            //width: "70%",
            marginTop: "4%",
            marginBottom: "2%",
            paddingRight: "8px",
            paddingLeft: "8px",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{ width: "25%" }}>
            <u>Shop</u>
          </div>
          <div style={{ width: "25%" }}>
            <u>Dine</u>
          </div>
          <div style={{ width: "25%" }}>
            <u>Beauty & Services</u>
          </div>
          <div style={{ width: "25%", textAlign: "right" }}>
            <u>Unwind</u>
          </div>
        </div>
        <div style={{ height: "20px" }}></div>

        <div
          style={{
            width: "80%",
            margin: "0 auto",
            paddingRight: "8px",
            paddingLeft: "8px",
            textAlign: "left",
            color: "#000",
            fontFamily: "arial",
            fontSize: "14px",
            display: "block",
          }}
        >
          <div>
            <h2> Privacy Policy</h2>
          </div>
          <div>
            <p>
              1. By interacting with Mercato and Town Centre Jumeirah, signing up for any products
              and services offered by Mercato and Town Centre Jumeirah, or submitting any personal
              data (such as your name, date of birth, email address, telephone numbers, gender and
              nationality) to Mercato and Town Centre Jumeirah, including without limitation through
              the Privilege<b>PLUS</b>Loyalty Programme form, via the Privilege<b>PLUS</b>
              application and/or website, the member hereby consents to Privilege<b>PLUS</b> Mercato
              and Town Centre Jumeirah, as well as Privilege
              <b>PLUS</b> Mercato and Town Centre Jumeirah collecting, using, and disclosing the
              member’s personal data for the following purposes and any and all ancillary purposes
              related thereto:
            </p>
            <div>
              <p>1.1. Maintaining and operating the member's account;</p>
              <p>1.2. Establishing and verifying the member's identity;</p>

              <p>1.3. Communicating with the member; and/or</p>

              <p> 1.4.. Disclosing the member’s personal data (where necessary) to:</p>
              <div>
                <p>
                  1.4.1. Third party entities whose products and/or services are offered on
                  Privilege<b>PLUS</b>’s application and/or website in order for such entities to
                  attend to any queries or matters in relation to such products and/or services;
                </p>
                <p>
                  1.4.2. Privilege<b>PLUS</b> Mercato and Town Centre Jumeirah’s related
                  corporations and affiliates, Privilege
                  <b>PLUS</b> Mercato and Town Centre Jumeirah’s tenants, Privilege<b>PLUS</b>{" "}
                  Mercato and Town Centre Jumeirah’s business partners and Privilege
                  <b>PLUS</b> Mercato and Town Centre Jumeirah’s service providers, as well as
                  Mercato and Town Centre Jumeirah’s related corporations and affiliates for use by
                  each of the aforesaid in research and marketing analytics for the purpose of
                  developing and enhancing its products, services, quality and strategies.
                </p>
              </div>
            </div>

            <p>
              2. The member represents and warrants to Privilege
              <b>PLUS</b>
              Mercato and Town Centre Jumeirah that the personal data and other information provided
              is correct, true, accurate and up-to-date. If any personal data and/or information
              provided by the member is incorrect, inaccurate, untrue and/or not up-to-date,
              Privilege<b>PLUS</b>
              Mercato and Town Centre Jumeirah may reject and/or cancel the member’s application
              and/or membership, and may suspend and/or terminate the provision of part or all of
              the services hereunder.
            </p>

            <p>
              3. The member represents, warrants and undertakes that the member may submit to
              Privilege<b>PLUS</b> Mercato and Town Centre Jumeirah, or to a third-party entity
              whose products and/or services are made available or offered on Privilege<b>PLUS</b>
              Mercato and Town Centre Jumeirah’s application and/or website, the personal data of
              any other person on behalf of such other person only:
            </p>
            <div>
              <p>
                3.1. If and to the extent that such other person has expressly consented to
                providing his/her personal data for the applicable purposes
              </p>
            </div>

            <p>
              4. Further, if the member consents to receiving promotional emails, the member allows
              the Group to:
            </p>
            <div>
              <p>
                4.1. Send to the member marketing communications (such as Privilege
                <b>PLUS</b> Mercato and Town Centre Jumeirah’s newsletter, e-flyers, SMS and app
                push notifications) in relation to Privilege
                <b>PLUS</b> Mercato and Town Centre Jumeirah’s sales, products, services, promotions
                or the Privilege
                <b>PLUS</b>’s application and/or Privilege
                <b>PLUS</b> Mercato and Town Centre Jumeirah’s websites; and
              </p>

              <p>
                4.2. Send to the member marketing communications in relation to the sales, products,
                services or promotions of third-party entities whose products and/or services are
                offered on Privilege<b>PLUS</b>’s application and/or Privilege<b>PLUS</b> Mercato
                and Town Centre Jumeirah’s website.
              </p>
            </div>

            <p>
              5. In addition, by signing up for the Privilege
              <b>PLUS</b>Loyalty Programme, the member would also have read, understood and agreed
              to be bound by Privilege
              <b>PLUS</b> Mercato and Town Centre Jumeirah’s terms and conditions, and hereby
              consents to the collection, use and/or disclosure of the member’s personal data for
              the purposes set out in the aforesaid policies.
            </p>

            <p>
              6. The member may withdraw the member’s consent given to Privilege
              <b>PLUS</b> Mercato and Town Centre Jumeirah for the collection, use and/or disclosure
              of the member’s personal data for any or all purposes set out herein by contacting us
              at the following email address: information.mercato@mercatotowncentre.ae or
              information.towncentre@mercatotowncentre.ae . However, if the member does so and
              depending on the nature of the member’s request, Privilege
              <b>PLUS</b> Mercato and Town Centre Jumeirah may not be in a position to continue to
              provide our services to the member under the Privilege
              <b>PLUS</b>Loyalty Programme.
            </p>

            <p style={{ fontWeight: "600" }}>7. MISCELLANEOUS</p>

            <div>
              <p>7.1. For the purposes of these Terms and Conditions</p>
              <div>
                <p>
                  7.1.1. “Privilege<b>PLUS</b>” shall include Privilege<b>PLUS</b> Mercato and Town
                  Centre Jumeirah, as the case may be; and “Programme” shall include Mercato and
                  Town Centre Jumeirah’s Privilege<b>PLUS</b>Loyalty Programme.
                </p>
              </div>

              <p>
                7.2. Privilege<b>PLUS</b> Mercato and Town Centre Jumeirah’s decisions on all
                matters relating to or in connection with the use of the Programme, including but
                not limited to the Privileges and redemption of benefits, shall be final and binding
                on the member and no correspondence shall be entertained.
              </p>

              <p>
                7.3. A person who is not a member or a party to these Terms and Conditions has no
                rights to enforce any of the terms and conditions herein.
              </p>
            </div>
          </div>
        </div>
        <div style={{ height: "10px" }}></div>
      </div>
    </PageLayout>
  );
};

export default privacyPolicy;
