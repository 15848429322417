// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import team2 from "assets/images/team-2.jpg";

import { useEffect, useState } from "react";
import Api from "constants/Api";
import { axiosApiLoyalty, axiosMerchant, axiosReport } from "./../../../axiosInstance"; // Adjust the path as per your file structure

import * as extendedFun from "extensions/extendedFunctions";
import MDAvatar from "components/MDAvatar";
import { useAuth } from "../../../contexts/authContext";

const initialFilter = {
  memberId: null,
  mallId: null,
  merchantstatusid: 1,
  //MemberName: "",
  //Username: "Test USer",
  // MerchantId: 1,
  //StartTransactionDate: formatDateURL(new Date()),
  //EndTransactionDate: null,
  page: 1,
  pageSize: -1,
  sortBy: "name",
  isSortAsc: true,
};

/*id, name, logoimage, mallid, groupid, categoryid, shopno, merchantstatusid, contacttelephone, details, refunddays, joindatetomall,
 "isPPL", dateofparticipationtoppl, ppldiscountpercentage, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate",
  "activityId", "isAnchor", "pplCeilPurcha
 */
export default function data(
  screenStatus,
  setOpen,
  setDeletedId,
  _refresh = false,
  setImageId = "",
  setOpenImage
) {
  const [merchant, setMerchant] = useState({ items: [] });
  const [refresh, setRefresh] = useState(_refresh);
  const [filter, setFilter] = useState(initialFilter);

  console.log("data");
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {data}
      </MDTypography>
    </MDBox>
  );

  const InfoImage = ({ image, name, onClick }) => (
    <MDBox display="block" color="text" fontWeight="medium">
      <MDAvatar src={image} name={name} size="sm" onClick={onClick} />
    </MDBox>
  );
  useEffect(() => {
    setRefresh(_refresh);
  }, [_refresh]);

  console.log("enter table data");
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const getMerchant = () => {
    const params = extendedFun.toQueryString(filter);
    const url = `${Api.merchant}/Merchant/getAll?${params}`;
    console.log("url" + url);
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    axiosMerchant.get(url).then((response) => {
      const arr = response.data;
      //console.log("array:" + JSON.stringify(arr));
      setMerchant(arr);
    });
  };

  useEffect(() => {
    console.log("getMerchant()");

    getMerchant();
  }, [screenStatus]);

  useEffect(() => {
    console.log("getMerchant()");

    getMerchant();
  }, [refresh]);

  /*id, , , , , , , , , , , ,
 "isPPL", , , "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate",
  "activityId", "isAnchor", "
  floor, "pplDiscountTerms", , "", , pplcategoryid
 */
  function ImagePopup({ image, onClose }) {
    return (
      <div className="popup">
        <div className="popup-content">
          <img src={image} alt="Large Image" />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  }
  return {
    columns: [
      { Header: "action", accessor: "action", align: "center" },
      { Header: "name", accessor: "name", width: "10%", align: "left" },

      { Header: "mall", accessor: "mall", width: "10%", align: "left" },
      { Header: "category", accessor: "category", width: "10%", align: "left" },
      { Header: "activity", accessor: "activity", width: "10%", align: "left" },
      { Header: "status", accessor: "status", width: "10%", align: "left" },

      //{ Header: "status", accessor: "status", width: "10%", align: "left" },

      { Header: "shop no", accessor: "shopno", width: "5%", align: "left" },
      { Header: "floor", accessor: "floor", width: "10%", align: "left" },
      { Header: "telephone", accessor: "telephone", align: "center" },
      { Header: "logo", accessor: "logo", align: "center" },
      { Header: "shop Image", accessor: "shopImage", align: "center" },
      { Header: "join date", accessor: "joindatetomall", align: "left" },

      { Header: "refund days", accessor: "refunddays", align: "center" },
      { Header: "exchange days", accessor: "exchangedays", align: "center" },
      { Header: "ppl Ceil Purchase", accessor: "pplCeilPurchase", align: "center" },

      { Header: "1st ppl discount", accessor: "ppldiscountpercentage", align: "center" },
      { Header: "2ed ppl discount", accessor: "ppldiscountpercentage2", align: "center" },
      { Header: "ppl Discount Terms", accessor: "pplDiscountTerms", align: "center" },

      { Header: "created By", accessor: "createdBy", align: "center" },
      { Header: "details", accessor: "details", align: "left" },
    ],
    rows: merchant.items.map((item) => ({
      action: (
        <>
          <MDTypography
            component="a"
            href={`/merchant/${item.id}`}
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            Edit
          </MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="medium">
            -
          </MDTypography>
          <MDTypography
            component="a"
            href="#"
            onClick={() => {
              console.log("delete");
              setOpen(true);
              setDeletedId(item.id);
            }}
            variant="caption"
            color="text"
            fontWeight="medium"
          >
            delete
          </MDTypography>
        </>
      ),
      name: <Info data={item.name ? item.name : ""} />,

      mall: <Info data={item.mall.name} />,
      category: <Info data={item.category.name} />,
      activity: <Info data={item.activity.name} />,
      status: <Info data={item.merchantstatus.name} />,

      shopno: <Info data={item.shopno ? item.shopno : ""} />,
      floor: <Info data={item.floor} />,
      telephone: <Info data={item.contacttelephone} />,
      logo: <InfoImage image={item.logoimage} name={item.logoimage ? item.logoimage : ""} />,
      shopImage: (
        <InfoImage
          image={item.shopImage}
          name={item.shopImage ? item.shopImage : ""}
          onClick={() => {
            console.log("inlarge Image");
            setOpenImage(true);
            setImageId(item.shopImage);
          }}
        />
      ),
      //image: <InfoImage image={team2} name={item.image ? item.details : ""} />,
      joindatetomall: (
        <Info data={item.joindatetomall ? extendedFun.IsoDateToDDMMYY(item.joindatetomall) : ""} />
      ),

      refunddays: <Info data={item.refunddays ? item.refunddays : ""} />,
      exchangedays: <Info data={item.exchangedays ? item.exchangedays : ""} />,
      pplCeilPurchase: <Info data={item.pplCeilPurchase ? item.pplCeilPurchase : ""} />,
      ppldiscountpercentage: (
        <Info data={item.ppldiscountpercentage ? item.ppldiscountpercentage : ""} />
      ),
      ppldiscountpercentage2: (
        <Info data={item.ppldiscountpercentage2 ? item.ppldiscountpercentage2 : ""} />
      ),
      pplDiscountTerms: <Info data={item.pplDiscountTerms ? item.pplDiscountTerms : ""} />,

      createdBy: <Info data={item.createdBy ? item.createdBy : ""} />,
      details: <Info data={item.details} />,
    })),
  };
}
/*
accessor: "mall",
accessor: "category",
accessor: "status",

accessor: "name",
accessor: "shopno"
accessor: "floor",
accessor: "telephone"
accessor: "logo"
accessor: "shopImage"
accessor: "details"
accessor: "joindatetomall", align: "left" },

accessor: "refunddays"
accessor: "exchangedays"
 accessor: "pplCeilPurchaseDate"

accessor: "ppldiscountpercentage"
 accessor: "ppldiscountpercentage2"
 accessor: "pplDiscountTerms"
*/
