/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

import New from "./components/New";
import DataTable from "examples/Tables/DataTable";
import merchantsTableData from "layouts/Merchant/data/merchantsTableData";
import { useAuth } from "../../contexts/authContext";

import { useParams } from "react-router-dom";
import Edit from "./components/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { axiosMerchant } from "./../../axiosInstance"; // Adjust the path as per your file structure

import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import axios from "axios";
import Api from "constants/Api";
import MDAlert from "components/MDAlert";
// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"

const list = "list";
const newEntry = "newEntry";
const editEntry = "editEntry";

function Merchant() {
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);
  const [deletedId, setDeletedId] = useState(null);

  const [open, setOpen] = useState(false);

  const [imageId, setImageId] = useState(null);
  const [openImage, setOpenImage] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const confirmDelete = () => {
    setOpen(false);
    const url = `${Api.merchant}/merchant/delete?id=${deletedId}`;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axiosMerchant
      .delete(url)
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        console.log(response.data);
        setMessage("Merchant deleted successfully");
        setAlertType("success");
        setRefresh((prevCheck) => !prevCheck);
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });
  };

  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );
  const [screenStatus, setScreenStatus] = useState(list);

  const params = useParams();
  console.log("refresh");

  const { columns, rows } = merchantsTableData(
    screenStatus ? screenStatus : "",
    setOpen,
    setDeletedId,
    refresh,
    setImageId,
    setOpenImage
  );

  useEffect(() => {
    console.log("url params:" + JSON.stringify(params));
    //setNewEntry(!(params.newEntryw === "false"));
    if (params.id) {
      setId(params.id);
    } else {
      setScreenStatus(list);
    }
  }, [params]);

  useEffect(() => {
    if (id != null) {
      setScreenStatus(editEntry);
    }
  }, [id]);
  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {screenStatus == list && "List Merchants"}
              {screenStatus == newEntry && "Create Merchants"}
              {screenStatus == editEntry && "Edit Merchants"}
            </MDTypography>
          </MDBox>
          {message && (
            <MDBox>
              <MDAlert color={alertType} dismissible>
                {alertContent(message, alertType)}
              </MDAlert>
            </MDBox>
          )}
          {screenStatus == newEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              {/*<MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(list);
                }}
                size="small"
              >
                {"< Back"}
              </MDButton>*/}
              <New action={{ route: "/merchant", color: "primary", tooltip: "Edit Profile" }} />
            </MDBox>
          )}
          {screenStatus == editEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              <Edit
                id={Number(id)}
                action={{ route: "/merchant", color: "primary", tooltip: "Edit Profile" }}
              />
            </MDBox>
          )}

          {screenStatus == list && (
            <MDBox ml={4} mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(newEntry);
                  //setList(false);
                }}
                size="small"
              >
                Create New
              </MDButton>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage
                showTotalEntries
                noEndBorder
              />
            </MDBox>
          )}
        </Card>
      </MDBox>
      <MDBox>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Winner"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete selected merchant?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={confirmDelete} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openImage}
          onClose={handleCloseImage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete Winner"}</DialogTitle>
          <DialogContent>
            <img src={imageId} width="400" height="400" />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImage}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Merchant;
