/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

import New from "./components/New";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/Happening/data/authorsTableData";

import { useParams } from "react-router-dom";
import Edit from "./components/Edit";
// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"

const list = "list";
const newEntry = "newEntry";
const editEntry = "editEntry";

function Happening() {
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);

  const [screenStatus, setScreenStatus] = useState(list);

  const params = useParams();

  const { columns, rows } = authorsTableData(screenStatus ? screenStatus : "");

  //console.log("access happening");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();

  useEffect(() => {
    console.log("url params:" + JSON.stringify(params));
    //setNewEntry(!(params.newEntryw === "false"));
    if (params.id) {
      setId(params.id);
    } else {
      setScreenStatus(list);
    }
  }, [params]);

  useEffect(() => {
    if (id != null) {
      setScreenStatus(editEntry);
    }
  }, [id]);
  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              {screenStatus == list && "List Happening"}
              {screenStatus == newEntry && "Create Happening"}
              {screenStatus == editEntry && "Edit Happening"}
            </MDTypography>
          </MDBox>

          {screenStatus == newEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(list);
                  //setList(false);
                }}
                size="small"
              >
                {"< Back"}
              </MDButton>
              <New action={{ route: "/happening", color: "primary", tooltip: "Edit Profile" }} />
            </MDBox>
          )}
          {screenStatus == editEntry && (
            <MDBox ml={4} mt={2} mb={1}>
              <Edit
                id={Number(id)}
                action={{ route: "/happening", color: "primary", tooltip: "Edit Profile" }}
              />
            </MDBox>
          )}

          {screenStatus == list && (
            <MDBox ml={4} mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => {
                  setScreenStatus(newEntry);
                  //setList(false);
                }}
                size="small"
              >
                Create New
              </MDButton>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage
                showTotalEntries
                noEndBorder
              />
            </MDBox>
          )}
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Happening;
