/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images

import { useEffect, useState } from "react";

import * as extendedFun from "extensions/extendedFunctions";

export default function data(transactions, setOpen, setEditedId, setEditedEmail, setEditedMobile) {
  //console.log("transactions in auth table:" + JSON.stringify(transactions));
  const [happening, setHappening] = useState(transactions);
  //const [filter, setFilter] = useState(transactionFilter);

  console.log("table data transaction ");
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="small">
      <MDTypography display="block" variant="caption">
        {data}
      </MDTypography>
    </MDBox>
  );

  console.log("array++");

  useEffect(() => {
    console.log("getTransaction()");

    setHappening(transactions);
  }, [transactions]);
  return {
    columns: [
      //{ Header: "merchant", accessor: "merchant", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
      { Header: "MemberId", accessor: "MemberId", width: "15%", align: "center" },

      { Header: "First name", accessor: "firstName", width: "15%", align: "center" },
      //{ Header: "Customer", accessor: "customerName", width: "20%", align: "left" },

      { Header: "Last name", accessor: "lastName", width: "20%", align: "center" },
      { Header: "Mobile Number", accessor: "phoneNumber", width: "20%", align: "center" },
      { Header: "Email", accessor: "email", width: "20%", align: "center" },
      { Header: "Nationality", accessor: "nationality", width: "20%", align: "center" },
      { Header: "Gender", accessor: "gender", width: "20%", align: "center" },
      { Header: "Age", accessor: "age", width: "20%", align: "center" },
      { Header: "Member Type", accessor: "residenceStatus", width: "20%", align: "center" },
      { Header: "Is Existing", accessor: "isExistingMember", width: "20%", align: "center" },
      { Header: "Complete Login", accessor: "isCompletedLogin", width: "20%", align: "center" },

      { Header: "Date of Joining", accessor: "created_on", width: "20%", align: "center" },
    ],
    rows: happening.map((item) => ({
      action: (
        <>
          <MDTypography
            component="a"
            href="#"
            onClick={() => {
              console.log("delete");
              setOpen(true);
              setEditedId(item.email);
              setEditedEmail(item.email);
              setEditedMobile(item.phoneNumber);
            }}
            variant="caption"
            color="text"
          >
            Edit
          </MDTypography>
        </>
      ),
      //merchant: <Info data={item.merchantName} />,

      date: <Info data={extendedFun.IsoDateToDDMMYY(item.dateOfTransaction)} />,
      //date: <Info data={item.dateOfTransaction} />,
      //customerName: <Info data={item.firstname + " " + item.lastname} />,

      MemberId: <Info data={item.code} />,

      firstName: <Info data={item.firstName} />,
      lastName: <Info data={item.lastName} />,
      phoneNumber: <Info data={item.phoneNumber} />,
      email: <Info data={item.email} />,
      gender: <Info data={item.gender} />,
      age: <Info data={item.age} />,
      nationality: <Info data={item.nationality} />,
      residenceStatus: <Info data={item.residenceStatus} />,
      isExistingMember: <Info data={item.isExistingMember ? "Yes" : "No"} />,
      isCompletedLogin: item.isExistingMember ? (
        <Info data={item.isCompletedLogin ? "Yes" : "No"} />
      ) : (
        <Info data={"-"} />
      ),

      created_on: <Info data={extendedFun.formatDate(item.created_on)} />,

      transactionNumber: <Info data={item.transactionNumber ? item.transactionNumber : ""} />,
      transactionValue: (
        <Info
          data={
            item.transactionValue !== undefined && item.transactionValue !== null
              ? "AED " + extendedFun.formatNumberAccountingStyle(item.transactionValue.toFixed(2))
              : ""
          }
        />
      ),
      points: <Info data={item.points !== undefined && item.points !== null ? item.points : ""} />,
      //balance: <Info data={item.pointbalance ? item.pointbalance : ""} />,

      transactionType: <Info data={item.transactionType ? item.transactionType : ""} />,
    })),
  };
}
