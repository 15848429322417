/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import axios from "axios";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Api from "constants/Api";
import PplDraw from "../pplDraw";
import { useAuth } from "../../../../contexts/authContext";
import { useNavigate } from "react-router-dom";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
const initPplCoupons = { totalItems: 0, items: [] };

// Format the dates as 'dd-mm-yyyy'
const formatDate = (date) => {
  const month = String(date.getDate()).padStart(2, "0");
  const day = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

function PplDrawsList({ onWinnerView }) {
  const [pplCoupons, setPplCoupons] = useState(initPplCoupons);

  const [couponTypeFilter, setCouponTypeFilter] = useState(null);
  const [nameFilter, setNameFilter] = useState("");

  const navigate = useNavigate();
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const getCoupons = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the current year
    const currentYear = currentDate.getFullYear();

    // Get the first day of the current year
    const firstDayOfYear = new Date(currentYear, 0, 1); // Month is 0-indexed, so 0 is January

    // Get the last day of the current year
    const lastDayOfYear = new Date(currentYear, 11, 31); // Month is 0-indexed, so 11 is December

    const startDate = formatDate(firstDayOfYear);
    const endDate = formatDate(lastDayOfYear);
    const url = `${Api.loyalty}/PplCoupon/getAllForDash?StartRangeDate=${startDate}&EndRangeDate=${endDate}`;
    console.log("Call PPL getCoupons:" + url);

    axios
      .get(
        url,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
        }
      )
      .then((response) => {
        const arr = response.data;

        //sorted by date
        arr.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        console.log("PplCoupons:" + JSON.stringify(arr));
        setPplCoupons(arr);
      })
      .catch((err) => {
        console.log("getCouponDetails error: +" + JSON.stringify(err));
        if (err.response && err.response.status === 401) {
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    // getCurrentForMembership?TierId=1 //const url = `${Api.api}/Transaction/getAll?${params}`;
    // const url = `${Api.api}/PplCoupon/getCurrentForMembership?SortBy=startdate&IsSortAsc=false`;

    //filter by current year
    getCoupons();
  }, []);

  useEffect(() => {
    console.log("useEffect PPL Coupons");
  }, []);

  const handleCouponTypeChange = (event) => {
    setCouponTypeFilter(event.target.value);
  };

  const handleNameFilterChange = (event) => {
    setNameFilter(event.target.value);
  };

  const filteredCoupons = pplCoupons.items.filter((coupon) => {
    let typeCondition = true;
    let nameCondition = true;

    if (couponTypeFilter) {
      console.log("event.target.value" + couponTypeFilter);

      typeCondition = coupon.couponType.id == couponTypeFilter;
    }

    if (nameFilter) {
      nameCondition = coupon.name.toLowerCase().includes(nameFilter.toLowerCase());
    }

    return typeCondition && nameCondition;
  });

  const distinctCouponTypes = pplCoupons.items.reduce((acc, currentItem) => {
    // Check if the accumulator already contains the current couponType
    const exists = acc.find((type) => type.id === currentItem.couponType.id);

    // If it doesn't exist, add to the accumulator
    if (!exists) {
      acc.push(currentItem.couponType);
    }

    return acc;
  }, []);
  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          PrivilegePLUS Draws List
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} display="flex" justifyContent="space-between">
        <FormControl variant="outlined" style={{ width: "48%", height: "46px" }}>
          <InputLabel>Coupon Type</InputLabel>
          <Select
            value={couponTypeFilter}
            onChange={handleCouponTypeChange}
            label="Coupon Type"
            style={{ height: "46px" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {distinctCouponTypes.map((act, index) => (
              <MenuItem value={act.id}>{act.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ width: "48%", height: "46px" }}>
          <input
            type="text"
            //label="Find by name"
            placeholder="Find by name..."
            value={nameFilter}
            onChange={handleNameFilterChange}
          />
          {/*<Select
            value={nameFilter}
            onChange={handleNameFilterChange}
            label="Name"
            style={{ height: "46px" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {pplCoupons.items.map((item, index) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>*/}
        </FormControl>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {filteredCoupons.map((act, index) => (
            <MDBox key={index}>
              <PplDraw
                index={index}
                name={act.name}
                description={act.description}
                prize={act.prizeDetails}
                numberOfWinner={act.numberOfWinner}
                drawDate={act.drawDate}
                startDate={act.startDate}
                endDate={act.endDate}
                isActive={act.isActive}
                isDisable={act.isDisable}
                winners={act.winnerPpls}
                onWinnerView={onWinnerView}
                prizeList={act.prizes}
                couponId={act.id}
                drawType={act.couponType.name}
              />
            </MDBox>
          ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of Bill
PplDrawsList.defaultProps = {
  onWinnerView: () => {},
};
// Typechecking props for the Bill
PplDrawsList.propTypes = {
  onWinnerView: PropTypes.func,
};
export default PplDrawsList;
