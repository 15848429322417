/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Billing page components

import { useEffect, useState } from "react";
import PromotionDrawsList from "./components/PromotionDrawsList";
import Winners from "./components/Winners";
import { useParams } from "react-router-dom";

import MDTypography from "components/MDTypography";
import Edit from "./components/Edit";

const list = "list";
const newEntry = "newEntry";
const editEntry = "editEntry";

function promotionDrawCreate() {
  const [winnerArr, setWinnerArr] = useState([]);
  const [drawDetails, setDrawDetails] = useState("");
  const [numberOfWinners, setNumberOfWinners] = useState(0);
  const [prizes, setPrizes] = useState([]);
  const [couponId, setCouponId] = useState(0);
  const [viewWinnersFun, setViewWinnersFun] = useState(null);

  const winnerViewHandler = (v1, v2, v3, v4, v5) => {
    setWinnerArr(v1);
    setDrawDetails(v2);
    setNumberOfWinners(v3);
    setPrizes(v4);
    console.log("setCouponId:" + v5);
    setCouponId(v5);
  };
  const [screenStatus, setScreenStatus] = useState(list);

  const [id, setId] = useState(null);

  const params = useParams();

  useEffect(() => {
    console.log("url params:" + JSON.stringify(params.id));
    //setNewEntry(!(params.newEntryw === "false"));
    if (params.id) {
      setId(params.id);
    } else {
      setId(null);
      setScreenStatus(list);
    }
  }, [params]);

  useEffect(() => {
    console.log("id@:" + JSON.stringify(id));

    if (id != null) {
      setScreenStatus(editEntry);
    }
  }, [id]);

  useEffect(() => {
    console.log("screenStatus:" + JSON.stringify(params));
    console.log("screenStatus:" + JSON.stringify(screenStatus));
  }, []);

  if (screenStatus == editEntry) {
    return (
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <Edit
                  id={Number(id)}
                  action={{
                    route: "/promotionDrawCreate",
                    color: "primary",
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
  if (screenStatus == list) {
    return (
      <DashboardLayout>
        <DashboardNavbar absolute isMini />
        <MDBox mt={8}>
          <MDBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <PromotionDrawsList onWinnerView={winnerViewHandler} />
              </Grid>
              <Grid item xs={12} md={5}>
                <Winners
                  _winnerArr={winnerArr}
                  _drawDetails={drawDetails}
                  _numberOfWinners={numberOfWinners}
                  _prizeList={prizes}
                  _couponId={couponId}
                />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default promotionDrawCreate;
