/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import PeopleIcon from "@mui/icons-material/People";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import Api from "constants/Api";
import axios from "axios";
import { useAuth } from "../../../../contexts/authContext";
import { useNavigate } from "react-router-dom";

const IsoDateToDDMMYY = (isoDate) => {
  const date = new Date(isoDate);
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getFullYear().toString()}`;
  return formattedDate;
};

const initPplCoupon = { totalItems: 0, items: [] };

function pplDraw({
  index,
  name,
  description,
  prize,
  numberOfWinner,
  drawDate,
  startDate,
  endDate,
  isActive,
  isDisable,
  winners,
  onWinnerView,
  prizeList,
  couponId,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [pplCoupon, setPplCoupon] = useState(initPplCoupon);
  const navigate = useNavigate();
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const getCouponDetails = (id) => {
    console.log("getCouponDetails is called : +" + id);

    const url = `${Api.loyalty}/Promotion/getAllForDash?couponid=${id}`;
    console.log("promotion get is called : +" + url);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;

        //sorted by date
        arr.items.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        console.log("promotion Card :" + JSON.stringify(arr));
        setPplCoupon(arr);
      })
      .catch((err) => {
        console.error("There was an error!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  const viewWinners = () => {
    // send list of inner of draw to winners component
    // console.log(`winners:${JSON.stringify(winners[0])}`);
    //onWinnerView(winners, description, numberOfWinner, prizeList, couponId);
    //call latest details of coupon
    console.log("viewWinners is called : ");
    getCouponDetails(couponId);
  };

  useEffect(() => {
    //update parent component
    pplCoupon.items.map((act) =>
      onWinnerView(act.winnerPpls, act.description, act.numberOfWinner, act.prizes, act.id)
    );
  }, [pplCoupon]);

  return (
    <MDBox
      key={index}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor={isActive ? "grey-100" : "grey-100"}
      borderRadius="lg"
      p={3}
      mb={1}
      mt={2}
    >
      <MDBox width="100%" display="flex" flexDirection="column">
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
          mb={2}
        >
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            {name}
          </MDTypography>

          {/*<MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
            <MDBox mr={1}>
              <MDButton variant="text" color="error">
                <Icon>delete</Icon>&nbsp;delete
              </MDButton>
            </MDBox>
            <MDButton variant="text" color={darkMode ? "white" : "dark"}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
          </MDBox>*/}
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Description:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
              {description}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Number Of Winner:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {numberOfWinner}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDBox mb={1} lineHeight={0}>
          <MDTypography variant="caption" color="text">
            Prize:&nbsp;&nbsp;&nbsp;
            <MDTypography variant="caption" fontWeight="medium">
              {prize}
            </MDTypography>
          </MDTypography>
        </MDBox>
        <MDTypography variant="caption" color="text">
          DrawDate:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {IsoDateToDDMMYY(drawDate)}
          </MDTypography>
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Start Date:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {IsoDateToDDMMYY(startDate)}
          </MDTypography>
        </MDTypography>

        <MDTypography variant="caption" color="text">
          End Date:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {IsoDateToDDMMYY(endDate)}
          </MDTypography>
        </MDTypography>
        <MDTypography variant="caption" color="text">
          Disable:&nbsp;&nbsp;&nbsp;
          <MDTypography variant="caption" fontWeight="medium">
            {isDisable}
          </MDTypography>
        </MDTypography>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems=""
          mt={{ xs: 2, sm: 2 }}
          ml={{ xs: -1.5, sm: 0 }}
        >
          {/* <MDButton disabled={!isActive} variant="gradient" color="success" onClick={() => {}}>
            <EmojiEventsIcon>EmojiEventsIcon</EmojiEventsIcon>&nbsp;Select Winner
          </MDButton> */}
          <MDButton variant="gradient" color="info" onClick={viewWinners}>
            <PeopleIcon>PeopleIcon</PeopleIcon>&nbsp; Winners
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
pplDraw.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Bill
pplDraw.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prize: PropTypes.string.isRequired,
  numberOfWinner: PropTypes.number.isRequired,
  drawDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool.isRequired,
  onWinnerView: PropTypes.func,
  couponId: PropTypes.number.isRequired,
};

export default pplDraw;
