import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//import { saveAs } from "file-saver";

// Material Dashboard 2 React components
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useState, useRef } from "react";

import DataTable from "examples/Tables/DataTable";
import authorsTableData from "./data/authorsTableData";
import ExcelJS from "exceljs";

import { useParams } from "react-router-dom";
import { MenuItem, Select, Button, InputLabel, FormControl, Input } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import * as extendedFun from "extensions/extendedFunctions";
import { useNavigate } from "react-router-dom";

import "./styles.css"; // Import your CSS file

import { jsPDF } from "jspdf";
import "jspdf-autotable";

const initMerchants = [
  { id: null, name: "Select Merchant*" },
  { id: 1, name: "abc" },
  { id: 2, name: "xyz" },
];

const nationalities = [{ id: "all", name: "All nationalities" }];
const genders = [{ id: "all", name: "All genders" }];

const transactionTypes = [
  { id: "all", name: "All transactions type" },
  { id: "purchase", name: "Purchase" },
  { id: "exchange", name: "Exchange" },
  { id: "refund", name: "Refund" },
  { id: "void", name: "void" },
];
import { useAuth } from "../../contexts/authContext";
import Api from "constants/Api";
import axios from "axios";
import { date } from "yup";
import MDInput from "components/MDInput";
import Merchant from "layouts/Merchant";

const imageUrl1 = "../pplLogo.jpg";
const imageUrl2 = "../Logo-english.jpg";
const imageUrl3 = "../tc_logo.jpg";

// Function to return array of objects with unique elements
function getUniqueObjects(array) {
  // Create a Set to store unique IDs
  let uniqueIds = new Set();

  // Use filter to iterate through the array and keep only the unique objects
  let uniqueArray = array.filter((obj) => {
    if (uniqueIds.has(obj.id)) {
      return false; // If ID already exists, filter it out
    } else {
      uniqueIds.add(obj.id); // Add ID to Set
      return true; // Keep the object
    }
  });

  return uniqueArray;
}

function TransactionsReportV1() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  console.log("useAuth():" + token + "," + roles + "," + merchantId);
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);
  const [merchants, setMerchants] = useState({ items: [] });
  const [merchantName, setMerchantName] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const [selectedNationality, setSelectedNationality] = useState();
  const [selectedGender, setSelectedGender] = useState();

  const [nationalityList, setnationalityList] = useState(nationalities);
  const [genderList, setGenderList] = useState(genders);

  const [customerName, setCustomerName] = useState();
  const [lastName, setLastName] = useState();
  const [memberCode, setMemberCode] = useState();

  const [mobileNumber, setMobileNumber] = useState();

  const [transactionFilter, setTransactionFilter] = useState({});
  const [submittedTransactionFilter, setSubmittedTransactionFilter] = useState({
    merchantId: merchantId,
  });
  const [transactions, setTransactions] = useState([]);

  const [totalTrans, setTotalTrans] = useState(0);
  const [totalSales, setTotalSales] = useState(0);

  const [totalVoidAmt, setTotalVoidAmt] = useState(0);
  const [totalVoid, setTotalVoid] = useState(0);

  const [averageSpending, setAverageSpending] = useState(0);
  const [highestTransactionValue, setHighestTransactionValue] = useState(0);
  const [lowestTransactionValue, setLowestTransactionValue] = useState(0);

  const [pointsGainedForTheTenant, setPointsGainedForTheTenant] = useState(0);

  const params = useParams();
  const { columns, rows } = authorsTableData(transactions);

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedDate(dateF);
  };
  const handleEndDateChange = (date) => {
    console.log(date);
    var dateF = new Date(date);
    console.log("dateF:" + dateF);

    setSelectedEndDate(dateF);
  };

  const handleChangeTransactionType = (event) => {
    const selectedValue = event.target.value;
    console.log("value:" + selectedValue);

    setSelectedTransactionType(selectedValue);
  };

  const handleChangeNationality = (event) => {
    const selectedValue = event.target.value;
    console.log("value  of nationality:" + selectedValue);

    setSelectedNationality(selectedValue);
  };

  const handleChangeGender = (event) => {
    const selectedValue = event.target.value;
    console.log("value  of gender:" + selectedValue);

    setSelectedGender(selectedValue);
  };

  /**useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, merchantId: merchantId }));
  }, [merchantId]);*/
  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, startDate: selectedDate }));
  }, [selectedDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, endDate: selectedEndDate }));
  }, [selectedEndDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, firstname: customerName }));
  }, [customerName]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, lastname: lastName }));
  }, [lastName]);
  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, memberCode: memberCode }));
  }, [memberCode]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, mobile: mobileNumber }));
  }, [mobileNumber]);

  useEffect(() => {
    if (selectedTransactionType !== "all") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: selectedTransactionType,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: "",
      }));
    }
  }, [selectedTransactionType]);

  useEffect(() => {
    if (selectedNationality) {
      if (selectedNationality.toLowerCase() !== "all") {
        setTransactionFilter((prevState) => ({
          ...prevState,
          nationality: selectedNationality,
        }));
      } else {
        setTransactionFilter((prevState) => ({
          ...prevState,
          nationality: "",
        }));
      }
    }
  }, [selectedNationality]);

  useEffect(() => {
    if (selectedGender !== "all") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        gender: selectedGender,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        gender: "",
      }));
    }
  }, [selectedGender]);

  //console.log("access TransactionReport");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();
  const getMerchant = async () => {
    const url = `${Api.merchant}/Merchant/getAllShortData?${merchantId}`;
    console.log("url" + url);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        setMerchants(arr);
      })
      .catch((err) => {
        console.error("There was an error!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    const elements = nationalities;
    if (transactions && Array.isArray(transactions) && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        elements.push({ id: transactions[i].nationality, name: transactions[i].nationality });
      }
    }

    // Call the function and store the result
    let uniqueObjectsArray = getUniqueObjects(elements);
    console.log("elements of nationalities: " + JSON.stringify(uniqueObjectsArray));
    setnationalityList(extendedFun.uppercasePropertyNames(uniqueObjectsArray));
  }, [transactions]);

  useEffect(() => {
    const elements = genders;
    if (transactions && Array.isArray(transactions) && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        elements.push({ id: transactions[i].gender, name: transactions[i].gender });
      }
    }

    // Call the function and store the result
    let uniqueObjectsArray = getUniqueObjects(elements);
    console.log("elements of nationalities: " + JSON.stringify(uniqueObjectsArray));
    setGenderList(uniqueObjectsArray);
  }, [transactions]);

  useEffect(() => {
    console.log("getMerchant e");

    getMerchant();
  }, []);
  useEffect(() => {
    console.log("getMerchant e a");

    let merchant = merchants.items.find((a) => a.id == merchantId);
    let merchantName = merchant ? merchant.name : null;
    console.log("getMerchant e a merchantName *:" + merchantName);
    setMerchantName(merchantName);
  }, [merchants, merchantId]);
  //get data from server

  const getTransaction = () => {
    //const url = `${Api.report}/TransactionReport/TransactionSeparateVoidReport`;
    const url = `${Api.report}/TransactionReport/TransactionSeparateVoidMemberReport`;
    console.log("array&:" + JSON.stringify(submittedTransactionFilter));
    console.log("url" + url);
    console.log("token" + token);
    axios
      .post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        console.log("####setTransactions:" + JSON.stringify(transactions));
        setTransactions(arr);
      })
      .catch((err) => {
        console.error("There was an error!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          console.error("401 error", err);
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    console.log("getTransaction()");
    getTransaction();
  }, [submittedTransactionFilter]);

  //end get data from server
  const tableRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to load and draw an image
  const loadAndDrawImage = async (doc, url, x, y, width, height) => {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        let reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          doc.addImage(reader.result, "JPEG", x, y, width, height);
          resolve();
        };
      };
      xhr.send();
    });
  };

  // Function to calculate average spending

  // Function to calculate average spending
  const calculateAverageSpending = () => {
    const totalSpending = transactions?.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending / transactions.length;
    return averageSpending.toFixed(2);
  };

  // Function to calculate the maximum spending
  const calcHighestTransactionValue = () => {
    const maxSpending = transactions?.reduce((max, curr) => {
      return Math.max(max, curr.transactionValue);
    }, 0);

    return maxSpending.toFixed(2);
  };

  const calcLowestTransactionValue = () => {
    // Filter transactions to only include positive values
    const positiveTransactions = transactions.filter(
      (transaction) => transaction.transactionValue > 0
    );

    if (positiveTransactions.length === 0) {
      return 0; // Return 0 if there are no positive transactions. You can adjust this default value if needed.
    }

    // Find the lowest positive transaction value
    const minSpending = positiveTransactions?.reduce((min, curr) => {
      return Math.min(min, curr.transactionValue);
    }, positiveTransactions[0].transactionValue);

    return minSpending.toFixed(2);
  };

  // Function to calculate average spending
  const calculatePointsGainedForTheTenant = () => {
    const totalPoints = transactions?.reduce((acc, curr) => {
      return acc + curr.points;
    }, 0);
    return totalPoints.toFixed(2);
  };
  // Function to generate the PDF document
  // ... (previous code)

  useEffect(() => {
    if (transactions) {
      const result = calculateTransactionDetails();

      setTotalTrans(result.totalPrivilegePLUSTransactions);
      setTotalSales(result.totalSaleValue);
      setTotalVoidAmt(result.totalVoidAmount);
      setTotalVoid(result.totalVoidTransactions);
      setAverageSpending(calculateAverageSpending());
      setHighestTransactionValue(calcHighestTransactionValue());
      setLowestTransactionValue(calcLowestTransactionValue());
      setPointsGainedForTheTenant(calculatePointsGainedForTheTenant());
    }
  }, [transactions]);

  function calculateTransactionDetails() {
    let totalVoidAmount = 0;
    let totalNumberOfVoidTransactions = 0;
    let totalSalesAmount = 0;
    let totalNumberOfTransactions = 0;

    for (let transaction of transactions) {
      if (transaction.transactionType === "Void") {
        totalVoidAmount += transaction.transactionValue;
        totalNumberOfVoidTransactions++;
      }
      totalSalesAmount += transaction.transactionValue;
      totalNumberOfTransactions++;
    }

    // Calculate net sales
    //let totalNetSales = totalSalesAmount + totalRefundAmount; // Refunds are usually negative values, so adding them will subtract from the sales.

    return {
      totalVoidAmount: totalVoidAmount,
      totalVoidTransactions: totalNumberOfVoidTransactions,
      totalPrivilegePLUSTransactions: totalNumberOfTransactions,
      totalSaleValue: totalSalesAmount,
    };
  }

  function formatAsAccounting(value) {
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const Download = () => {
    switch (downloadFormat.toLowerCase()) {
      case "xlsx":
        generateXLSX();
        break;
    }
  };
  function getMonthName(dateString) {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Use toLocaleString to get the month name
    const monthName = date.toLocaleString("default", { month: "long" });

    return monthName;
  }
  const generateXLSX = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Transactions Report");

      const headerRow = worksheet.addRow([
        "Customer",
        "PRIVILEGEPLUS ID",
        "Date",
        "Time",
        "Month",
        "Category",
        "Merchant",
        "Nationality",
        "Gender",
        "Age",
        "Transaction Type",
        "Receipt No.",
        "Net Amount",
        "Discount ",
        "Discount Amount",
        "Gross Amount",
        "Points",
        "Balance",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      //data rows
      //format data before put in
      const data = transactions
        .filter((transaction) => !transaction.firstname.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            customer: transaction.firstname + " " + transaction.lastname,
            // memberCode: transaction.memberCode,
            id: transaction.memberCode,
            category: transaction.category,
            merchant: transaction.shopName,
            date: formatDate(transaction.dateOfTransaction),
            time: extendedFun.IsoDateToDDMMYY(transaction.dateOfTransaction).substring(15),
            month: getMonthName(transaction.dateOfTransaction),

            receipt: transaction.transactionNumber,
            nationality: transaction.nationality,
            age: transaction.age,
            gender: transaction.gender,
            type: transaction.transactionType,
            netamount: formatAsAccounting(transaction.transactionValue),
            discount: transaction.ppldiscount,
            discountamount: formatAsAccounting(
              transaction.transactionValue / (1 - transaction.ppldiscount / 100) -
                transaction.transactionValue
            ),
            grossamount: formatAsAccounting(
              transaction.transactionValue / (1 - transaction.ppldiscount / 100)
            ),

            points: transaction.points || "-",
            pointbalance: transaction.pointbalance || "-",
          };
        });

      //put in excel
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.customer,
          // transaction.memberCode,
          transaction.id,
          transaction.date,
          transaction.time,
          transaction.month,
          transaction.category,
          transaction.merchant,
          transaction.nationality,
          transaction.gender,
          transaction.age,
          transaction.type,
          transaction.receipt,
          transaction.netamount,
          transaction.discount,
          transaction.discountamount,
          transaction.grossamount,
          //transaction.ppldiscount,
          //transaction.isppldiscountapplied,
          transaction.points,
          transaction.pointbalance,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "Member", width: 15 },
        { key: "Merchant", width: 15 },
        { key: "date", width: 15 },
        { key: "id", width: 24 },
        { key: "receipt", width: 15 },
        { key: "amount", width: 20 },
        { key: "points", width: 15 },
        { key: "type", width: 20 },
        { key: "nationality", width: 15 },
        { key: "age", width: 15 },
        { key: "gender", width: 15 },
      ];
      worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });

      //Add summary
      // Calculate the average spending
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      const totalSaleValue = result.totalSaleValue;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/

      // Assuming currentRow is already defined
      const rangeA = `A${currentRow}`;
      const rangeB = `B${currentRow}`;
      const rangeC = `C${currentRow}`;

      worksheet.mergeCells(rangeA, rangeB);
      worksheet.getCell(rangeA).alignment = { horizontal: "left" };
      worksheet.getCell(rangeC).alignment = { horizontal: "right" };
      worksheet.getCell(rangeA).value = "Description";
      worksheet.getCell(rangeC).value = "Values";

      // Apply borders
      const borderStyle = { style: "thin" };

      worksheet.getCell(rangeA).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };
      worksheet.getCell(rangeB).border = {
        top: borderStyle,
        bottom: borderStyle,
        right: borderStyle, // This cell is merged with the previous one so no need for the left border.
      };
      worksheet.getCell(rangeC).border = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle,
      };

      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
      worksheet.getCell(`C${currentRow}`).value = `${formatAsAccounting(
        totalSaleValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
      worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        -1 * totalVoidAmount
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
      worksheet.getCell(`C${currentRow}`).value =
        formatAsAccounting(pointsGainedForTheTenant).toString();
      currentRow++;
      currentRow++;
      worksheet.getCell(`A${currentRow}`).value = "Summary:";
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        highestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        lowestTransactionValue
      ).toString()}`;
      currentRow++;

      worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
      worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
        averageSpending
      ).toString()}`;
      currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // ... continue with any other operations, such as saving the workbook.

      // Download the Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const d = new date();
      link.download = `Member Sales Report _ All Tenant Transactions Report .xls`;
      link.click();
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  // Utility function to get base64 encoded image from URL
  const getBase64ImageFromUrl = async (imageUrl) => {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const [downloadFormat, setDownloadFormat] = useState("XLSX");

  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="small">
      <MDTypography display="block" variant="caption" color="black" fontWeight="small">
        {data}
      </MDTypography>
    </MDBox>
  );
  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            //bgColor="#AB8E66"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white">
              All Tenants Transactions Report
            </MDTypography>
          </MDBox>

          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mx={1}
            flexWrap="wrap"
          >
            {" "}
            {/* Add flexWrap here */}
            {/*<filters*/}
            <MDBox display="flex" alignItems="center">
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    onChange={(newValue) => handleDateChange(newValue)}
                    sx={{
                      "& .MuiFormLabel-root.MuiInputLabel-root": {
                        marginTop: "0px", // Adjust the value as per your requirements
                      },
                      "& .MuiInputBase-root": {
                        height: "46px",
                        width: "160px",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px",
                        borderWidth: "0px",
                      },
                    }}
                  />
                </LocalizationProvider>
                <MDBox ml={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      sx={{
                        "& .MuiFormLabel-root.MuiInputLabel-root": {
                          marginTop: "0px", // Adjust the value as per your requirements
                        },
                        "& .MuiInputBase-root": {
                          height: "46px",
                          width: "160px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          margin: "0px",
                          paddingRight: "10px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          borderWidth: "0px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </MDBox>
              </MDBox>

              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <FormControl
                  style={{
                    marginLeft: 0,
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    htmlFor="transaction-type-select"
                    style={{
                      marginLeft: 10,
                      height: "46px",
                    }}
                  >
                    Transaction Type
                  </InputLabel>
                  <Select
                    id="transaction-type-select"
                    label="Transaction Type"
                    //defaultValue={""}
                    onChange={handleChangeTransactionType}
                    style={{ width: "160px", height: "46px" }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }} // Disable the underline here
                  >
                    {transactionTypes.map((element, index) => (
                      <MenuItem key={index} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
              {/*<MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={2}>
                {/*<FormControl
                  style={{
                    marginLeft: 0,
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    htmlFor="nationality-select"
                    style={{
                      marginLeft: 10,
                      height: "46px",
                    }}
                  >
                    Nationality
                  </InputLabel>
                  <Select
                    id="nationality-select"
                    label="Nationality"
                    //defaultValue={""}
                    onChange={handleChangeNationality}
                    style={{ width: "160px", height: "46px" }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }} // Disable the underline here
                  >
                    {nationalityList.map((element, index) => (
                      <MenuItem key={index} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>*/}

              {/*<FormControl
                  style={{
                    marginLeft: 10,
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    htmlFor="Gender"
                    style={{
                      marginLeft: 10,
                      height: "46px",
                    }}
                  >
                    Gender
                  </InputLabel>
                  <Select
                    id="Gender"
                    label="Gender"
                    //defaultValue={""}
                    onChange={handleChangeGender}
                    style={{ width: "160px", height: "46px" }}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ disableUnderline: true }} // Disable the underline here
                  >
                    {genderList.map((element, index) => (
                      <MenuItem key={index} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>*/}
            </MDBox>
            <MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="row"
                mt={0}
                ml={2}
                style={{ height: "44px", marginLeft: 20 }}
              >
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={2}
                >
                  <FormControl
                    style={{
                      marginLeft: 0,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <MDInput
                      id="customer-name"
                      style={{
                        width: "160px",
                        height: "46px",
                      }}
                      type="text"
                      label="Customer Name"
                      value={customerName}
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={2}
                >
                  <FormControl
                    style={{
                      marginLeft: 0,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <MDInput
                      id="customer-lastname"
                      style={{
                        width: "160px",
                        height: "46px",
                      }}
                      type="text"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </FormControl>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={2}
                >
                  <FormControl
                    style={{
                      marginLeft: 0,
                      height: "55px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <MDInput
                      id="mobile-number"
                      style={{ width: "160px", height: "46px" }}
                      type="text"
                      label="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                    />
                  </FormControl>
                </MDBox>

                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={2}
                >
                  <FormControl
                    style={{
                      marginLeft: 0,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <MDInput
                      id="customer-code"
                      style={{
                        width: "160px",
                        height: "46px",
                      }}
                      type="text"
                      label="Customer Code"
                      value={lastName}
                      onChange={(e) => setMemberCode(e.target.value)}
                    />
                  </FormControl>
                </MDBox>

                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={4}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    //bgColor="#AB8E66"
                    borderRadius="lg"
                    coloredShadow="success"
                    onClick={() => {
                      console.log("search");
                      setSubmittedTransactionFilter(transactionFilter);
                    }}
                    style={{ width: "90px", height: "44px", marginLeft: 10 }} // Set the width you desire
                  >
                    Search
                  </MDButton>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  mt={2}
                  ml={4}
                >
                  <label
                    className="label-small"
                    style={{
                      fontSize: "14px", // Bigger font size
                      padding: "8px 12px", // Padding to increase height and width
                      marginLeft: 4,
                    }}
                  >
                    {"Export to:"}
                  </label>
                  <select
                    style={{
                      fontSize: "14px", // Bigger font size
                      //padding: "8px 12px", // Padding to increase height and width
                      border: "1px solid #ccc", // A subtle border (optional)
                      borderRadius: "4px", // Rounded corners (optional)
                      marginLeft: 4,
                      height: "40px",
                    }}
                    onChange={(event) => {
                      let selectedValue = event.target.value;
                      console.log(JSON.stringify(selectedValue));
                      setDownloadFormat(selectedValue);
                    }}
                  >
                    {/*<option value="pdf">PDF</option>*/}
                    <option value="xlsx">XLSX</option>
                    {/*<option value="xls">XLS</option>*/}
                    {/*<option value="csv">CSV</option>*/}
                  </select>
                  <MDButton
                    variant="gradient"
                    color="info"
                    //bgColor="#AB8E66"
                    borderRadius="lg"
                    coloredShadow="success"
                    onClick={Download}
                    style={{ width: "4px", height: "44px", marginLeft: 10 }} // Set the width you desire
                  >
                    <DownloadIcon style={{ fontSize: "medium", width: "24", height: "24" }} />
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
            {/*<filters*/}
          </MDBox>
          {/*<DataTable*/}
          <MDBox mx={2} mt={1}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries
              noEndBorder
              align={"middle"}
              //canSearch
            />
          </MDBox>

          <MDBox mx={2} mt={1} p={2} mb={3}>
            <MDBox display="block" color="text" fontWeight="small">
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total PrivilegePlus Transactions: {totalTrans}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Sales Amount: AED {totalSales}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Void Transactions: {totalVoid}
              </MDTypography>
              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Total Void Amount: AED {totalVoidAmt}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={2}
              >
                Total PrivilegePlus Points Rewarded: {pointsGainedForTheTenant}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Summary:
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Highest Transaction Value: {highestTransactionValue}
              </MDTypography>

              <MDTypography
                display="block"
                variant="caption"
                color="black"
                fontWeight="small"
                mb={1}
              >
                Lowest Transaction Value: AED {lowestTransactionValue}
              </MDTypography>

              <MDTypography display="block" variant="caption" color="black" fontWeight="small">
                Average Transaction Value: AED {averageSpending}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default TransactionsReportV1;
