/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
// import DashboardLayout from "../../../../../../examples/LayoutContainers/DashboardLayout";
import WinnerInfoCard from "../../../../examples/Cards/InfoCards/WinnerInfoCard";

import * as extendedFun from "../../../../extensions/extendedFunctions";

// Billing page components

function Winner({
  name,
  nationality,
  gender,
  prizeDetails,
  orderWinner,
  drawDate,
  mobile,
  email,
  couponCode,
  memberCode,
}) {
  console.log(`name:${JSON.stringify(name)}`);

  return (
    <MDBox mt={0}>
      <MDBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <WinnerInfoCard
                  icon="EmojiEvents"
                  name={name}
                  description="Belong Interactive"
                  value={prizeDetails}
                  nationality={nationality}
                  gender={gender}
                  prizeDetails={prizeDetails}
                  orderWinner={orderWinner}
                  drawDate={extendedFun.IsoDateToDDMMYY(drawDate)}
                  mobile={mobile}
                  email={email}
                  couponCode={couponCode}
                  memberCode={memberCode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill

Winner.propTypes = {
  name: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  prizeDetails: PropTypes.string.isRequired,
  orderWinner: PropTypes.number.isRequired,
  drawDate: PropTypes.string.isRequired,
  mobile: PropTypes.string,
  email: PropTypes.string,
  couponCode: PropTypes.string,
  memberCode: PropTypes.string,
};
export default Winner;
