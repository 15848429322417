/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

import PropTypes from "prop-types";

import axios from "axios";
import Api from "constants/Api";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useAuth } from "../../../../contexts/authContext";

// Material Dashboard 2 React examples
// import DashboardLayout from "../../../../../../examples/LayoutContainers/DashboardLayout";
// import WinnerInfoCard from "../../../../../../examples/Cards/InfoCards/WinnerInfoCard";

const alertContent = (name) => (
  <MDTypography variant="body2" color="white">
    A simple {name} alert with{" "}
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      an example link
    </MDTypography>
    . Give it a click if you like.
  </MDTypography>
);
// Billing page components

function SelectWinner({ couponId, prize, orderWinner, refresh }) {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenError = () => {
    setOpenError(true);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };
  console.log(couponId);
  const selectWinner = () => {
    console.log(`couponId:${couponId}`);
    handleClose();
    console.log(`generate-PplWinner - Token :${token}`);

    const url = `${Api.api}/Draw/generatePplWinner`;
    axios
      .post(
        url,
        {
          couponiId: couponId,
          orderWinner,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Authorization header with your token
          },
        }
      )
      .then((response) => {
        console.log(`response:${response}`);
        //handleClickOpenError();
        refresh();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(`error:${error.response.data}`);
          console.log(error.response.status);
          console.log(error.response.headers);
          setErrorMessage(error.response.data);
          handleClickOpenError();
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          handleClickOpenError();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
          handleClickOpenError();
        }
        console.log(error.config);
      });
  };
  return (
    <MDBox mt={0}>
      <MDBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} xl={12}>
                <MDBox
                  mb={3}
                  // style={{ borderWidth: "36.25rem" }}
                  style={{ borderStyle: "solid", padding: "15px" }}
                  borderRadius="30px"
                >
                  <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                    Prize Order: {orderWinner}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                    <br />
                    Prize details: {prize}
                  </MDTypography>
                  <MDTypography variant="caption" fontWeight="bold" textTransform="uppercase">
                    <br />
                    Winner has not been selected yet
                  </MDTypography>
                  {/* <MDBox p={2} mt="auto">
                    <MDButton variant="gradient" color="success" onClick={handleClickOpen}>
                      <EmojiEventsIcon>EmojiEventsIcon</EmojiEventsIcon>&nbsp;Select Winner
                    </MDButton>
                  </MDBox>*/}
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Select Winner"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to do the draw and select winner?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={selectWinner} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>

      <MDBox>
        <Dialog
          open={openError}
          onClose={handleCloseError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Select Winner"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Bill
SelectWinner.defaultProps = {};

SelectWinner.propTypes = {
  couponId: PropTypes.number.isRequired,
  prize: PropTypes.string.isRequired,
  orderWinner: PropTypes.number.isRequired,
  refresh: PropTypes.func,
};

export default SelectWinner;
