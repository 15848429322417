//protectedRoute.js
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "contexts/authContext";

function checkRoles(RequiredRoles, userRoles) {
  //console.log("checkRoles:" + JSON.stringify(userRoles));
  // Convert both arrays to lowercase for a case-insensitive check
  if (userRoles && userRoles.length > 0) {
    const lowercasedRequiredRoles = RequiredRoles.map((role) => role.toLowerCase());
    const lowercasedUserRoles = userRoles.map((role) => role.toLowerCase());

    // Check if any required role is present in user roles
    for (const role of lowercasedRequiredRoles) {
      if (lowercasedUserRoles.includes(role)) {
        return true; // At least one required role is found in user roles
      }
    }
  }

  return false; // None of the required roles are found in user roles
}

export function ProtectedRoute({ children, authRoles }) {
  //console.log("ProtectedRoute");
  let { isAuthenticated, merchantId, roles, token } = useAuth();
  //console.log("isAuthenticated-ProtectedRoute:" + isAuthenticated);
  //console.log("roles-ProtectedRoute:" + JSON.stringify(roles));
  //console.log("merchantId-ProtectedRoute:" + JSON.stringify(merchantId));
  //console.log("token-ProtectedRoute:" + JSON.stringify(token));

  //console.log("Route:" + JSON.stringify(Route));

  //roles = roles.map((item) => item.toLowerCase());
  //authRoles = authRoles.toLowerCase();

  let location = useLocation();
  //console.log("authRoles:" + JSON.stringify(authRoles)); // Access the roles prop directly
  if (!isAuthenticated) {
    // Not authenticated? Redirect to Login
    return <Navigate to="/authentication/sign-in" state={{ from: location }} />;
  } else {
    //if (authRoles && authRoles != "") {
    if (authRoles && authRoles.length != 0) {
      if (!checkRoles(authRoles, roles)) {
        return <Navigate to="/authentication/sign-in" state={{ from: location }} />;
      }
    }
  }

  return children;
}
