import { useState } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Api from "constants/Api";
import axios from "axios";
import { useAuth } from "contexts/authContext";
import { useNavigate, useLocation } from "react-router-dom";
//import ReCAPTCHA from "react-google-recaptcha";
import packageJson from "../../../../package.json";

import "../sign-in/style.css"; // Import your stylesheet

//import jsPDF from "jspdf";
//import { Alert } from "@mui/material";

// Import the setupAxiosInterceptors function if it's defined in another file
import setupAxiosInterceptors from "../../../setupAxiosInterceptors";
import { Typography } from "@mui/material";

function Basic() {
  const { setIsAuthenticated } = useAuth();
  const { setRoles } = useAuth();
  const { setToken } = useAuth();
  const { setMerchantId } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const onChange = () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Make API call here using the email and password
    console.log(email + "," + password);
    const data = {
      email: email,
      password: password,
    };
    try {
      const url = `${Api.IdentityServer1}/user/LoginDashboardApp`;
      console.log("url" + url);

      axios
        .post(url, data)
        .then((response) => {
          console.log("signin:" + JSON.stringify(response));
          if (response.status === 200) {
            const arr = response.data;
            const { email, token, Roles } = response.data;
            setIsAuthenticated(true);
            setRoles(response.data.roles);
            console.log("response.data.merchantId:" + response.data.merchantId);
            if (typeof response.data.merchantId === "number" && !isNaN(response.data.merchantId)) {
              setMerchantId(response.data.merchantId);
            }
            const roles = response.data.roles.map((role) => role.toLowerCase());
            console.log("rolesx:" + roles);

            if (roles == "pplmanager" || roles == "super") {
              setMerchantId(0);
            }
            setToken(response.data.token);
            console.log("Token^:" + response.data.token);
            // Store the token in local storage
            localStorage.setItem("token", token);
            // setupAxiosInterceptors(response.data.token); // Call the interceptor setup here
            setupAxiosInterceptors();

            console.log("SignIn:" + JSON.stringify(response));
            console.log("SignIn:" + JSON.stringify(response.data));
            console.log("SignIn:" + JSON.stringify(response.data.roles));
            console.log("merchantid:" + JSON.stringify(response.data.merchantId));

            /*setAuthState({
          isAuthenticated: true,
          token,
          email,
          roles,
        });*/

            // If redirected from a protected route, navigate there after login:
            if (roles.includes("pplmanager") || roles.includes("super")) {
              setMerchantId(0);
            }
            // let { from } = location.state || { from: { pathname: "/" } };
            if (
              roles.includes("pplmanager") ||
              roles.includes("super") ||
              roles.includes("sponsoradmin")
            ) {
              let { from } = location.state || { from: { pathname: "/home/" } };
              console.log("*route:" + JSON.stringify(from));
              navigate(from.pathname);
            } else {
              let { from } = location.state || { from: { pathname: "/home2" } };
              console.log("*route:" + JSON.stringify(from));
              navigate(from.pathname);
            }
          } else {
            console.log("error in sign in ");
            console.log(response.data.message);
            // Handle error here, e.g., set an error message state
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            console.log(error.response.data);
          } else {
            console.error("Error occurred:", error);
          }
        });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };
  const imageUrl1 = "../pplLogo.jpg";
  return (
    <BasicLayout image={bgImage}>
      <Card className="responsive-card">
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
          className="responsive-box"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit} className="responsive-form">
            {/* Image added here */}
            <MDBox variant="gradient" mt={-3} p={2} mb={1} textAlign="center">
              <img
                src={imageUrl1}
                width="200"
                height="48"
                alt="PrivilegePLUS "
                style={{ marginBottom: "20px" }}
                className="responsive-image"
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Username"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDTypography color="error">{error}</MDTypography>
            {/* <ReCAPTCHA sitekey="6LcUYd0pAAAAAEsK2Klj_u79EGUSeghKXxdBgNH5" onChange={onChange} /> */}
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" style={{ fontSize: "12px" }}>
              Version: {packageJson.version}
            </Typography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
