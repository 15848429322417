/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import PropTypes from "prop-types";

import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import * as extendedFun from "extensions/extendedFunctions";
import Api from "constants/Api";
import axios from "axios";
import MDAlert from "components/MDAlert";
import { FormControl, FormHelperText, Input } from "@mui/material";
import { useAuth } from "../../../../contexts/authContext";

const initMalls = [
  { id: "", name: "Select Mall*" },
  { id: 1, name: "Mercato" },
  { id: 2, name: "Towncenter" },
];

const initForm = {
  id: null,
  title: "",
};

function Edit({ action, id }) {
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [image, setImage] = useState(null);

  const [happening, setHappening] = useState(null);

  console.log("id=" + id);

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initForm,
  });

  const validate = (data) => {
    if (data.title == "") {
      return false;
    }
    if (image == null && image == "") {
      return false;
    }
    return true;
  };

  const onSubmit = (data) => {
    console.log("Edit data:" + JSON.stringify(data));
    console.log("Edit image:" + JSON.stringify(image));

    if (validate(data)) {
      const formData = new FormData();
      formData.append("id", data.id);
      formData.append("image", image);
      formData.append("title", data.title);
      create(formData);
    } else {
      setMessage("Please, fill all fields");
      setAlertType("error");
      setTimeout(function () {
        setMessage(null);
      }, 5000);
    }
  };

  const create = (data) => {
    const url = `${Api.merchant}/Happening`;
    console.log("url:" + url);
    /*const config = {
      headers: {
        Authorization: "Bearer " + YOUR_TOKEN_HERE,
      },
    };*/

    axios
      .put(url, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        console.log(response.data);
        setMessage("happening Modified successfully");
        setAlertType("success");
        reset();
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });
  };

  const getHappeningCategoryById = (id) => {
    const url = `${Api.merchant}/Happening/${id}`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      console.log("subHappening by  id:" + JSON.stringify(arr));
      console.log("extendedFun.IsoDateToYYYYMMDD:" + extendedFun.IsoDateToYYYYMMDD(arr.startDate));

      setHappening(arr);
      setValue("id", arr.id ? arr.id : "");
      setValue("title", arr.title ? arr.title : "");
      setValue("image", arr.image ? arr.image : "");
      setImage(arr.image ? arr.image : "");
    });
  };

  useEffect(() => {
    if (id) {
      getHappeningCategoryById(id);
    }
  }, [id]);

  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );
  return (
    <>
      <MDBox
        color="white"
        p={2}
        justifyContent="center"
        alignItems="center"
        mx={0}
        sx={{
          width: "90%",
          height: "100%",
          flex: 1,
        }}
      >
        <Card>
          <MDBox ml={2} mt={0} mb={0}>
            <MDButton component={Link} to={action.route} variant="text" color="info">
              back
            </MDButton>
          </MDBox>
          <MDBox pt={0} pb={3} px={3}>
            <MDBox role="form">
              <form>
                {message && (
                  <MDBox>
                    <MDAlert color={alertType} dismissible>
                      {alertContent(message, alertType)}
                    </MDAlert>
                  </MDBox>
                )}
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={2}>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "title is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallId} style={{ minWidth: 120, marginTop: 16 }}>
                        <MDInput
                          required
                          label="title"
                          error={!!errors.title}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.title?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={4}>
                  <Controller
                    name="image"
                    control={control}
                    defaultValue="hjhkjh"
                    rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.image} style={{ minWidth: 120, marginTop: 16 }}>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setImage(e.target.files[0]);

                            const file = e.target.files[0];
                            field.onChange(file);
                            setValue("image", file);
                            console.log("file:" + JSON.stringify(file));
                            //console.log("file:" + JSON.stringify(file.name));
                            //console.log("file:" + JSON.stringify(e.target.files[0]));
                          }}
                        />
                        <FormHelperText>{errors.image?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                  <MDTypography fontWeight="light" fontSize="0.9rem" verticalAlign="bottom">
                    {happening ? happening.image : ""}
                  </MDTypography>
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

Edit.propTypes = {
  action: PropTypes.shape({
    //type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
  }).isRequired,
  id: PropTypes.number.isRequired,
};

export default Edit;
